import { Typography, Card, Grid, FormControlLabel, IconButton, Button, Box } from '@mui/material';
import React from 'react';
import StyledRadio from '../../Shared/Inputs/Radio';
import styles from './filter-left-panel.module.scss';
import { allText , assetType } from '../../../data/appConstants';
import CloseIcon from '@mui/icons-material/Close';
import i18next from 'i18next';
import Images from '../../../assets/img';
import DeviceBreakPoints from '../../../deviceBreakPoints';

const FilterLeftPanel = (props) => {
    const { isMediumDevice, isSmallDevice } = DeviceBreakPoints();
    const filterList = allText?.concat(assetType);

    return (
        <>
            <Card container className={styles['panel']} sx={{backgroundColor:'primaryBackground.bgBody'}}>
                {(isMediumDevice || isSmallDevice) &&
                    <Grid item className={styles['view-more-grid']}>
                        <IconButton size='small' onClick={props.closeFilter}>
                            <CloseIcon fontSize='inherit' />
                        </IconButton>
                    </Grid>
                }
                {(isMediumDevice || isSmallDevice) &&
                    <Grid item>
                        <Typography variant='largeTextBold' sx={{width: '100% !important'}}>
                        {i18next.t('FILTER_SECTION.FILTER')}
                        </Typography>
                    </Grid>
                }
                <Grid item>
                    <Typography variant='largeTextBold' sx={{width: '100% !important'}}>
                       {i18next.t('FILTER_SECTION.ASSET_TYPE')}
                    </Typography>
                </Grid>
                <Grid item className={styles['filter-item-grid']}>{
                    filterList.map((assets) => (
                        <Grid key={assets.label}>
                            <FormControlLabel  disabled={assets.label !== i18next.t('ALL')} checked={assets.label === i18next.t('ALL') } control={<StyledRadio className={styles['filtered-list-radio-icon']} size='small' />} label={<Typography className={styles['filtered-text']}>{i18next.t(assets.label)}</Typography>} />
                        </Grid>))
                }
                </Grid>
                <Grid item className={styles['view-more-grid']}>
                    <Box component='span'  disabled={true}>
                        <Typography color='primary'>
                        {i18next.t('SHARED.VIEW_MORE')} </Typography></Box>
                </Grid>
                {(isMediumDevice || isSmallDevice) &&
                    <Grid item className={styles['apply-filter-grid']} >
                        <Button  disabled={true} className={styles['reset-button']}>
                        {i18next.t('SHARED.RESET')} 
                        <Box component='img' disabled={true} src={Images.resetIcon} className={styles['reset-icon']} />
                        </Button>
                        <Button  disabled={true} className={'apply-filter-button'} variant='filledBtn' >{i18next.t('FILTER_SECTION.APPLY_FILTER')}</Button>
                    </Grid>}
            </Card>
        </>
    );
};

export default FilterLeftPanel;