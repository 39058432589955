const initialState = {
  userDeregistered: ''
};

const UserReducer = (state = initialState, action) => {
  return action?.type === 'USER_DEREGISTERED'
    ? { ...state, userDeregistered: action.payload }
    : state;
};

export default UserReducer;