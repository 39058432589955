import React from 'react';
import { Box, Container, Grid, Typography, Card, Button, CardContent } from '@mui/material';
// import CardMedia from '@mui/material/CardMedia';
// import Slider from 'react-slick';
// import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
// import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import i18next from 'i18next';
import { zeliotContent} from '../../../data/caseStudyData';
// import caseStudyData from '../../../data/caseStudyData';
import styles from './case-studies.module.scss';
import Images from '../../../assets/img';
import { useHistory } from 'react-router-dom';
import DeviceBreakPoints from '../../../deviceBreakPoints';

// Commented Code will be reverted once all case studies are ready

function CaseStudies() {
    // const [imgIndex, setImgIndex] = useState(0);
    // const [finalCaseStudyData, setFinalCaseStudyData] = useState([]);
    // const [currentSlide, setCurrentSlide] = useState(0);
    // const maxSlidesToShow = 3;

    const history = useHistory();
    const { isMediumDevice, isSmallDevice, isTab } = DeviceBreakPoints();

    // useEffect(() => {
    //     if (caseStudyData.length > 0 && caseStudyData.length <= maxSlidesToShow) {
    //         setFinalCaseStudyData(caseStudyData.concat(caseStudyData));
    //     } else {
    //         setFinalCaseStudyData(caseStudyData);
    //     }
    // }, []);

    // const CaseStudyNextArrow = ({ onClick }) => {
    //     return (
    //         <Box sx={{backgroundColor:'common.light',color:'primary.main'}}className={isSmallDevice ? `${styles['case-study-arrow']} ${styles['case-study-next']} ${styles['case-study-next-mobile']}` :
    //             isMediumDevice ? `${styles['case-study-arrow']} ${styles['case-study-next']} ${styles['case-study-next-small-device']}` :
    //                 isTab ? `${styles['case-study-arrow']} ${styles['case-study-next']} ${styles['case-study-next-tab']}` :
    //                     `${styles['case-study-arrow']} ${styles['case-study-next']}`} onClick={onClick}>
    //             <ArrowForwardOutlinedIcon />
    //         </Box>
    //     );
    // };

    // const CaseStudyPrevArrow = ({ onClick }) => {
    //     return (
    //         <Box sx={{backgroundColor:'common.light',color:'primary.main'}} className={isSmallDevice ? `${styles['case-study-arrow']} ${styles['case-study-prev']} ${styles['case-study-prev-mobile']}` :
    //             isMediumDevice ? `${styles['case-study-arrow']} ${styles['case-study-prev']} ${styles['case-study-prev-small-device']}` :
    //                 isTab ? `${styles['case-study-arrow']} ${styles['case-study-prev']} ${styles['case-study-prev-tab']}` :
    //                     `${styles['case-study-arrow']} ${styles['case-study-prev']}`} onClick={onClick}>
    //             <ArrowBackOutlinedIcon />
    //         </Box>
    //     );
    // };

    // var caseStudySettings = {
    //     infinite: true,
    //     dots: true,
    //     speed: 700,
    //     slidesToShow: 3,
    //     centerMode: true,
    //     centerPadding: '0px',
    //     nextArrow: <CaseStudyNextArrow />,
    //     prevArrow: <CaseStudyPrevArrow />,
    //     beforeChange: (current, next) => setImgIndex(next),
    //     afterChange: (index) => {
    //         if (index < caseStudyData.length) {
    //             setCurrentSlide(index);
    //         } else {
    //             setCurrentSlide(index - caseStudyData.length);
    //         }
    //     },
    //     customPaging: function (index) {
    //         return (
    //             <Box
    //                     sx={{color:index < caseStudyData.length ? index === currentSlide ? 'success.main':'secondary.main':''}}
    //                 className={index < caseStudyData.length ? index === currentSlide ? `${styles['dot-active']}` : `${styles['dot']}` : `${styles['none']}`}>
    //                 &#9679;
    //             </Box>
    //         );
    //     }
    // };
    return (
        <>
            <Box className={isSmallDevice ? styles['container-padding-responsive'] : styles['container-box-padding']}>
                <Typography mb={3} variant='h5' align='center'>{i18next.t('HOME_PAGE.CASE_STUDIES')}</Typography>
                <Container className={isSmallDevice ? styles['container-padding-responsive'] : styles['container-padding']}>
                    <Card variant='outlined' square sx={{ backgroundColor: 'primary.main' }}>
                        <CardContent className={styles['card-content']}>
                            <Grid container pb={3}>
                                <Grid className={styles['grid-padding']} item xs={8} sm={8} md={8} lg={7} xl={7} >
                                    <Box align='center' p={0.5} mb={2} sx={{ width: 100, height: 60, bgcolor: 'common.light' }}><Box component='img' sx={{ height: 55}} src={Images.caseStudyPage.zeliotImg} /></Box>
                                    <Box className={styles['grid-content']}>
                                        <Typography variant='boldOnlyText' color='common.light' className={isMediumDevice ? styles['one-liner-responsive'] : styles['one-liner']} >{i18next.t(zeliotContent.customerOneLiner)}</Typography>
                                        <Typography color='common.light' className={isTab ? styles['description-responsive'] : styles['description']} >{i18next.t(zeliotContent.customerDescription)}</Typography>
                                        <Button
                                            variant='whiteFilledBtn'
                                            sx={{ fontSize: '13px', color: 'common.dark !important' }}
                                            onClick={() => history.push('/case-studies/zeliot')}
                                        >
                                            {i18next.t('HOME_PAGE.CASE_STUDY_BUTTON')}
                                        </Button>
                                    </Box>
                                </Grid>
                                <Grid item xs={4} sm={4} md={4} lg={5} xl={5}  >
                                    <Box component='img' className={isMediumDevice ? styles['image-size-responsive'] : styles['image-size']} src={Images.caseStudyPage.caseStudyPageBannerImg} />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>

                    {/* <Slider {...caseStudySettings}>
                        {finalCaseStudyData.map((slide, index) => (
                            <>
                                <Card
                                    variant='outlined'
                                    square
                                    className={
                                        index === imgIndex ? isSmallDevice ? `${styles['slide']} ${styles['slide-active']} ${styles['slide-active-responsive']}` : `${styles['slide']} ${styles['slide-active']}` : isSmallDevice ? `${styles['slide']} ${styles['slide-responsive']}` : `${styles['slide']}`
                                    }
                                    sx={{
                                        bgcolor: slide.backGroundColor
                                    }}
                                >
                                    <CardMedia
                                        image={slide.foreGroundImage}
                                        component='img'
                                        className={isMediumDevice ? styles['foreground-image-responsive'] : styles['foreground-image']}>
                                    </CardMedia>
                                    <CardContent className={styles['card-content']}
                                    >
                                        <Grid container>
                                            <Grid className={styles['grid-padding']} item xs={9} sm={9} md={9} lg={9} xl={9}>
                                                <Box component='img' src={slide.icon} className={isMediumDevice ? slide.iconClassResponsive ? styles[slide.iconClassResponsive] : styles['custom-style-img-responsive'] : styles[slide.iconClass]} />
                                                <Typography className={isMediumDevice ? styles['one-liner-responsive'] : styles['one-liner']}>{slide.customerOneLiner}</Typography>
                                                <Typography className={isTab ? styles['description-responsive'] : styles['description']}>{slide.customerDescription}</Typography>
                                                <Button
                                                    variant='whiteFilledBtn'
                                                    sx={{ fontSize: '13px', color: 'common.dark !important'}}
                                                >
                                                    {i18next.t('HOME_PAGE.CASE_STUDY_BUTTON')}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </>
                        ))}
                    </Slider> */}
                </Container>
            </Box>
            <Box py={3}></Box>
        </>
    );
}

export default CaseStudies;