const initialState = {
  orderStatus: [],
  orderDescription: {},
  statusChange: 0,
  comments: [],
  isLoading: false,
  imageUrl: '',
  billingAddress: {},
  shippingAddress: {},
  errorState: false,
  postComment: 0,
  orderStatusChangeResponse: null,
  isCancellable: '',
  pricingPolicies: []
};

const OrderFulfillmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ORDER_STATUS':
      return { ...state, orderStatus: action.payload, isCancellable: action.cancellable };
    case 'GET_ORDER_SUMMARY':
      return { ...state, orderDescription: action.payload };
    case 'CHANGE_ORDER_STATUS':
      return { ...state, statusChange: state.statusChange + 1, orderStatusChangeResponse: action.payload };
    case 'GET_COMMENTS':
      return { ...state, comments: action.payload };
    case 'POST_COMMENT':
      return { ...state, postComment: state.postComment + action.payload };
    case 'SET_LOADER':
      return { ...state, isLoading: action.payload };
    case 'SET_BILLING_ADDRESS':
      return { ...state, billingAddress: action.payload };
    case 'SET_SHIPPING_ADDRESS':
      return { ...state, shippingAddress: action.payload };
    case 'CHANGE_ERROR_STATE':
      return { ...state, errorState: action.payload };
    case 'GET_PRICING_DETAILS':
      return { ...state, pricingPolicies: action.payload };
    default:
      return state;
  }
};

export default OrderFulfillmentReducer;
