import axios from 'axios';
import * as Constants from './configuration';
import idConstants from '../data/idConstants';

export function getOrderMasterData() {
    return (dispatch) =>
      axios
        .get(
          Constants.MASTER_DATA_ACTION.GET_ORDER_MASTERDATA  + `?categoryId=${idConstants.api.categoryId}`,
          {
            headers: {
              'Content-Type': 'application/json'
            },
            data: {}
          }
        )
        .then((response) => {
          dispatch({
            type: 'GET_ORDER_MASTER_DATA',
            payload: response.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: 'ERROR',
            error: error,
          });
        });
  }

export function getDynamicForm(categoryId) {
  return (dispatch) =>
    axios
      .get(
        Constants.CATEGORY_SERVICE_ACTION.GET_DYNAMIC_FORM + `/${categoryId}`,
        {
          headers: {
            'Content-Type': 'application/json'
          },
          data: {}
        }
      )
      .then((response) => {
        dispatch({
          type: 'GET_DYNAMIC_FORM',
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error,
        });
      });
}

export function getPromisedAllMasterData(categoryId) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      axios({
        method: 'GET',
        url:
          Constants.MASTER_DATA_ACTION.GET_PRODUCT_MASTERDATA +
          `?categoryId=${categoryId}`,

        headers: {
          'Content-Type': 'application/json'
        },
        data: {}
      })
        .then((response) => {
          let concatResponse = {};
          concatResponse['categoryId'] = categoryId;
          concatResponse['data'] = response?.data;
          resolve(concatResponse);
          dispatch({
            type: 'GET_ALL_MASTER_DATA',
            payload: response.data
          });
        })
        .catch((error) => {
          reject(error.response);
          dispatch({
            type: 'ERROR',
            error: error
          });
        });
    });
}  

export function getSubcategories() {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      axios({
        method: 'GET',
        url: Constants.MASTER_DATA_ACTION.GET_SUBCATEGORIES,
        headers: {
          'Content-Type': 'application/json'
        },
        data: {}
      })
        .then((response) => {
          resolve(response?.data);
          dispatch({
            type: 'GET_SUBCATEGORIES',
            payload: response?.data
          });
        })
        .catch((error) => {
          reject(error?.response);
          dispatch({
            type: 'ERROR',
            error: error
          });
        });
    });
}

export default function getAllMasterData() {
  return (dispatch) =>
    axios
      .get(
        Constants.MASTER_DATA_ACTION.GET_PRODUCT_MASTERDATA  + `?categoryId=${idConstants.api.categoryId}`,
        {
          headers: {
            'Content-Type': 'application/json'
          },
          data: {}
        }
      )
      .then((response) => {
        dispatch({
          type: 'GET_ALL_MASTER_DATA',
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error,
        });
      });
}
