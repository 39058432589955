import Axios from 'axios';
import * as Constants from './configuration';
import { getDispatchObject } from '../utils';

export const retrieveUserCompanyStatus = (dcsCompanyData) => async dispatch => {
    await Axios.get(Constants.COMPANY.COMPANY_STATUS, {
        headers: {
            'content-type': 'application/json'
        }
    }).then(async response => {
        await dispatch(getDispatchObject('USER_COMPANY_STATUS', response?.data, 'payload'));
        await dispatch(getDispatchObject('IS_REG_CHECK_DONE', true, 'payload'));
        if (response.data && dcsCompanyData && Object.keys(dcsCompanyData).length > 0 && dcsCompanyData.dcsCompanyId !== '') {
            if (!response.data.companyId) {
                dispatch(getDispatchObject('MISMATCH_IN_DCS_MS_COMPANY_DATA', true, 'payload'));
            }
        }
    }).catch(error => {
        dispatch(getDispatchObject('ERROR_USER_COMPANY_STATUS', error, 'payload'));
        dispatch(getDispatchObject('IS_REG_CHECK_DONE', true, 'payload'));
    });
};

export const getDCSCompanyStatus = () => async dispatch => {
    await Axios.get(Constants.COMPANY.GET_MYCOMPANY_STATUS, {
        headers: {
            'content-type': 'application/json'
        }
    }).then(async response => {
        if (response.data?.role?.some((item) => item.name === '') || response.data?.role?.length === 0) {
            await dispatch(getDispatchObject('IS_REG_CHECK_DONE', true, 'payload'));
        } else if (response.data?.role?.some((item) => item.name === Constants.AAA_CONFIG.BUYER_ROLE) ||
            response.data?.role?.find((item) => item.name === Constants.AAA_CONFIG.SELLER_ROLE)) {
            await dispatch(retrieveUserCompanyStatus(response.data));
        }
    }).catch(error => {
        dispatch(getDispatchObject('ERROR_USER_COMPANY_STATUS', error, 'payload'));
        dispatch(getDispatchObject('IS_REG_CHECK_DONE', true, 'payload'));

    });
};

export function retrieveMSCompanyDetails(companyId) {
    return (dispatch) =>
        Axios.get(Constants.COMPANY.GET_MS_COMPANY_DETAILS + '&companyId=' + companyId,
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then((response) => {
                if (response?.data) {
                    dispatch({
                        type: 'MS_COMPANY_DETAILS',
                        payload: response.data
                    });
                    return response.data;
                }
            })
            .catch((error) => {
                dispatch({
                    type: 'ERROR',
                    payload: error
                });
            });
};

export function getDCSShippingAddress() {
    return (dispatch) =>
        Axios.get(Constants.COMPANY.GET_DCS_SHIPPING_ADDRESS, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => {
                if (response?.data) {
                    return response.data;
                }
            })
            .catch((error) => {
                dispatch({
                    type: 'ERROR',
                    payload: error,
                });
            });
}

export function retrieveCompanyDetails(companyId, userId) {
    return (dispatch) =>
        Axios.get(Constants.USER_SERVICE_CAPABILITIES_ACTION.GET_COMPANY_DETAILS + 'companyId=' + companyId
            + '&userId=' + userId,
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then((response) => {
                if (response?.data) {                    
                    return response.data?.data;
                }
            })
            .catch((error) => {
                dispatch({
                    type: 'ERROR',
                    payload: error
                });
            });
};
