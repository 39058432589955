import { Grid, Tooltip, Typography, Box } from '@mui/material';
import { React, useState } from 'react';
import i18next from 'i18next';
import styles from './product-video-card.module.scss';
import { productVideosList } from '../../../../data/productGuideVideoData';


const ProductVideoCard = () => {

    const ProductVideo = (props) => {
        const [play, setPlay] = useState(false);
        return (
            <Grid container className={`${styles['product-guide-grid']}`} >
                <Grid item container xs={12} className={`${styles['product-section-video-container']}`}>
                    <Grid item xs={12} className={`${styles['product-section-video-wrapper']}`}>
                        {play ? <video id='myVideo' className={`${styles['product-section-video']}`} controls autoPlay playsInline>
                            <source src={props.list.link} type='video/mp4' />
                        </video> : <>
                            <Tooltip title={i18next.t('SHARED.PLAY_VIDEO')}>
                                <Box component='img' src={props.list.image}
                                    onClick={() => setPlay(true)}
                                    alt={i18next.t('IMAGE_ALT_TEXT.CAMPAIGN_SECTION.PLAYBACKIMG')}
                                    className={`${styles['thumbnail-section-image']}`} />
                            </Tooltip>
                        </>
                        }
                    </Grid>
                </Grid>
                <Grid item >
                    <Typography variant='boldText' component='h6'>{i18next.t(props.list.question)}</Typography>
                </Grid>
                <Grid item >
                    <Typography variant='lightText'>{i18next.t(props.list.solution)}</Typography>
                </Grid>
            </Grid>
        );
    };
    return (
        <>
            {
                productVideosList.map((list) => {
                    return <ProductVideo key={list.id} list={list} />;
                }
                )
            }
        </>
    );

};

export default ProductVideoCard;
