import { withRouter } from 'react-router-dom';
import reactGA from 'react-ga';
import getMetaDataDetails from '../utils/getMetaDataDetails';
import i18next from 'i18next';
import idConstants from '../data/idConstants';
import { getProductId } from '../utils';

const RouteChangeTracker = ({history}) => {
  const origin = location.origin;
  const getMetaDetails = (metaTitle, metaDescription) => {
    const metaDataConstants = idConstants?.metaData;
    document
      .getElementsByTagName(metaDataConstants.metaTag)
      .namedItem(metaDataConstants.title)
      .setAttribute(metaDataConstants.content, i18next.t(metaTitle));
    document
      .getElementsByTagName(metaDataConstants.metaTag)
      .namedItem(metaDataConstants.description)
      .setAttribute(metaDataConstants.content, i18next.t(metaDescription));
    document.title = i18next.t(metaTitle);

    const ogTitleMeta = document.querySelector(`meta[property="${metaDataConstants.ogTitle}"]`);
    const ogDescriptionMeta = document.querySelector(`meta[property="${metaDataConstants.ogDescription}"]`);
    const twitterTitleMeta = document
      .getElementsByTagName(metaDataConstants.metaTag)
      .namedItem(metaDataConstants.twitterTitle);
    const twitterDescriptionMeta = document
      .getElementsByTagName(metaDataConstants.metaTag)
      .namedItem(metaDataConstants.twitterDescription);
    if (ogTitleMeta && ogDescriptionMeta && twitterTitleMeta && twitterDescriptionMeta) {
      ogTitleMeta.setAttribute(metaDataConstants.content, i18next.t(metaTitle));
      twitterTitleMeta.setAttribute(metaDataConstants.content, i18next.t(metaTitle));
      ogDescriptionMeta.setAttribute(metaDataConstants.content, i18next.t(metaDescription));
      twitterDescriptionMeta.setAttribute(metaDataConstants.content, i18next.t(metaDescription));
    }
  };
  history.listen((location, action) => {
    reactGA.pageview(location.pathname);
    const productIdRegex = new RegExp(/(?=[a-zA-Z0 - 9]*)/);
    const [metaTitle, metaDescription] = getMetaDataDetails(location.pathname);
    document.querySelector("link[rel='canonical']")?.setAttribute('href', origin + location.pathname);
    if (!productIdRegex.test(getProductId()) && metaTitle && metaDescription) {
      getMetaDetails(metaTitle, metaDescription);
    }
  });

  return true;
};

export default withRouter(RouteChangeTracker);