import { combineReducers } from 'redux';

import SolutionReducer from './api/SolutionReducer';
import LoaderReducer from './LoaderReducer';
import ProgressReducer from './ProgressReducer';
import SnackbarReducer from './SnackbarReducer';
import MasterDataReducer from './MasterDataReducer';
import LandingReducer from './LandingReducer';
import ProductDetailReducer from './ProductDetailReducer';
import SuccessReducer from './SuccessReducer';
import ErrorReducer from './ErrorReducer';
import PricingPlanReducer from './PricingPlanReducer';
import loginReducer from './LoginReducer';
import ApiStatisticsReducer from './api/ApiStatisticsReducer';
import CountryListReducer from './CountryListReducer';
import DeleteAccountReducer from './DeleteAccountReducer';
import CompanyDetailsReducer from './CompanyDetailsReducer';
import UserReducer from './UserReducer';
import OrderSummaryReducer from './OrderSummaryReducer';
import OrderHistoryReducer from './OrderHistoryReducer';
import MyProposalReducer from '../reducer/MyProposalReducer';
import GetInTouchReducer from './GetInTouchReducer';
import RequestInformationReducer from './RequestInformationReducer';
import SellerNamesReducer from './SellerNamesReducer';
import ManageProductsReducer from './ManageProductsReducer';
import ApiGuideReducer from './ApiGuideReducer';
import CategoryReducer from './CategoryReducer';
import OrderFulfillmentReducer from './OrderFulfillmentReducer';
import CartReducer from './CartReducer';
import QuotationReducer from './QuotationReducer';
import TicketReducer from './TicketReducer';

/**
 * Combine Reducers In One Object
 */
export default combineReducers({
  SuccessReducer,
  ErrorReducer,
  SolutionReducer,
  ManageProductsReducer,
  LoaderReducer,
  LandingReducer,
  ProgressReducer,
  SnackbarReducer,
  MasterDataReducer,
  ProductDetailReducer,
  PricingPlanReducer,
  loginReducer,
  ApiStatisticsReducer,
  CountryListReducer,
  DeleteAccountReducer,
  CompanyDetailsReducer,
  UserReducer,
  OrderSummaryReducer,
  OrderHistoryReducer,
  MyProposalReducer,
  GetInTouchReducer,
  RequestInformationReducer,
  SellerNamesReducer,
  CategoryReducer,
  ApiGuideReducer,
  OrderFulfillmentReducer,
  CartReducer,
  QuotationReducer,
  TicketReducer
});
