import 'react-app-polyfill/ie11';
import 'core-js';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './index.scss';
import { Provider } from 'react-redux';
import { BreadcrumbsProvider } from 'react-breadcrumbs-dynamic';
import { BrowserRouter as Router } from 'react-router-dom';
import AppMain from './App';
import { StickyContainer } from 'react-sticky';
import StickyIcons from './components/Shared/StickyIcons';
import store from './store';
import RootContextProvider from './providers/RootContextProvider';
import './i18n';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { appTheme } from './themes/appTheme';
import { CssBaseline } from '@mui/material';

const appThemeProvider = createTheme(appTheme);

ReactDOM.render(

  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={appThemeProvider}>
      <CssBaseline enableColorScheme />
      <BreadcrumbsProvider>
        <Provider store={store}>
          <RootContextProvider>
            <Router>
              <StickyContainer>
                <AppMain />
                <StickyIcons />
              </StickyContainer>
            </Router>
          </RootContextProvider>
        </Provider>
      </BreadcrumbsProvider>
    </ThemeProvider>
  </StyledEngineProvider>,

  document.getElementById('root'),
);
