const initialState = {
    ticketDetails: {},
    ticketsList: []
};

const TicketReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'CREATE_TICKET':
            return { ...state, ticketDetails: action.payload };
        case 'GET_TICKET_LIST':
            return { ...state, ticketsList: action.payload };
        default:
            return state;
    }
};

export default TicketReducer;
