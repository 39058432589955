import React from 'react';
import { connect } from 'react-redux';
import { Box, Grid, Typography, Button, Container } from '@mui/material';
import i18next from 'i18next';
import style from './contact-seller-info.module.scss';
import { masterDataConstants } from '../../../../data/appConstants';
import isEmpty from '../../../../validator/isEmpty';
import { IMAGE_CDN } from '../../../../actions/configuration';
import DeviceBreakPoints from '../../../../deviceBreakPoints';

function ContactSellerInfo(props) {

    const getMasterConfigInfo = (configId) => {
        if (props?.allMasterData && !isEmpty(props?.allMasterData)) {
            return props?.allMasterData?.config_master_type?.find(type => type.id === configId);
        }
        return '';
    };

    const { isDesktopDevice } = DeviceBreakPoints();
    const hrefLink = 'mailto:' + getMasterConfigInfo(masterDataConstants?.sellerSalesContactEmail)?.description;

    return (
        <>
            <Container sx={{ paddingRight: props?.hidePadding ? '0 !important' : '16px' }}>
                <Container disableGutters={isDesktopDevice ? false : true}>
                    <Grid md={12} sm={12} xs={12} className={`${style['contact-seller-container']}`} sx={{ backgroundImage: `url(${IMAGE_CDN}background-waves-pattern.png)`, backgroundColor: 'primaryBackground.main' }}>
                        <Grid container className={`${style['contact-seller-wrapper']}`}>
                            <Grid item xs={12} sm={12} md={9} lg={9} className={`${style['contact-seller-text']}`}>
                                <Grid py={3} className={`${style['contact-seller-heading']}`} >
                                    <Typography
                                        className={`${style['title-text-style']}`}
                                        align='center'
                                        component='h4'
                                        variant='h4'
                                        color='common.light'
                                    >
                                        {i18next.t('CONTACT_SELLER.TITLE_REVAMP')}
                                    </Typography>
                                </Grid>
                                <Grid>
                                    <Typography variant='body1' className={`${style['any-query-text']}`} align='center' color='common.light'>
                                        {props?.displaySubText ? props?.displaySubText : i18next.t('CONTACT_SELLER.BODY_REVAMP')}
                                        <br />{props?.displaySubText ? '' : i18next.t('CONTACT_SELLER.BODY2_REVAMP')}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item py={3} xs={12} sm={12} md={3} lg={3} mx='auto' className={`${style['contact-seller-style']}`}>
                                <Box>
                                    <a className={`${style['contact-seller-button']}`} href={hrefLink} >
                                        <Button
                                            variant='whiteFilledBtn'
                                        >{i18next.t('SHARED.CONTACT_US')}</Button>
                                    </a>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Container>
        </>
    );

}

const mapStateToProps = (redux_state) => ({
    allMasterData: redux_state.MasterDataReducer.allMasterData
});

export default connect(mapStateToProps, null)(ContactSellerInfo);