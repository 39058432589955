import images from '../assets/img';

export const Ecosystem = [
    {
        category:'ABOUT_US_PAGE.COMPONY_CATEGORY.EV_ECOSYSTEM',
        logos: [{ img: images.companyLogos.magentaLogo },
        { img: images.companyLogos.kazamLogo },
        { img: images.companyLogos.chargeZoneLogo },
        { img: images.companyLogos.expedentLogo },
        { img: images.companyLogos.ampereLogo },
        ]
    },
    {
        category: 'ABOUT_US_PAGE.COMPONY_CATEGORY.MAAS',
        logos: [{ img: images.companyLogos.zeliotLogo },
        { img: images.companyLogos.routematicLogo },
        { img: images.companyLogos.carxLogo },
        ]
    },
    {
        category: 'ABOUT_US_PAGE.COMPONY_CATEGORY.FINTECH',
        logos: [
        { img: images.companyLogos.setuLogo }
        ]
    },
];
