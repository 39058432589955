const initialState = { allMasterData: {}, dynamicForm: {}, subcategories:[], isDataFetched: false };

const MasterDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_ALL_MASTER_DATA':
            return Object.assign({}, state, { allMasterData: action.payload });
        case 'GET_SUBCATEGORIES':
            return Object.assign({}, state, { subcategories: action.payload });
        case 'GET_ORDER_MASTER_DATA':
            return Object.assign({}, state, { orderMasterData: action.payload });
        case 'GET_DYNAMIC_FORM':
                return Object.assign({}, state, { dynamicForm: action.payload });       
        case 'IS_DATA_FETCHED':
            return Object.assign({}, state, { isDataFetched: action.payload });
        default:
            return state;
    }
};

export default MasterDataReducer;