const initialState = {
    adminPricingPlans: [], validationError: {}, userSubscriptions: [], subscribePlan: null, subscribeError: null, unSubscribedSuccess: {}, unSubscribeError: null, userApplications: [], wso2AccessToken: {},
    productSubscriptions: [], enableTryOutError: null, isSubscribedProduct: []
};

const PricingPlanReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SUBSCRIBE_PLAN':
            return Object.assign({}, state, { subscribePlan: action.payload, subscribeError: action.error });
        case 'GET_USER_SUBSCRIPTIONS':
            return Object.assign({}, state, { userSubscriptions: action.payload });
        case 'GET_USER_APPLICATIONS':
            return Object.assign({}, state, { userApplications: action.payload });
        case 'UNSUBSCRIBE_PLAN':
            return Object.assign({}, state, { unSubscribedSuccess: action.payload, unSubscribeError: action.unSubscribeError });
        case 'WSO2_ACCESS_TOKEN':
            return Object.assign({}, state, { wso2AccessToken: action.payload, enableTryOutError: null });
        case 'GET_SUBSCRIPTION_FOR_PRODUCT':
            return Object.assign({}, state, { productSubscriptions: action.payload, isSubscribedProduct: action.payload });
        case 'TRYOUT_ENABLE_ERROR':
            return Object.assign({}, state, { enableTryOutError: action.error });
        case 'CLEAR_SUBSCRIBE_ERRORS':
            return Object.assign({}, state, { subscribeError: action.payload });
        case 'CLEAR_SUBSCRIBE_PLAN':
            return Object.assign({}, state, { subscribePlan: action.payload });
        default:
            return state;
    }
};

export default PricingPlanReducer;