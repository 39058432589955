import React, { lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import subscribedApisTabs from './../data/subscribedApisTabs';
import * as Constants from '../actions/configuration';

import LandingContainer from './../containers/landing';
const FooterContainer = lazy(() => import('./../containers/footer'));
const ProductDetailsInterfaceContainer = lazy(() =>
  import('../containers/productDetailsInterfaceContainer')
);
const SearchResultContainer = lazy(() =>
  import('./../containers/searchResult')
);
import ListingProductsContainer from '../containers/ListingProductsContainer';
import CategoryContainer from '../containers/categoryContainer';
const ErrorPageContainer = lazy(() =>
  import('../containers/errorPageContainer')
);
const GetInTouch = lazy(() => import('../containers/getInTouch'));
const MyQuotes = lazy(() => import('../components/Quotation/MyQuotes'));
const MyProposalsContainer = lazy(() => import('../containers/myProposals'));
const ProposalOverviewContainer = lazy(() =>
  import('../containers/proposalOverviewContainer')
);
const Support = lazy(() => import('./../containers/support'));
const ApiDocumentation = lazy(() => import('../containers/apiDocumentation'));
import AboutUs from '../containers/aboutUs';
const BuyerAccountContainer = lazy(() =>
  import('./../containers/buyerAccount')
);
const APIStatisticsContainer = lazy(() =>
  import('./../containers/api/apiStatisticsContainer')
);
const OrderHistoryContainer = lazy(() =>
  import('./../containers/orderHistoryContainer')
);
const BuyerApplicationsContainer = lazy(() =>
  import('./../containers/buyerApplications')
);
const Profile = lazy(() => import('./../containers/profile'));
const InProgressPage = lazy(() =>
  import('./../components/BaseLayout/InProgressPage')
);
const Callback = lazy(() => import('./../containers/callback'));
const AuthCallback = lazy(() => import('../components/callbacks/AuthCallback'));
const OrderSummaryContainer = lazy(() =>
  import('./../containers/orderSummary')
);
const OrderDetailsContainer = lazy(() => import('../containers/orderDetails'));
const APICampaign = lazy(() => import('../containers/apiCampaign'));
const APICampaign2 = lazy(() => import('../containers/apiCampaign2'));
const ThankyouPage = lazy(() =>
  import('../components/Campaign/CampaignThankYouPage')
);
import ProductGuideVideo from '../containers/productGuideVideo';
const CaseStudiesPageContainer = lazy(() =>
  import('../containers/caseStudyPageContainer')
);
const OrderFulfillment = lazy(() => import('../containers/orderFulfillment'));
const Cart = lazy(() => import('../containers/cart'));
const Quotation = lazy(() => import('../containers/quotation'));
const QuotationDetails = lazy(() => import('../containers/quotationDetails'));
const SiteWideSearchContainer = lazy(() =>
  import('../containers/siteWideSearch')
);
const TicketListing = lazy(() => import('../containers/ticketListing'));
const TicketDetails = lazy(() =>
  import('../components/TicketListing/TicketDetails')
);

export const ParentRoutes = () => {
  return (
    <Switch>
      <Route path='/' exact component={LandingContainer} />
      <Route path='/cart' exact component={Cart} />
      <Route path='/listing/add-quote' exact component={Quotation} />
      <Route
        path={'/professional-services' + '/:productName' + '/add-quote'}
        exact
        component={Quotation}
      />
      <Route path='/quotation-details' exact component={QuotationDetails} />
      <Route path='/corporate-information' exact component={FooterContainer} />
      <Route path='/legal-notice' exact component={FooterContainer} />
      <Route path='/data-protection-policy' exact component={FooterContainer} />
      <Route path='/terms-of-use' exact component={FooterContainer} />

      <Route
        path={
          '/:productCategoryName' +
          '/:productName' +
          '/overview' +
          '/:productId'
        }
        exact
        component={ProductDetailsInterfaceContainer}
      />
      <Route
        path={
          '/cloud-services/:productCategoryName/:productName' +
          '/overview' +
          '/:productId'
        }
        exact
        component={ProductDetailsInterfaceContainer}
      />
      <Route
        path={
          '/services/:productCategoryName/:productName' +
          '/overview' +
          '/:productId'
        }
        exact
        component={ProductDetailsInterfaceContainer}
      />
      <Route path='/thankyou' exact component={ThankyouPage} />
      <Route path='/page-not-found' exact component={ErrorPageContainer} />
      <Route path='/get-in-touch' exact component={GetInTouch} />
      <Route path='/support' exact component={Support} />
      <Route path='/about-us' exact component={AboutUs} />
      <Route path='/case-studies/zeliot' exact component={CaseStudiesPageContainer} />
      <Route path='/product-guides' exact component={ProductGuideVideo} />
      <Route path='/product-videos' exact component={ProductGuideVideo} />
      <Route path='/developer-guide' exact component={ApiDocumentation} />
      <Route path='/profile' exact component={Profile} />
      <Route path='/myaccount' exact component={BuyerAccountContainer} />
      <Route
        path='/myaccount/myproposals'
        exact
        component={MyProposalsContainer}
      />
      <Route path='/myaccount/myquotes' exact component={MyQuotes} />
      <Route path='/myaccount/track-issues' exact component={TicketListing} />
      <Route
        path='/myaccount/track-issues/details'
        exact
        component={TicketDetails}
      />
      <Route
        path='/myaccount/myproposals/proposaloverview/:proposalID'
        exact
        component={ProposalOverviewContainer}
      />
      <Route
        path='/myaccount/apistatistics'
        exact
        component={APIStatisticsContainer}
      />
      <Route
        path='/myaccount/myorders'
        exact
        component={OrderHistoryContainer}
      />
      <Route path='/cloud-services' exact component={SearchResultContainer} />
      <Route path='/services' exact component={SearchResultContainer} />
      <Route
        path='/myaccount/myapplications'
        exact
        component={BuyerApplicationsContainer}
      />
      <Route
        path='/myaccount/myapplications/application'
        exact
        component={BuyerApplicationsContainer}
      />
      {subscribedApisTabs.map((option) => (
        <Route
          path={option.url}
          key={option.key}
          exact
          component={BuyerApplicationsContainer}
        />
      ))}
      <Route path='/inprogress' exact component={InProgressPage} />
      <Route path='/callback' exact component={Callback} />
      <Route
        path={'/' + Constants.CALLBACK_PARAM.AAA_CALLBACK}
        exact
        component={AuthCallback}
      />
      <Route
        path={'/' + Constants.CALLBACK_PARAM.DCS_CALLBACK}
        exact
        component={AuthCallback}
      />
      <Route path='/order-summary' exact component={OrderSummaryContainer} />
      <Route path='/order-details' exact component={OrderDetailsContainer} />
      <Route
        path={'/' + Constants.CALLBACK_PARAM.SWITCH_CALLBACK}
        exact
        component={AuthCallback}
      />
      <Route
        path={'/' + Constants.CALLBACK_PARAM.DEREGISTRATION_CALLBACK}
        exact
        component={AuthCallback}
      />
      <Route
        path={'/' + Constants.CALLBACK_PARAM.FEDERATED_CALLBACK}
        exact
        component={AuthCallback}
      />
      <Route path='/apicampaign' exact component={APICampaign} />
      <Route path='/msapicampaign' exact component={APICampaign2} />
      <Route path='/search' exact component={SearchResultContainer} />
      {/* Commented Site-Wide-Search code for Future Release */}
      {/* <Route
        path='/:search'
        exact
        component={
          window.location.search.includes('key')
            ? SiteWideSearchContainer
            : SearchResultContainer
        }
      /> */}
      <Route
        path='/:productCategory/listing'
        exact
        component={ListingProductsContainer}
      />
      <Route
        path='/:productCategory/:childCategory/listing'
        exact
        component={ListingProductsContainer}
      />
      <Route
        path='/:productCategory/:childCategory/:subcategory/listing'
        exact
        component={ListingProductsContainer}
      />
      <Route path='/listing' exact component={ListingProductsContainer} />
      <Route
        path='/:productCategory'
        exact
        component={
          window.location.search.includes('key')
            ? SearchResultContainer
            : CategoryContainer
        }
      />
      <Route
        path='/:productCategory/:childCategory'
        exact
        component={
          window.location.search.includes('key')
            ? SearchResultContainer
            : CategoryContainer
        }
      />
      <Route
        path='/myaccount/myorders/order-fulfillment/:productOrderDetailId'
        exact
        component={OrderFulfillment}
      />
      <Redirect from='/*' to='/page-not-found' />
    </Switch>
  );
};
