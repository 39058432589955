import axios from 'axios';
import * as Constants from './configuration';
import errorConstants from '../data/errorConstants';

const handleErrorBlock = (error, errorCallback) => {
    if (error.response !== undefined) {
        errorCallback(error.response?.data?.message);
    } else {
        errorCallback(error.message ? error.message : error);
    }
};

export const getAllMasterData = async (categoryId, errorCallback) => {
    try {
        const response = await axios
            .get(
                Constants.MASTER_DATA_ACTION.GET_PRODUCT_MASTERDATA + `?categoryId=${categoryId}`,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: {}
                }
            );
        return response.data;
    } catch (error) {
        handleErrorBlock(error, errorCallback);
    }
};

export const getSubCategories = async (categoryId, errorCallback) => {

    const headers = {
        'Content-Type': 'application/json'
    };

    try {
        const response = await axios.get(Constants.LANDING_ACTION.GET_FILTERED_PUBLISHED_PRODUCT +
            `?category=${categoryId}&groupBy=subcategory`,
            {
                headers: headers
            });
        return response.data;
    } catch (error) {
        handleErrorBlock(error, errorCallback);
    }
};

export const getPublishedLatestProducts = async (key, category, errorCallback, pageNumber, size, productIds, sortBy) => {
    const searchText = encodeURIComponent(key);
    let searchTextHeader = '';
    let categoryHeader = '';
    let productTextHeader = '';
    let sortByHeader = '';
    if (!!category) {
        categoryHeader = `category=${category}&`;
    }
    if (!!productIds) {
        productTextHeader = `ids=${productIds.join(',')}&`;
    }
    if (!!searchText) {
        searchTextHeader = `key=${btoa(encodeURIComponent(searchText))}&`;
    }
    if (!!sortBy) {
        sortByHeader = `sortBy=${sortBy}&`;
    }
    try {
        const response = await axios.get(Constants.LANDING_ACTION.GET_FILTERED_PUBLISHED_PRODUCT +
            `?${searchTextHeader}${categoryHeader}${productTextHeader}${sortByHeader}page=${pageNumber}&size=${size}`,
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                data: {}
            }
        );
        if (response.data?.error !== errorConstants.searchError) {
            return response.data;
        }
    } catch (error) {
        handleErrorBlock(error, errorCallback);
    }
};

export const getPopularProducts = async (category, errorCallback, pageNumber, size) => {
    let categoryHeader = '';
    if (!!category) {
        categoryHeader = `categoryId=${category}&`;
    }
    try {
        const response = await axios.get(Constants.LANDING_ACTION.GET_POPULAR_PRODUCTS +
            `${pageNumber}/${size}?${categoryHeader}`,
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                data: {}
            }
        );
        if (response?.data?.productIds) {
            let data = await getPublishedLatestProducts('', '', errorCallback, '', '', response.data.productIds, '');
            return {
                counts: response?.headers['total-entry-count'],
                data: data
            };
        } else {
            return response.data;
        }
    } catch (error) {
        handleErrorBlock(error, errorCallback);
    }
};