import Images from '../assets/img';

const caseStudyData = [
    {
        backGroundColor: '#007bc0',
        foreGroundImage: Images.caseStudyForeGroundImg1,
        icon: Images.caseStudyLogoImg1,
        customerOneLiner: 'ZELIOT.COMPANY_TITLE_TAG',
        customerDescription: 'ZELIOT.COMPANY_BODY_TEXT',
        iconClass: 'custom-style-img-1'
    },
    {
        backGroundColor: 'black',
        foreGroundImage: Images.caseStudyForeGroundImg2,
        icon: Images.caseStudyLogoImg2,
        customerOneLiner: 'MCP_CLOUD.COMPANY_TITLE_TAG',
        customerDescription: '',
        iconClass: 'custom-style-img-2',
        iconClassResponsive: 'custom-style-img-2-responsive'
    },
    {
        backGroundColor: 'black',
        foreGroundImage: Images.caseStudyForeGroundImg3,
        icon: Images.caseStudyLogoImg3,
        customerOneLiner: 'PARKZEUS.COMPANY_TITLE_TAG',
        customerDescription: '',
        iconClass: 'custom-style-img-3'
    }
];
export default caseStudyData;


export const signzyContent = {
    backGroundColor: 'black',
    foreGroundImage: Images.caseStudyPage.caseStudyPageBannerImg,
    icon: Images.caseStudyPage.signzyImg,
    customerOneLiner: 'SIGNZY_DETAILS.COMPANY_TITLE_TAG',
    customerDescription: 'SIGNZY_DETAILS.COMPANY_BODY_TEXT',
    iconClass: 'custom-style-img-3'
};

export const zeliotContent = {
    backGroundColor: 'black',
    foreGroundImage: Images.caseStudyPage.caseStudyPageBannerImg,
    icon: Images.companyLogos.zeliotLogo,
    customerOneLiner: 'ZELIOT_DETAILS.COMPANY_TITLE_TAG',
    customerDescription: 'ZELIOT_DETAILS.COMPANY_BODY_TEXT',
    iconClass: 'custom-style-img-3'
};
export const caseStudyText = {

    challenges: [
        {
            text: 'SIGNZY_DETAILS.CHALLENGES.TEXT_1'
        },
        {
            text: 'SIGNZY_DETAILS.CHALLENGES.TEXT_2'
        },
        {
            text: 'SIGNZY_DETAILS.CHALLENGES.TEXT_3'
        }
    ],
    solutions: [
        {
            text: 'SIGNZY_DETAILS.SOLUTIONS.TEXT_1'
        },
        {
            text: 'SIGNZY_DETAILS.SOLUTIONS.TEXT_2'
        },
        {
            text: 'SIGNZY_DETAILS.SOLUTIONS.TEXT_3'
        }
    ],
    result: [
        {
            text: 'SIGNZY_DETAILS.RESULT.TEXT_1'
        },
        {
            text: 'SIGNZY_DETAILS.RESULT.TEXT_2'
        },
        {
            text: 'SIGNZY_DETAILS.RESULT.TEXT_3'
        }
    ],
    testimonials:
    {
        text: 'SIGNZY_DETAILS.TESTIMONIALS.TEXT1',
        person: 'SIGNZY_DETAILS.TESTIMONIALS.PERSON'
    },
    companyCategories: [
        {
            logo: Images.caseStudyPage.customerIcon,
            heading: 'SHARED.CUSTOMER',
            text: 'SIGNZY_DETAILS.COMPANY_NAME'
        },
        {
            logo: Images.caseStudyPage.industryImg,
            heading: 'SHARED.INDUSTRY',
            text: 'SIGNZY_DETAILS.INDUSTRY'
        },
        {
            logo: Images.caseStudyPage.countryImg,
            heading: 'SHARED.COUNTRY',
            text: 'SIGNZY_DETAILS.COUNTRY'
        },
        {
            logo: Images.caseStudyPage.segmentImg,
            heading: 'SHARED.SEGMENT',
            text: 'SIGNZY_DETAILS.ENTERPRISE'
        },
        {
            logo: Images.caseStudyPage.websiteLinkImg,
            heading: 'SHARED.WEBSITE',
            text: 'www.bosch.com'
        }
    ]
};
export const zeliotCaseStudyText = {

    challenges: [
        {
            text: 'ZELIOT_DETAILS.CHALLENGES.TEXT_1'
        },
        {
            text: 'ZELIOT_DETAILS.CHALLENGES.TEXT_2'
        }
    ],
    solutions: [
        {
            heading: 'ZELIOT_DETAILS.SOLUTION_1',
            description: [
            {
                text: 'ZELIOT_DETAILS.SOLUTIONS.TEXT_1'
            },
            {
                text: 'ZELIOT_DETAILS.SOLUTIONS.TEXT_2'
            }
        ]         
        },
        {
            heading: 'ZELIOT_DETAILS.SOLUTION_2',
            description: [
                {
                    text: 'ZELIOT_DETAILS.SOLUTIONS.TEXT_3'
                }
            ]
        }
    ],
    result: [
        {
            text: 'ZELIOT_DETAILS.RESULT.TEXT_1'
        },
        {
            text: 'ZELIOT_DETAILS.RESULT.TEXT_2'
        },
        {
            text: 'ZELIOT_DETAILS.RESULT.TEXT_3'
        }
    ],
    testimonials:
    {
        text: 'ZELIOT_DETAILS.TESTIMONIALS.TEXT1',
        person: 'ZELIOT_DETAILS.TESTIMONIALS.PERSON'
    },
    companyCategories: [
        {
            logo: Images.caseStudyPage.customerIcon,
            heading: 'SHARED.CUSTOMER',
            text: 'ZELIOT_DETAILS.COMPANY_NAME'
        },
        {
            logo: Images.caseStudyPage.industryImg,
            heading: 'SHARED.INDUSTRY',
            text: 'ZELIOT_DETAILS.INDUSTRY'
        },
        {
            logo: Images.caseStudyPage.countryImg,
            heading: 'SHARED.COUNTRY',
            text: 'ZELIOT_DETAILS.COUNTRY'
        },
        {
            logo: Images.caseStudyPage.segmentImg,
            heading: 'SHARED.SEGMENT',
            text: 'ZELIOT_DETAILS.ENTERPRISE'
        },
        {
            logo: Images.caseStudyPage.websiteLinkImg,
            heading: 'SHARED.WEBSITE',
            text: 'https://www.zeliot.in/'
        }
    ]
};