const initialState = {
  countryList: [],
};

const CountryListReducer = (state = initialState, action) => {
    return action?.type === 'COUNTRY_LIST'
        ? Object.assign({}, state, { countryList: action.payload })
        : state;
};

export default CountryListReducer;