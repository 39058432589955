import Images from '../assets/img';

const testimonialData = [
    {
        description: '"We believe we were one of the first suppliers to be onboarded on to the Mobility Marketplace and although it was the first experience for everyone, it went quite smoothly. One of the most important reasons for this was the fact that Bosch was very flexible in terms of understanding the requirements, making changes which were deemed necessary. In majority of the cases, platforms are made which are quite inflexible in nature, however, this was not the case in the case of Bosch\'s Mobility Marketplace. Further, the entire team helped set up everything smoothly. We are already integrating our second API and hope to onboard a couple more APIs in the coming months."',
        name: 'Mr. Tabrez Alam',
        designation: 'Founder and Chief Architect, Intents Mobi Private Limited',
        icon: Images.intentsMobiThumbnail,
        iconClass: 'custom-style-img-margin',
        iconClassResponsive: 'custom-style-img-responsive'
    },
    {
        description: '"Decentro is proud to have partnered with Bosch in enabling embedded banking & financial products in its ecosystem. Decentro has worked closely with the Bosch team in facilitating simplified API integrations for their marketplace and found them to be not just enthusiastic but looking way ahead in terms of experience and understanding customer requirements. Bosch team has been extremely supportive in helping Decentro launch its APIs on the marketplace of Bosch and looks to work closely with Bosch in facilitating a greater number of services to its ecosystem partners. Bosch is one of the leading names globally in the automotive space and Decentro looks forward to further its partnership with Bosch."',
        name: 'Mr. Gautam Mahesh',
        designation: 'Head - Products & Partnerships, Decentro',
        icon: Images.decentroThubmail,
        iconClass: 'custom-style-img',
        iconClassResponsive: 'custom-style-img'
    },
    {
        description: '"The current decade will belong to mobility as the previous decade did for mobile phones. Mobility Marketplace in this regards will be a game changer and there is no better company to offer mobility solutions through a market place than Bosch. Very excited to have associated with Bosch on this journey this early."',
        name: 'Mr. Anup Naik',
        designation: 'Co-Founder, Zeliot Connected Services Pvt Ltd',
        icon: Images.zeliotThumbnail,
        iconClass: 'custom-style-img',
        iconClassResponsive: 'custom-style-img'
    }
];

export default testimonialData;