import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
// import SellerSection from './SellerSection';
import SupportSection from './SupportSection';
import { Snackbar, Alert, Box } from '@mui/material';
import BreadCrumb from '../BaseLayout/BreadCrumb';
import { RegisterCompany } from '@mobilitystore/user-onboarding-interface';
import ConfirmModal from '../Shared/Utils/Modal/ConfirmModal';
import { logout } from '../../actions/LoginAction';
import Loader from '../Shared/Utils/Loader';
import AllYouNeed from './AllYouNeed';
import Testimonials from './Testimonials';
import CaseStudies from './CaseStudies';
import CustomerJourney from './CustomerJourney';
import HomepageBanner from './HomepageBanner/homepageBanner';
// import UseCases from './UseCases';
import UniqueSection from './UniqueSection';
import WhatWeOffer from './WhatWeOffer';
import idConstants from '../../data/idConstants';
import { getEnvironment } from '../../utils';
import * as Constants from '../../actions/configuration';

const Landing = (props) => {
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [confirmationModalLoader, setConfirmationModalLoader] = useState(false);
  const reduxStore = useSelector((state) => state);
  const mismatchInDcsAndMsData =
    reduxStore?.CompanyDetailsReducer?.mismatchInDcsAndMsData;

  const dispatchRedux = useDispatch();

  const handleRegisterModalClose = () => {
    localStorage.removeItem('popUpOpen');
    localStorage.removeItem('newLogin');
  };

  const handleLogOutUser = () => {
    setOpenConfirmModal(false);
    setConfirmationModalLoader(true);
    dispatchRedux(logout());
  };

  useEffect(() => {
    if (mismatchInDcsAndMsData) {
      setSnackAlertFlag(true);
      setSnackAlertMsg(
        'Mismatch occurred in Company details between DCS and MS'
      );
      setSnackAlertSeverity('error');
    }
  }, [mismatchInDcsAndMsData]);

  const [snackAlertFlag, setSnackAlertFlag] = useState(false);
  const [snackAlertMsg, setSnackAlertMsg] = useState('');
  const [snackAlertSeverity, setSnackAlertSeverity] = useState('');

  return (
    <React.Fragment>
      <BreadCrumb landing={true} />
      <Box mt={-2}>
        <HomepageBanner productCategories={props.productCategories} />
      </Box>
      <AllYouNeed />
      <WhatWeOffer />
      <UniqueSection />
      <CustomerJourney />
      {/* <UseCases /> */}
      <Testimonials />
      <CaseStudies />
      {/* <SellerSection accessToken={props?.accessToken} /> */}
      <SupportSection />
      {localStorage.getItem(idConstants.tokens.aaaIdToken) && localStorage.getItem('newLogin') &&
        <RegisterCompany
          language='en'
          clientToken={localStorage.getItem(idConstants.tokens.aaaIdToken)}
          environment={getEnvironment(process.env.REACT_APP_ENVIRONMENT)}
          successHandler={(response) => {
            dispatchRedux({
              type: 'UPDATE_PROFILE_STATUS',
              payload: response
            });
            response?.status && localStorage.removeItem('newLogin');
          }}
          cancelHandler={() => { handleRegisterModalClose(); }}
          errorHandler={(error) => {
            if (error.message) {
              setSnackAlertFlag(true);
              setSnackAlertMsg(error.message);
              setSnackAlertSeverity('error');
            }
          }}
          role={Constants.AAA_CONFIG.BUYER_ROLE}
        />
      }
      <Snackbar
        open={snackAlertFlag}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={() => {
          setSnackAlertFlag(false);
        }}
      >
        <Alert severity={snackAlertSeverity}>{snackAlertMsg}</Alert>
      </Snackbar>
      {confirmationModalLoader && <Loader />}
      {openConfirmModal && (
        <ConfirmModal
          modalContent=''
          heading={userRoleUpdatedToSeller.message}
          category={'approve'}
          callback={handleLogOutUser}
          okOperation={handleLogOutUser}
          cancelBtn='disable'
          noCloseIcon
          {...props}
        />
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (redux_state) => ({
  accessToken: redux_state.loginReducer.accessToken
});

export default connect(mapStateToProps, null)(Landing);