import isEmpty from './isEmpty';
export default function customValidator(str, type) {
    switch (type) {
        case 'REQUEST_COUNT':
        case 'BURST_CONTROL':
            return !isMaxInteger(str);
        case 'PRICE_PER_REQUEST':
        case 'OVER_USAGE_PRICE':
            return !isMaxDecimal(str);
        case 'WHOLE_NUMBER':
            return !isMaxNumber(str);
        case 'PRICE':
            return !isMaxPrice(str);
        case 'EMAIL_ID':
            return isValidEmail(str);
        default:
            return false;
    }
}

function isMaxInteger(str) {
    const maxLength = 2147483647; //Maximum length allowed
    if (!isEmpty(str)) {
        const number = parseInt(str);
        return number <= maxLength;
    } else {
        return false;
    }
}

function isMaxDecimal(str) {
    const expression = /^\d+(\.\d{1,5})?$/;
    if (!isEmpty(str)) {
        return expression.test(str);
    } else {
        return false;
    }
}

function isMaxNumber(str) {
    const expression = /^\d{1,10}(\.\d+)?$/;
    if (!isEmpty(str)) {
        return expression.test(str);
    } else {
        return false;
    }
}

function isMaxPrice(str) {
    const expression = /^\d+(\.\d{1,2})?$/;
    if (!isEmpty(str)) {
        return expression.test(str);
    } else {
        return false;
    }
}

export function isValidEmail(str) {
    const expression = /^[a-zA-Z0-9\.\_\+\-\']+@[a-zA-Z0-9]+(?:[-.][a-zA-Z0-9]+)*\.[a-zA-Z]{2,}$/;
    if (!isEmpty(str)) {
        return expression.test(str);
    } else {
        return false;
    }
}


export function isValidPhoneNumber(str) {
    const expression = /^\d{10,}$/;
    if (!isEmpty(str)) {
        return expression.test(str);
    } else {
        return false;
    }
}


export function isValidString(str) {
    const expression = /^[a-zA-Z ]*$/;
    if (!isEmpty(str)) {
        return expression.test(str);
    } else {
        return false;
    }
}

