import IdConstants from '../data/idConstants';
import { portalViewData } from '../data/appConstants';

const initialState = {
  productCategories: [], filterCategories: [], filterCategoriesGroupBy: [], filteredProducts: [], publishedProductsCounts: {}, publishedProducts: {}, noProducts: false,
  postApplication: undefined, deleteApplicationData: undefined, editApplication: undefined, prodCount: 0,
  portalView: portalViewData.DESKTOP,
  countryCodeRegion: '',
  searchSuggestions: {},
  searchResults: {},
  trendingSearch: {},
  searchLoader: false
};

const LandingReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_PRODUCT_CATEGORIES':
      const sortedProdCategories = action.payload;
      const apiObj = action.payload.find((product) => product.categoryId === IdConstants.api.categoryId);
      sortedProdCategories.splice(sortedProdCategories.indexOf(apiObj), 1);
      sortedProdCategories.unshift(apiObj); //currently changing the order in UI by adding API as the first element in the array.
      return Object.assign({}, state, { productCategories: sortedProdCategories });
    case 'GET_PUBLISHED_PRODUCTS':
      return Object.assign({}, state, { publishedProducts: action.payload });
    case 'GET_PUBLISHED_PRODUCTS_COUNTS':
      return Object.assign({}, state, { publishedProductsCounts: action.payload });
    case 'GET_FILTER_CATEGORIES':
      return Object.assign({}, state, { filterCategories: action.payload });
    case 'GET_FILTER_CATEGORIES_GROUP_BY':
      return Object.assign({}, state, { filterCategoriesGroupBy: action.payload });
    case 'GET_FILTERED_PRODUCTS':
      return Object.assign({}, state, { publishedProducts: action.payload, filterCategories: action.payload.filters });
    case 'UPDATE_FILTERED_PRODUCTS':
      return Object.assign({}, state, { filteredProducts: action.payload, prodCount: action.count });
    case 'CREATE_APPLICATION':
      return Object.assign({}, state, { postApplication: action.payload });
    case 'NO_PRODUCTS':
      return Object.assign({}, state, { noProducts: action.payload });
    case 'EDIT_APPLICATION':
      return Object.assign({}, state, { editApplication: action.payload });
    case 'DELETE_APPLICATION':
      return Object.assign({}, state, { deleteApplicationData: action.payload });
    case 'SET_PORTAL_VIEW':
      return Object.assign({}, state, { portalView: action.payload });
    case 'COUNTRY_CODE_REGION':
      return Object.assign({}, state, { countryCodeRegion: action.payload });
    case 'GET_SEARCH_SUGGESTIONS':
      return Object.assign({}, state, { searchSuggestions: action.payload });
    case 'GET_TRENDING_SEARCH':
      return Object.assign({}, state, { trendingSearch: action.payload });
    case 'GET_SEARCH_RESULTS':
      return Object.assign({}, state, {
        searchResults: {
          ...action.payload,
          tabType: action?.tabType
        }
      });
    case 'SEARCH_SHOW_LOADER':
    case 'SEARCH_HIDE_LOADER':
      return Object.assign({}, state, { searchLoader: action.payload });
    default:
      return state;
  }
};

export default LandingReducer;