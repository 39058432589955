const getMetaDataDetails = (pathname) => {

    const metaTagDetails = [];
    let metaTitle = '';
    let metaDescription = '';
    const categoryIndex = pathname.split('/')[1];
    const subCategoryIndex = pathname.split('/')[2];
    const path = subCategoryIndex || categoryIndex;
    switch (path) {
        case 'api':
            metaTitle = 'META_DATA.API.META_TITLE';
            metaDescription = 'META_DATA.API.META_DESCRIPTION';
            break;
        case 'data':
            metaTitle = 'META_DATA.DATA.META_TITLE';
            metaDescription = 'META_DATA.DATA.META_DESCRIPTION';
            break;
        case 'solutions':
            metaTitle = 'META_DATA.SOLUTIONS.META_TITLE';
            metaDescription = 'META_DATA.SOLUTIONS.META_DESCRIPTION';
            break;
        case 'search':
            metaTitle = 'META_DATA.SEARCH.META_TITLE';
            metaDescription = 'META_DATA.SEARCH.META_DESCRIPTION';
            break;
        case 'get-in-touch':
            metaTitle = 'META_DATA.GET_IN_TOUCH.META_TITLE';
            metaDescription = 'META_DATA.GET_IN_TOUCH.META_DESCRIPTION';
            break;
        case 'about-us':
            metaTitle = 'META_DATA.ABOUT_US.META_TITLE';
            metaDescription = 'META_DATA.ABOUT_US.META_DESCRIPTION';
            break;
        case 'services':
            metaTitle = 'META_DATA.SERVICES.META_TITLE';
            metaDescription = 'META_DATA.SERVICES.META_DESCRIPTION';
            break;
        case 'contact-us':
            metaTitle = 'META_DATA.CONTACT_US.META_TITLE';
            metaDescription = 'META_DATA.CONTACT_US.META_DESCRIPTION';
            break;
        case 'hardware':
            metaTitle = 'META_DATA.HARDWARE.META_TITLE';
            metaDescription = 'META_DATA.HARDWARE.META_DESCRIPTION';
            break;
        case 'listing':
            metaTitle = 'META_DATA.LISTING.META_TITLE';
            metaDescription = 'META_DATA.LISTING.META_DESCRIPTION';
            break;
        case 'support':
            metaTitle = 'META_DATA.SUPPORT.META_TITLE';
            metaDescription = 'META_DATA.SUPPORT.META_DESCRIPTION';
            break;
        case 'product-guides':
            metaTitle = 'META_DATA.PRODUCT_GUIDES.META_TITLE';
            metaDescription = 'META_DATA.PRODUCT_GUIDES.META_DESCRIPTION';
            break;
        case 'product-videos':
            metaTitle = 'META_DATA.PRODUCT_VIDEOS.META_TITLE';
            metaDescription = 'META_DATA.PRODUCT_VIDEOS.META_DESCRIPTION';
            break;
        case 'cloud-infrastructure':
            metaTitle = 'META_DATA.CLOUD_INFRASTRUCTURE.META_TITLE';
            metaDescription = 'META_DATA.CLOUD_INFRASTRUCTURE.META_DESCRIPTION';
            break;
        case 'managed-services':
            metaTitle = 'META_DATA.MANAGED_SERVICES.META_TITLE';
            metaDescription = 'META_DATA.MANAGED_SERVICES.META_DESCRIPTION';
            break;
        case 'enterprise-licensing':
            metaTitle = 'META_DATA.ENTERPRISE_LICENSING.META_TITLE';
            metaDescription = 'META_DATA.ENTERPRISE_LICENSING.META_DESCRIPTION';
            break;
        case 'iot-software-services':
            metaTitle = 'META_DATA.IOT_SOFTWARE_SERVICES.META_TITLE';
            metaDescription = 'META_DATA.IOT_SOFTWARE_SERVICES.META_DESCRIPTION';
            break;
        case 'professional-services':
            metaTitle = 'META_DATA.PROFESSIONAL_SERVICES.META_TITLE';
            metaDescription = 'META_DATA.PROFESSIONAL_SERVICES.META_DESCRIPTION';
            break;
        case 'communication-services':
            metaTitle = 'META_DATA.COMMUNICATION_SERVICES.META_TITLE';
            metaDescription = 'META_DATA.COMMUNICATION_SERVICES.META_DESCRIPTION';
            break;
        case 'iot-&-ai':
            metaTitle = 'META_DATA.IOT_&_AI.META_TITLE';
            metaDescription = 'META_DATA.IOT_&_AI.META_DESCRIPTION';
            break;
        case 'location-&-mapping':
            metaTitle = 'META_DATA.LOCATION_&_MAPPING.META_TITLE';
            metaDescription = 'META_DATA.LOCATION_&_MAPPING.META_DESCRIPTION';
            break;
        case 'smart-city':
            metaTitle = 'META_DATA.SMART_CITY.META_TITLE';
            metaDescription = 'META_DATA.SMART_CITY.META_DESCRIPTION';
            break;
        case 'fintech':
            metaTitle = 'META_DATA.FINTECH.META_TITLE';
            metaDescription = 'META_DATA.FINTECH.META_DESCRIPTION';
            break;
        case 'parking':
            metaTitle = 'META_DATA.PARKING.META_TITLE';
            metaDescription = 'META_DATA.PARKING.META_DESCRIPTION';
            break;
        case 'environment':
            metaTitle = 'META_DATA.ENVIRONMENT.META_TITLE';
            metaDescription = 'META_DATA.ENVIRONMENT.META_DESCRIPTION';
            break;
        case 'government':
            metaTitle = 'META_DATA.GOVERNMENT.META_TITLE';
            metaDescription = 'META_DATA.GOVERNMENT.META_DESCRIPTION';
            break;
        case 'route-planning':
            metaTitle = 'META_DATA.ROUTE_PLANNING.META_TITLE';
            metaDescription = 'META_DATA.ROUTE_PLANNING.META_DESCRIPTION';
            break;
        case 'fuel-&-charging':
            metaTitle = 'META_DATA.FUEL_&_CHARGING.META_TITLE';
            metaDescription = 'META_DATA.FUEL_&_CHARGING.META_DESCRIPTION';
            break;
        case 'verification':
            metaTitle = 'META_DATA.VERIFICATION.META_TITLE';
            metaDescription = 'META_DATA.VERIFICATION.META_DESCRIPTION';
            break;
        case 'logistics-&-fleet':
            metaTitle = 'META_DATA.LOGISTICS_&_FLEET.META_TITLE';
            metaDescription = 'META_DATA.LOGISTICS_&_FLEET.META_DESCRIPTION';
            break;
        case 'fleet-management':
            metaTitle = 'META_DATA.FLEET_MANAGEMENT.META_TITLE';
            metaDescription = 'META_DATA.FLEET_MANAGEMENT.META_DESCRIPTION';
            break;
        case 'vehicle-data-analytics':
            metaTitle = 'META_DATA.VEHICLE_DATA_ANALYTICS.META_TITLE';
            metaDescription = 'META_DATA.VEHICLE_DATA_ANALYTICS.META_DESCRIPTION';
            break;
        case 'others':
            metaTitle = 'META_DATA.OTHERS.META_TITLE';
            metaDescription = 'META_DATA.OTHERS.META_DESCRIPTION';
            break;
        case 'data-protection-policy':
            metaTitle = 'META_DATA.DATA_PROTECTION_NOTICE.META_TITLE';
            metaDescription = 'META_DATA.DATA_PROTECTION_NOTICE.META_DESCRIPTION';
            break;
        case 'corporate-information':
            metaTitle = 'META_DATA.CORPORATE_INFORMATION.META_TITLE';
            metaDescription = 'META_DATA.CORPORATE_INFORMATION.META_DESCRIPTION';
            break;
        case 'terms-of-use':
            metaTitle = 'META_DATA.TERMS_AND_CONDITIONS.META_TITLE';
            metaDescription = 'META_DATA.TERMS_AND_CONDITIONS.META_DESCRIPTION';
            break;
        case 'legal-notice':
            metaTitle = 'META_DATA.LEGAL_NOTICE.META_TITLE';
            metaDescription = 'META_DATA.LEGAL_NOTICE.META_DESCRIPTION';
            break;
        default:
            metaTitle = 'META_DATA.HOME.META_TITLE';
            metaDescription = 'META_DATA.HOME.META_DESCRIPTION';
    }

    metaTagDetails.push(metaTitle);
    metaTagDetails.push(metaDescription);
    return metaTagDetails;
};

export default getMetaDataDetails;