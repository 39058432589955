import variables from '../variables.module.scss';

export const appTheme = {
  palette: {
    common: {
      //specifying the default colors for reference
      dark: '#000',
      light: '#FFFFFF'
    },
    primaryBackground: {
      main: '#007BC0',
      banner: '#005382',
      bgBody: '#FFFFFF'
    },
    secondaryBackground: {
      main: '#4199CB',
      bgBody: '#EFF1F2', // gray color for background
      light: '#007bc014'
    },
    primary: {
      // blue color
      dark: '#00629a', // darker version
      main: '#007BC0', // main version
      light: '#d1e4ff', //light version
      matt: '#5697BC', // matt version
      contrastText: '#fff' //default even if we dont specify
    },
    secondary: {
      //gray color
      dark: '#b2b9c0', // darker version
      main: '#d0d4d8', // main version
      light: '#eff1f2', //lighter version
      contrastText: '#fff' //default even if we dont specify
    },
    disabledColor: { main: '#C1C7CC', dark: '#8a9097' }, // gray version for disabled fields and buttons
    success: { main: '#00884A', light: '#B8EFC9', veryLight: '#f3feed' }, // green color 
    danger: { main: '#ED0007', light: '#ff8787' }, // red color
    warning: { dark: '#8F7300', main: '#FFCF00', light: '#FFEFD1' }, // yellow color
    patchColor: { main: '#18837E' }, // Pine Green
    profileStatus: { main: '#FFFFE0' }, // very light yellow in profile status
    endpointsBgColor: { main: '#0000000a' }, //gray shade used for endpoints accordion 
    casestudycolor: {main: '#00FFFF'}, //Turquoise color used in Casestudy
    orderSummayBgColor: {
      default: '#F8F9FB',
      paper: '#F1F1F1'
    },
    textfieldBgColor: { main: '#e0e2e5' }
  },
  typography: {
    fontFamily: variables.generalBoschFont,
    p: {
      fontFamily: variables.generalBoschFont,
      fontWeight: 'normal'
    },
    h4: {
      fontFamily: variables.generalBoschFont,
      fontWeight: 'bold'
    },
    h5: {
      lineHeight: 1.5,
      fontFamily: variables.generalBoschFont,
      fontWeight: 'bold',
      marginBottom: '15px'
    },
    h6: {
      fontFamily: variables.generalBoschFont,
      fontWeight: 'bold'
    },
    boldOnlyText: {
      fontFamily: variables.generalBoschFont,
      fontWeight: 'bold'
    },
    body1: {
      fontFamily: variables.generalBoschFont,
      fontWeight: 'normal',
      textAlign: 'left',
      fontSize: '14px'
    },
    body1Light: {
      fontFamily: variables.generalBoschFont,
      fontWeight: 'lighter',
      textAlign: 'left'
    },
    body1Bold: {
      fontFamily: variables.generalBoschFont,
      fontWeight: 'bold',
      textAlign: 'left'
    },
    subtitle1: {
      fontFamily: variables.generalBoschFont,
      fontWeight: 'normal'
    },
    headerMenuTitle: {
      fontSize: '1.1em',
      fontFamily: variables.generalBoschFont,
      fontWeight: 'bold',
      color: '#000',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    },
    bannerTitle: {
      fontFamily: variables.generalBoschFont,
      textAlign: 'left',
      fontSize: '24px',
      paddingRight: '20px',
      color: '#fff',
      fontWeight: 'normal',
      lineHeight: '36px',
      marginBottom: '4%',
      '@media(max-width:600px)': {
        fontSize: '14px',
        marginBottom: '8%'
      },
      '@media(max-width:900px)': {
        fontSize: '18px'
      }
    },
    bannerSubTitle: {
      fontFamily: variables.generalBoschFont,
      fontWeight: 'normal',
      color: '#fff',
      fontSize: '14px',
      '@media(max-width:600px)': {
        fontSize: '10px'
      }
    },
    boldText: {
      fontFamily: variables.generalBoschFont,
      fontWeight: 'bold',
      fontSize: '1rem'
    },
    lightText: {
      fontFamily: variables.generalBoschFont,
      fontWeight: 'lighter'
    },
    lightTextLightColor: {
      fontFamily: variables.generalBoschFont,
      fontWeight: 'lighter',
      color: 'rgba(0,0,0,0.7)'
    },
    smallText: {
      fontSize: '0.75rem',
      color: '#000',
      fontFamily: variables.generalBoschFont
    },
    smallBoldText: {
      fontSize: '0.75rem',
      color: '#000',
      fontFamily: variables.generalBoschFont,
      fontWeight: 'bold'
    },
    mediumText: {
      fontSize: '0.94rem',
      fontFamily: variables.generalBoschFont,
      fontWeight: 'normal'
    },
    mediumBoldText: {
      fontSize: '0.94rem',
      fontFamily: variables.generalBoschFont,
      fontWeight: 'bold'
    },
    largeText: {
      fontSize: '1.2rem',
      fontFamily: variables.generalBoschFont,
      textAlign: 'inherit',
      fontWeight: 'normal'
    },
    largeTextBold: {
      fontSize: '1.2rem',
      fontFamily: variables.generalBoschFont,
      fontWeight: 'bold'
    },
    mainHeading: {
      fontFamily: variables.generalBoschFont,
      fontWeight: 'normal',
      fontSize: '2rem'
    },
    mainTitle: {
      fontSize: '1.5rem',
      fontFamily: variables.generalBoschFont,
      fontWeight: 'normal'
    },
    mainTitleLight: {
      fontSize: '1.5rem',
      fontFamily: variables.generalBoschFont,
      fontWeight: '200 !important'
    },
    modalSubTitle: {
      fontFamily: variables.generalBoschFont,
      fontWeight: 'normal',
      fontSize: '0.9em'
    },
    modalSubTitleLight: {
      fontFamily: variables.generalBoschFont,
      fontWeight: 'lighter',
      fontSize: '0.9em'
    },
    modalSubTitle2: {
      fontFamily: variables.generalBoschFont,
      fontSize: '13px'
    },
    button: {
      fontSize: '1rem'
    }
  },
  components: {
    MuiListItemText: {
      styleOverrides: {
        variants: [
          {
            props: { variant: 'listItemLightText' },
            style: {
              fontWeight: 'lighter !important'
            }
          }
        ]
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: 'black',
            backgroundColor: 'rgba(25, 118, 210, 0.08)'
          }
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontWeight: 'bold !important'
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: variables.generalBoschFont,
          fontSize: '0.75rem',
          color: '#000'
        },
        variants: [
          {
            props: { variant: 'inputDialogLabel' },
            style: {
              fontSize: '1rem',
              fontFamily: variables.generalBoschFont,
              fontWeight: 'bold'
            }
          },
          {
            props: { variant: 'outlinedLabel' },
            style: {
              transform: 'none !important',
              position: 'relative !important'
            }
          },
          {
            props: { variant: 'selectLabel' },
            style: {
              transform: 'none !important',
              fontSize: '12px !important'
            }
          },
          {
            props: { variant: 'inputLabel' },
            style: {
              fontSize: '0.66rem !important',
              zIndex: '1',
              textAlign: 'left',
              marginLeft: '0.6rem'
            }
          }
        ]
      },
      MuiFilledInput: {
        styleOverrides: {
          root: {
            '& .MuiInputBase-formControl': {
              height: '60px !important'
            }
          }
        }
      },
      variants: [
        {
          props: { variant: 'nativeSelectfield' },
          style: {
            width: '100% !important',
            textOverflow: 'ellipsis',
            borderRadius: '0'
          }
        }
      ]
    },
    MuiButton: {
      styleOverrides: {
        root: {
          '&.MuiIconButton-root': {
            '&:hover': {
              backgroundColor: 'transparent !important'
            }
          },
        }
      },
      variants: [
        {
          props: { variant: 'borderedBtn' },
          style: {
            fontFamily: `${variables.generalBoschFont} !important`,
            color: '#007bc0',
            textTransform: 'capitalize !important',
            borderRadius: '0 !important',
            border: '1px #007bc0 solid !important',
            fontSize: '16px',
            '&:disabled': {
              opacity: '0.4 !important'
            }
          }
        },
        {
          props: { variant: 'whiteBorderedBtn' },
          style: {
            fontFamily: variables.generalBoschFont,
            color: 'white !important',
            textTransform: 'capitalize !important',
            borderRadius: '0 !important',
            border: '1px white solid',
            fontSize: '16px',
            boxShadow: 'none',
            '&:hover': {
              backgroundColor: '#00629a !important'
            },
            '&:disabled': {
              opacity: '0.4 !important'
            }
          }
        },
        {
          props: { variant: 'filledBtn' },
          style: {
            fontFamily: variables.generalBoschFont,
            color: 'white !important',
            backgroundColor: '#007bc0 !important',
            borderRadius: '0 !important',
            textTransform: 'capitalize',
            border: '0px !important',
            fontSize: '16px !important',
            boxShadow: 'none',
            '&:hover': {
              backgroundColor: '#00629a !important'
            },
            '&:disabled': {
              backgroundColor: '#c1c7cc !important',
              color: '#8a9097 !important'
            }
          }
        },
        {
          props: { variant: 'textBtn' },
          style: {
            border: 'none !important',
            background: 'none !important',
            color: '#007bc0',
            fontFamily: variables.generalBoschFont,
            fontSize: '1em !important',
            textTransform: 'none !important',
            paddingLeft: '0 !important',
            borderRadius: '0 !important',
            '&:disabled': {
              opacity: '0.4 !important'
            }
          }
        },
        {
          props: { variant: 'blackTextBtn' },
          style: {
            border: 'none !important',
            background: 'none !important',
            color: 'black !important',
            fontFamily: variables.generalBoschFont,
            fontSize: '14px !important',
            textTransform: 'none !important',
            padding: '6px 8px',
            borderRadius: '0 !important',
            '&:disabled': {
              opacity: '0.4 !important'
            }
          }
        },
        {
          props: { variant: 'greenBorderedBtn' },
          style: {
            fontFamily: variables.generalBoschFont,
            textTransform: 'capitalize !important',
            borderRadius: '0 !important',
            border: '1px solid rgba(139, 195, 74, 0.5) !important',
            fontSize: '16px',
            boxShadow: 'none',
            color: 'rgb(139, 195, 74) !important',
            '&:hover': {
              backgroundColor: 'rgba(139, 195, 74, 0.04)'
            },
            '&:disabled': {
              opacity: '0.4 !important'
            }
          }
        },
        {
          props: { variant: 'redBorderedBtn' },
          style: {
            border: '1px solid red !important',
            color: 'red !important',
            padding: '5px 7px !important',
            fontSize: '16px',
            fontFamily: variables.generalBoschFont,
            textTransform: 'capitalize !important',
            borderRadius: '0 !important',
            '&:disabled': {
              opacity: '0.4 !important'
            }
          }
        },
        {
          props: { variant: 'whiteFilledBtn' },
          style: {
            fontFamily: variables.generalBoschFont,
            backgroundColor: '#ffff',
            color: '#007bc0 !important',
            textTransform: 'capitalize !important',
            borderRadius: '0 !important',
            border: '1px #ffff solid',
            fontSize: '16px',
            boxShadow: 'none',
            '&:hover': {
              backgroundColor: '#f5f5f5 !important'
            },
            '&:disabled': {
              opacity: '0.4 !important'
            }
          }
        }
      ]
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: () => ({
          backgroundColor: 'white',
          color: 'rgba(0, 0, 0, 0.87)',
          boxShadow:
            '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)'
        })
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: 'black',
            backgroundColor: '#007bc0',
            '&:hover': {
              backgroundColor: '#007bc0'
            }
          },
          '&:hover': {
            backgroundColor: '#0000000a'
          }
        }
      }
    },
    MuiTextField: {
      defaultProps: {
        variant: 'filled',
        fullWidth: true
      },
      styleOverrides: {
        root: {
          fontFamily: variables.generalBoschFont,
          '&.Mui-disabled': {
            backgroundColor: '#EFF1F2'
          },
          '& ::placeholder': {
            fontSize: '0.8rem !important'
          },
          '&.search-field': {
            '& .MuiInputBase-input': {
              paddingTop: '10px !important',
              paddingBottom: '10px !important',
              paddingLeft: '10px',
              borderRadius: '0 !important',
              paddingRight: '0 !important'
            },
            '& .MuiFilledInput-input': {
              paddingTop: '4px !important'
            },
            '& .MuiFilledInput-root': {
              borderRadius: '0 !important'
            },
          },
          '&.user-input-field': {
            '& label': {
              color: 'black',
              fontSize: '14px !important',
              zIndex: 1,
              paddingLeft: '15px !important',
              paddingTop: '30px !important'
            },
            '& .MuiInputBase-input': {
              paddingLeft: '0.8rem !important',
              fontSize: '0.9rem !important'
            },
            '& .MuiInputBase-formControl': {
              width: 'auto',
              textOverflow: 'ellipsis',
              border: 'none',
              borderRadius: 0,
              borderBottom: '1px solid black',
              height: '50px',
              padding: '15px',
              paddingLeft: 0,
              paddingTop: '30px',
              backgroundColor: '#e0e2e5 !important'
            },
            '& .MuiFilledInput-root:before': {
              borderBottom: 'none'
            },
            '& .MuiFilledInput-root:after': {
              border: 'none !important'
            },
            '& .MuiInput-underline:after': {
              border: 'none !important'
            },
            '& .MuiInput-underline:before': {
              border: 'none !important'
            },
            '& .MuiInputBase-formControl:hover': {
              backgroundColor: '#c1c7cc !important'
            },
            '& .MuiInput-root.Mui-focused': {
              backgroundColor: '#d1e4ff !important'
            }
          },
          '&.user-input-select-field': {
            '& label': {
              fontSize: '14px !important',
              zIndex: 1
            },
            '& .MuiInputLabel-root.Mui-disabled': {
              color: 'black !important'
            },
            '& select:focus ': {
              borderRadius: '4px'
            },
            '& select:hover ': {
              borderBottom: '1px solid #015 !important'
            },
            '& .MuiInputBase-input': {
              paddingLeft: '10px !important'
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: 'Black'
            },
            '& .MuiFilledInput-input': {
              height: '36px',
              paddingTop: '10px !important'
            },
            '& .MuiFilledInput-root:before': {
              borderBottom: 'none'
            },
            '& .MuiFilledInput-root:after': {
              border: 'none !important'
            },
            '& .MuiInputBase-root-MuiInput-root': {
              width: 'inherit !important'
            },
            '& .MuiFormHelperText-root': {
              marginLeft: 0
            },
            '& option': {
              backgroundColor: '#b2b9c0 !important'
            },
            '& .MuiInputBase-formControl': {
              textOverflow: 'ellipsis',
              borderBottom: '1px solid black',
              paddingLeft: 0,
              backgroundColor: '#e0e2e5 !important'
            },
            '& .MuiInputBase-formControl:hover': {
              backgroundColor: '#c1c7cc !important',
              borderBottom: 'none'
            },
            '& .MuiInput-root.Mui-focused': {
              border: '2px solid #000',
              backgroundColor: '#d1e4ff !important'
            }
          },
          '&.user-input-select-field-without-label': {
            '& .MuiFilledInput-input': {
              height: '34px',
              width: '15.2rem',
              paddingTop: '3px'
            },
            '& .MuiFilledInput-root:before': {
              border: 'none'
            },
            '& .MuiFilledInput-root:after': {
              border: 'none !important'
            },
            '& .MuiInputLabel-root.Mui-disabled': {
              color: 'black !important'
            }
          },
          '&.user-input-select-field-label-outside': {
            marginTop: '8px',
            '& .MuiInputBase-root:hover:not(.Mui-disabled):before': {
              borderBottom: 'none'
            },
            '& .MuiInputBase-formControl': {
              textOverflow: 'ellipsis',
              paddingLeft: 0,
              backgroundColor: 'white !important'
            },
            '& .MuiInput-input': {
              border: '1px solid #ced4da',
              paddingLeft: '10px',
              height: '34px',
              width: '220px'
            },
            '& .MuiInput-input:focus': {
              border: '2px solid #000000 !important',
              backgroundColor: '#b2b9c0',
              height: '32px',
              width: '219px'
            },
            '& .MuiInput-root:before': {
              borderBottom: 'none'
            },
            '& .MuiInput-root:after': {
              borderBottom: 'none'
            },
            '& option': {
              backgroundColor: '#b2b9c0 !important'
            },
            '& select': {
              backgroundColor: '#d0d4d8'
            },
            '& select:focus': {
              backgroundColor: '#d0d4d8'
            },
            '& select:hover': {
              backgroundColor: '#b2b9c0'
            }
          },
          '&.user-input-select-field-without-label-gray-background': {
            marginTop: '8px',
            '& .MuiInputBase-root:hover:not(.Mui-disabled):before': {
              borderBottom: 'none'
            },
            '& .MuiInputBase-formControl': {
              textOverflow: 'ellipsis',
              paddingLeft: 0,
              backgroundColor: '#EFF1F2 !important'
            },
            '& .MuiInput-input': {
              border: '1px solid #ced4da',
              paddingLeft: '10px',
              height: '37px',
              width: '260px'
            },
            '& .MuiInput-input:focus': {
              borderBottom: 'none',
              backgroundColor: '#b2b9c0',
              border: '2px solid #000000 !important',
              height: '35px',
              width: '259px'
            },
            '& .MuiInput-root:before': {
              borderBottom: 'none'
            },
            '& .MuiInput-root:after': {
              borderBottom: 'none'
            },
            '& .MuiFilledInput-input': {
              height: '34px',
              width: '15.2rem'
            },
            '& option': {
              backgroundColor: '#b2b9c0 !important'
            }
          },
          '&.user-input-date-picker': {
            border: '1px solid #ced4da',
            '& .MuiInputBase-root:hover:not(.Mui-disabled):before': {
              borderBottom: 'none'
            },
            '& .MuiInputBase-formControl': {
              textOverflow: 'ellipsis',
              paddingLeft: 0,
              height: '43px',
              backgroundColor: 'white !important'
            },
            '& .MuiFilledInput-input': {
              fontSize: '12px',
              width: '15.2rem',
              paddingTop: '10px'
            },
            '& .MuiFilledInput-root:before': {
              border: 'none'
            },
            '& .MuiFilledInput-root:after': {
              border: 'none !important'
            }
          }
        }
      }
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: 'primary !important',
          '&:hover': {
            backgroundColor: 'transparent'
          }
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: 'primary !important'
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: 'red !important',
          fontFamily: variables.generalBoschFont
        }
      }
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '&$selected': {
            backgroundColor: '#d9ebf7'
          },
          '&$selected:hover': {
            backgroundColor: '#d9ebf7'
          },
          '&$focusVisible': {
            backgroundColor: '#d9ebf7'
          }
        }
      }
    },
    MuiTable: {
      styleOverrides: {
        root: {
          fontFamily: variables.generalBoschFont,
          '& .MuiTableCell-root': {
            fontFamily: variables.generalBoschFont
          }
        }
      }
    },
    MuiRating: {
      styleOverrides: {
        root: {
          color: '#007BC0'
        }
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            width: '24px',
            height: '24px'
          }
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          '& :hover': {
            color: '#007BC0'
          }
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontFamily: variables.generalBoschFont,
          textTransform: 'none'
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 0
        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: {
        '*::-webkit-scrollbar-thumb': {
          background: '#cccecf'
        },
        '*::-webkit-scrollbar-thumb:hover': {
          background: '#555'
        }
      }
    }
  }
};
