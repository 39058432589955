import axios from 'axios';
import { CART_ACTION, DOCUMENT_SERVICE_CAPABILITIES_ACTION, PRICING_PLAN_CAPABILITIES_ACTION, PRODUCT_SERVICE_CAPABILITIES_ACTION, AAA_CONFIG } from './configuration';
import idConstants from '../data/idConstants';
import { getUserDetails } from './api/ApiStatisticsAction';

export const updateCartProduct = (payload) => {
  return (dispatch) =>
    axios({
      method: 'PUT',
      url: CART_ACTION.PRODUCT_CART,
      headers: {
        'Content-Type': 'application/json'
      },
      data: payload
    })
      .then((response) => {
        dispatch({
          type: 'ADD_OR_REMOVE_CART_QUANTITY',
          payload: payload
        });

        /* Fetch and update the cart items whenever the cart items is getting added, updated or deleted (Includes quantity) */
        if (payload.quantity === 0) {
          // Argument should be true to update the pricing details as cart item is deleted
          dispatch(getCartProduct(true));
        } else if (payload.isGetCartCount) {
          dispatch(getCartProduct(false));
        }

        if (payload.isFetchPricingDetails) {
          window.localStorage.setItem('addToCart', 'true');
        } else {
          window.localStorage.setItem('addToCart', 'false');
        }

        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error
        });
      });
};

export const getCartProduct = (isFetchPricingDetails) => {
  return (dispatch) => {
    dispatch({
      type: 'CART_SHOW_LOADER',
      payload: true
    });
    return axios({
      method: 'GET',
      url: CART_ACTION.PRODUCT_CART,
      headers: {
        'Content-Type': 'application/json'
      },
      data: {}
    })
      .then((response) => {
        let productIds = '';
        let productArr = [];
        const uniqueProductIds = new Set();
        response?.data?.forEach((cartObj) => {
          cartObj.items?.forEach((product) => {
            if (!uniqueProductIds.has(product.productId)) {
              uniqueProductIds.add(product.productId);
              productIds += `${product.productId},`;
              productArr.push({
                itemId: product.productId,
                language: null
              });
            }
          });
        });

        dispatch({
          type: 'GET_CART',
          payload: {
            data: response.data,
            count: response?.headers['total-entry-count']
          }
        });

        // Pricing Details API will be called if isFetchPricingDetails is true.
        if (response?.data?.length > 0) {

          if (!isFetchPricingDetails) return;
          const productIdArr = productArr.map(productArr => productArr.itemId);
          const documentReqPayload = {
            itemIds: productIdArr,
            documentTypeId: idConstants.documentType.thumbnailImg
          };

          Promise.all([
            getProductDetails(productIdArr),
            getPricingDetails(productArr),
            getDocumentDetails(documentReqPayload)
          ]).then(([productDetailResponse, pricingDetailResponse, documentDetailResponse]) => {
            if (pricingDetailResponse?.length > 0 && productDetailResponse?.length > 0) {
              dispatch({
                type: 'CART_HIDE_LOADER',
                payload: false
              });
              dispatch({
                type: 'GET_CART',
                payload: {
                  data: response.data,
                  count: response?.headers['total-entry-count']
                }
              });
              const cartPricingDetails = [];
              const sellerUserIds = Array.from(new Set(productDetailResponse.map(productDetail => productDetail.createdBy))).join(',');
              dispatch(getUserDetails(sellerUserIds, AAA_CONFIG.SELLER_ROLE))
                .then(userDetailResponse => {
                  productDetailResponse.forEach(productDetail => {
                    const thumbnailImageUrl = documentDetailResponse.find(documentDetail => documentDetail.productId === productDetail.productId)?.blobFileUrl;
                    pricingDetailResponse.forEach(pricingDetail => {
                      if (productDetail?.productId === pricingDetail?.itemId) {
                        const sellerCompanyDetails = userDetailResponse.find(userDetail => userDetail.userId === productDetail.createdBy)?.companyDetails;
                        cartPricingDetails.push({
                          ...pricingDetail,
                          product: {
                            productName: productDetail.productName,
                            thumbnailImageUrl: thumbnailImageUrl,
                            userDetails: {
                              companyDetails: sellerCompanyDetails
                            }
                          }
                        });
                      }
                    });
                  });
                  dispatch({
                    type: 'PRICING_DETAILS',
                    payload: cartPricingDetails
                  });
                })
                .catch((error) => {
                  dispatch({
                    type: 'ERROR',
                    error: error
                  });
                  dispatch({
                    type: 'CART_HIDE_LOADER',
                    payload: false
                  });
                });
            }
          });
        } else {
          dispatch({
            type: 'RESET_CART'
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error
        });
        dispatch({
          type: 'CART_HIDE_LOADER',
          payload: false
        });
      });
  };
};

export const getPricingDetails = (payload) => {
  return (
    axios({
      method: 'POST',
      url: PRICING_PLAN_CAPABILITIES_ACTION.GET_PRICING_DETAILS_LIST_BY_ID,
      headers: {
        'Content-Type': 'application/json'
      },
      data: payload
    })
      .then((response) => {
        return response.data;
      })
  );
};

export const getProductDetails = (payload) => {
  return(
    axios({
      method: 'POST',
      url: PRODUCT_SERVICE_CAPABILITIES_ACTION.GET_PRODUCT_DETAILS_LIST_BY_ID,
      headers: {
        'Content-Type': 'application/json'
      },
      params: {
        productPublishedTenant: 'mos'
      },
      data: payload
    })
      .then((response) => {
        return response.data;
      })
    );
};

export const getDocumentDetails = (payload) => {
  return (
    axios({
      method: 'POST',
      url: DOCUMENT_SERVICE_CAPABILITIES_ACTION.GET_PRODUCT_DOCS_LIST_BY_ID,
      headers: {
        'Content-Type': 'application/json'
      },
      data: payload
    })
      .then((response) => {
        return response.data;
      })
    );
};

export const resetCart = () => {
  return (dispatch) =>
    dispatch({
      type: 'RESET_CART'
    });
};

export const getMinimumOrderQuantity = (productId) => {
  return (dispatch) =>
    axios({
      method: 'GET',
      url: CART_ACTION.GET_MINIMUM_ORDER_QUANTITY + productId,
      headers: {
        'Content-Type': 'application/json'
      },
      data: {}
    })
      .then((response) => {
        dispatch({
          type: 'GET_CART_PRODUCT_QUANTITY',
          payload: response.data
        });
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          error: error
        });
      });
};
