import React from 'react';
import { Container, CssBaseline, Typography, Grid, Box, Button, Tooltip } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { DOCS, IMAGE_CDN } from '../../../actions/configuration';
import Images from '../../../assets/img';
import styles from './support-section.module.scss';
import i18next from 'i18next';
import reactGA from 'react-ga';
import Loader from '../../Shared/Utils/Loader';
import DeviceBreakPoints from '../../../deviceBreakPoints';

function SupportSection() {
    const { isMediumDevice } = DeviceBreakPoints();

    const history = useHistory();

    const [loader, setLoader] = React.useState(false);

    const downloadDoc = (url) => {
        setLoader(true);
        reactGA.event({
            category: 'Download User Manual in Support Page',
            action: 'User clicked on Download User Manual',
            label: 'User Manual'
        });
        const req = new XMLHttpRequest();
        req.open('GET', url, true);
        req.responseType = 'blob';
        req.onload = function () {
            const blob = new Blob([req.response], { type: 'application/octetstream' });
            const urlLink = window.URL || window.webkitURL;
            const link = urlLink.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.setAttribute('download', url.split('/').pop());
            anchor.setAttribute('href', link);
            document.body.appendChild(anchor);
            anchor.click();
            setLoader(false);
            document.body.removeChild(anchor);
        };
        req.send();
    };

    const supportLink = () => {
        window.scrollTo(0, 0);
        history.push('/support');
    };

    const features = [
        { 'id': 1, 'name': i18next.t('HOME_PAGE.SUPPORT_SECTION.USER_ONBOARDING_MANUAL'), 'icon': Images.userOnboardingManual, 'class': styles.mailSupportIcon, 'onclick': () => downloadDoc(DOCS.USER_ONBOARDING_MANUAL), isTooltip: true },
        { 'id': 2, 'name': i18next.t('HOME_PAGE.SUPPORT_SECTION.USER_MANUAL'), 'icon': Images.userManualIcon, 'class': styles.documentSupportIcon, 'onclick': () => downloadDoc(DOCS.USER_MANUAL), isTooltip: true },
        { 'id': 3, 'name': i18next.t('HOME_PAGE.SUPPORT_SECTION.FAQS'), 'icon': Images.faq, 'class': styles.mailSupportIcon, 'onclick': supportLink, isTooltip: false }
    ];

    return (
        <React.Fragment>
            <CssBaseline />
            <Box className={`${styles['support-section-bg']}  ${styles['support-section-wrapper']}`} sx={{ backgroundImage: `url(${IMAGE_CDN}background-waves-pattern.png)`, backgroundColor: 'secondaryBackground.main' }} pt={6} pb={10} pl={6}>
                <Container className={`${styles['support-section-box-wrapper']}`} >
                    <Grid container >
                        <Grid container md={12} sm={12} xs={12}>
                            <Grid item md={6} sm={7} xs={12} className={`${styles['support-section-heading-wrapper']}`}>
                                <Typography align={'left'} sx={{ color: 'common.light' }} variant='mainHeading' component='h6'>
                                    <Box component='span'>{' '}<Typography component='span' variant='mainHeading' sx={{ fontWeight: 'bold' }}>{i18next.t('HOME_PAGE.SUPPORT_SECTION.WE_ARE')}.</Typography></Box>
                                    <Box className={`${styles['text-subtitle']}`}>
                                        <Typography variant='body1'>{i18next.t('HOME_PAGE.SUPPORT_SECTION.MULTIPLE_OPTIONS')}</Typography>
                                        <br />
                                    </Box>
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid container md={12} sm={12} xs={12}>
                            <Grid container md={9} sm={8} xs={12} pt={4} pb={4} className={`${styles['support-section-features-container']} ${styles['support-section-features-wrapper']}`}>
                                {features.map((item) => (
                                    <Tooltip key={item?.id} title={item.isTooltip ? i18next.t('SHARED.DOWNLOAD_BUTTON') : ''}>
                                        <Grid item md={3} sm={4} xs={4} className={`${styles['support-section-grid-wrapper']}`}>
                                            <Button className={`${styles['support-section-features-btn']}`} onClick={item.onclick}  >
                                                <Box className={`${styles['support-section-features']}`}>
                                                    <Box sx={{ backgroundColor: 'primaryBackground.bgBody' }} className={`${styles['circle-bordered']}`}>
                                                        <Box component='img' className={`${styles['icon-cursor-styling']} ${item.class}`} src={item.icon} alt={item.name} />
                                                    </Box>
                                                    <Box sx={{ color: 'common.light' }} className={`${styles['icon-cursor-label']}`}><Typography variant='body1' align='center' fontSize={isMediumDevice ? '0.75rem' : '1rem'}>{item.name}</Typography></Box>
                                                </Box>
                                            </Button>
                                        </Grid>
                                    </Tooltip>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container md={4} xs={12} sm={4} className={`${styles['support-section-img-container']}`}>
                        <Grid item md={12} sm={12} xs={12} className={`${styles['support-section-img-wrapper']}`}>
                            <Box component='img' src={Images.supportsectionHomepage} alt={i18next.t('SHARED.SUPPORT_BANNER_ALT')} />
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            {loader && <Loader />}
        </React.Fragment>
    );
}

export default SupportSection;
