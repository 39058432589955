import React, { useState, useEffect } from 'react';
import { Box, Container, Grid, Typography, Modal, IconButton, Card, CardContent } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Slider from 'react-slick';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import i18next from 'i18next';
import testimonialData from '../../../data/testimonialData';
import styles from './testimonials.module.scss';
import DeviceBreakPoints from '../../../deviceBreakPoints';

function Testimonials() {
    const [imgIndex, setImgIndex] = useState(0);
    const [finalTestimonialData, setFinalTestimonialData] = useState([]);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [modalState, setModalState] = useState(false);
    const [modalContent, setModalContent] = useState({});
    const maxSlidesToShow = 3;
    const { isMediumDevice, isSmallDevice, isTab } = DeviceBreakPoints();

    useEffect(() => {
        if (testimonialData.length > 0 && testimonialData.length <= maxSlidesToShow) {
            setFinalTestimonialData(testimonialData.concat(testimonialData));
        } else {
            setFinalTestimonialData(testimonialData);
        }
    }, []);

    const openModal = (value, body) => {
        setModalState(value);
        setModalContent(body);
    };

    const closeModal = (value) => {
        setModalState(value);
    };

    const TestimonialsNextArrow = ({ onClick }) => {
        let className = `${styles['testimonial-arrow']} ${styles['testimonial-next']}`;
        let backgroundColor = 'common.light';

        if (isSmallDevice) {
            className += ` ${styles['testimonial-next-mobile']}`;
        } else if (isMediumDevice) {
            className += ` ${styles['testimonial-next-small-device']}`;
        } else if (isTab) {
            className += ` ${styles['testimonial-next-tab']}`;
        }
        return (
            <Box
                backgroundColor={backgroundColor}
                className={className}
                sx={{ color: 'primary.main' }}
                onClick={onClick}
            >
                <ArrowForwardOutlinedIcon />
            </Box>
        );
    };
    const TestimonialsPrevArrow = ({ onClick }) => {
        let className = `${styles['testimonial-arrow']} ${styles['testimonial-prev']}`;
        let backgroundColor = 'common.light';

        if (isSmallDevice) {
            className += ` ${styles['testimonial-prev-mobile']}`;
        } else if (isMediumDevice) {
            className += ` ${styles['testimonial-prev-small-device']}`;
        } else if (isTab) {
            className += ` ${styles['testimonial-prev-tab']}`;
        }

        return (
            <Box
                backgroundColor={backgroundColor}
                className={className}
                sx={{ color: 'primary.main' }}
                onClick={onClick}
            >
                <ArrowBackOutlinedIcon />
            </Box>
        );
    };

    const getClassName = (index) => {
        if (index < testimonialData?.length) {
            if (index === currentSlide) return `${styles['dot-active']}`;
            else return `${styles['dot']}`;
        } else return `${styles['none']}`;
    };


    const testimonialsSettings = {
        infinite: true,
        dots: true,
        speed: 700,
        slidesToShow: 3,
        centerMode: true,
        centerPadding: '0px',
        nextArrow: <TestimonialsNextArrow />,
        prevArrow: <TestimonialsPrevArrow />,
        beforeChange: (current, next) => setImgIndex(next),
        afterChange: (index) => {
            if (index < testimonialData.length) {
                setCurrentSlide(index);
            } else {
                setCurrentSlide(index - testimonialData.length);
            }
        },
        customPaging: function (index) {
            return (
                <Box sx={{ color: index === currentSlide ? 'primary.main' : 'secondary.main' }}
                    className={getClassName(index)}>
                    &#9679;
                </Box>
            );
        }
    };

    const getImageStyle = () => {
        let imageStyle = `${styles['slide']} ${styles['slide-active']}`;
        if (isSmallDevice) {
            imageStyle += ` ${styles['slide-active-responsive']} ${styles['height-tab']}`;
        } else if (isTab) {
            imageStyle += ` ${styles['height-tab']}`;
        }
        return imageStyle;
    };

    const getElementStyle = () => {
        let elementStyle = `${styles['slide']}`;
        if (isSmallDevice) {
            elementStyle += ` ${styles['slide-responsive']} ${styles['height-tab']}`;
        } else if (isTab) {
            elementStyle += ` ${styles['height-tab']}`;
        }
        return elementStyle;
    };

    const paddingBottom = () => {
        if (isSmallDevice)
            return 3;
        else if (isMediumDevice)
            return 1;
        else
            return 2;
    };

    return (
        <>
            <Box py={3} sx={{ backgroundColor: 'primaryBackground.bgBody' }}></Box>
            <Box sx={{ backgroundColor: 'secondaryBackground.bgBody' }} className={isSmallDevice ? styles['container-padding-responsive'] : styles['container-padding']}>
                <Typography variant='h5' align='center'>{i18next.t('HOME_PAGE.TESTIMONIALS')}</Typography>
                <Container className={isSmallDevice ? styles['container-padding-responsive'] : styles['container-padding']}>
                    <Slider {...testimonialsSettings}>
                        {finalTestimonialData.map((slide, index) => (
                            <>
                                <Card
                                    sx={{ backgroundColor: 'primaryBackground.bgBody' }}
                                    variant='outlined'
                                    square
                                    className={
                                        index === imgIndex 
                                        ? getImageStyle() 
                                        : getElementStyle()
                                    }
                                >
                                    <CardContent className={styles['card-content']}
                                    >
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <Box>
                                                <Box px={3} py={2} className={isTab ? styles['description-style-responsive'] : styles['description-style']}>
                                                    {(() => {
                                                        if (slide.description.length > 315) {
                                                            const desc = slide.description.substring(0, Math.min(slide.description.length, 315)) + '...';
                                                            return (
                                                                <>
                                                                    <Typography variant='p' component='span'>{desc}</Typography>
                                                                    <a className={styles['link']} onClick={() => openModal(true, slide)}>{i18next.t('SHARED.READ_MORE')}</a>

                                                                </>
                                                            );
                                                        } else {
                                                            return (<Typography variant='p' component='span'>{slide.description}</Typography>);
                                                        }
                                                    })()}
                                                </Box>
                                                <Box className={styles['logo-section']}>
                                                    <Box px={10} pb={paddingBottom()}>
                                                        <Box component='img' src={slide.icon} alt={i18next.t('HOME_PAGE.SLIDE_ICON_ALT_TEXT')} className={isSmallDevice ? styles[slide.iconClassResponsive] : styles[slide.iconClass]} />
                                                    </Box>
                                                    <Box px={10} pb={2} className={isSmallDevice ? `${styles['mobile-responsive']}` : ''}>
                                                        <Box>
                                                            <Typography variant='boldText' fontSize={isMediumDevice ? '12px' : '16px'}>{slide.name}</Typography>
                                                        </Box>
                                                        <Box>
                                                            <Typography variant='lightText' fontSize={isMediumDevice ? '12px' : '14px'}>{slide.designation}</Typography>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </CardContent>

                                </Card>
                            </>
                        ))}
                    </Slider>
                </Container>
            </Box>
            <Box py={2} sx={{ backgroundColor: 'primaryBackground.bgBody' }}></Box>
            {modalState && <Modal
                aria-labelledby='simple-modal-title'
                aria-describedby='simple-modal-description'
                open={modalState}
                onClose={() => closeModal(false)}
                sx={{
                    zIndex: 10000
                }}
            >
                <Box className={isSmallDevice ? `${styles['modal-responsive']} ${styles['modal']}` : styles['modal']} sx={{ backgroundColor: 'primaryBackground.bgBody' }}>
                    <Grid container>
                        <Grid item xs={11}>
                        </Grid>
                        <Grid item xs={1}>
                            <IconButton
                                className={styles['close-icon']}
                                onClick={() => closeModal(false)} size='large' ><CloseIcon size='small' /></IconButton>
                        </Grid>
                        <Grid item xs={12}>
                            <Box className={styles['modal-box']}>
                                <Box px={3} py={2} className={isSmallDevice ? styles['modal-description-style-responsive'] : styles['modal-description-style']}>
                                    {modalContent.description}
                                </Box>
                                <Box pb={paddingBottom()}>
                                    <Box component='img' src={modalContent.icon} alt={i18next.t('HOME_PAGE.SLIDE_ICON_ALT_TEXT')} className={isSmallDevice ? styles[modalContent.iconClassResponsive] : styles[modalContent.iconClass]} />
                                </Box>
                                <Box px={10} pb={2} className={isSmallDevice ? `${styles['mobile-responsive']}` : ''}>
                                    <Box>{modalContent.name}</Box>
                                    <Box>{modalContent.designation}</Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>}
        </>
    );
}

export default Testimonials;