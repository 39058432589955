import React, { useEffect } from 'react';
import { Container, Grid, Box, Typography } from '@mui/material';
import i18next from 'i18next';
import HeaderContainer from '../containers/header';
import BreadCrumb from '../components/BaseLayout/BreadCrumb';
import Footer from '../components/BaseLayout/Footer';
// import CaseStudies from '../components/Landing/CaseStudies';
import Testimonials from '../components/Landing/Testimonials';
import styles from './about-us.module.scss';
import AnimatedSpriteImage from '../components/Landing/HomepageBanner/AnimatedSpriteImage/animatedSpriteImage';
import images from '../assets/img';
import OurEcoSystem from '../components/Shared/Utils/OurEcoSystem';
import { IMAGE_CDN } from '../actions/configuration';

const AboutUs = (props) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
        <>
            <HeaderContainer {...props} />
            <BreadCrumb />
            <Container className={styles['aboutus-banner-container']} sx={{ backgroundColor: 'primaryBackground.banner', backgroundImage: `url(${IMAGE_CDN}revamped-homepage-banner-background.png)` }}>
                <Grid container className={styles['aboutus-banner-content-grid']}>
                    <Grid item xs={6}>
                        <Grid container justifyContent='flex-start'>
                            <Grid item alignItems='center'>
                                <Typography variant='bannerTitle' component='h1'>
                                    {i18next.t('SHARED.JEST_FEW_CLICKS_AWAY')}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container justifyContent='flex-end'>
                            <Grid item>
                                <Box
                                    component='img'
                                    src={images.fewClicksAwayImage}
                                    className={styles['aboutus-banner-image']}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
            <Box sx={{ backgroundColor: 'primaryBackground.bgBody' }}>
                <Container>
                    <Grid container xs={12} sm={12} md={12} lg={12} className={styles['our-story-container']} >
                        <Grid item xs={12} sm={12} md={12} lg={12} >
                            <Typography variant='h5'>{i18next.t('ABOUT_US_PAGE.OUR_STORY.TITLE')}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} >
                            <Typography variant='body1'>{i18next.t('ABOUT_US_PAGE.OUR_STORY.BODY')}.</Typography>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Box backgroundColor='secondaryBackground.bgBody'>
                <Container container item xs={12} sm={12} md={12} lg={12} className={styles['market-overview-container']}>
                    <Grid item xs={12} sm={12} md={12} lg={12} className={styles['market-overview-container-grid1']} >
                        <Typography variant='h5'>{i18next.t('ABOUT_US_PAGE.MARKETPLACE_OVERVIEW.TITLE')}</Typography>
                    </Grid>
                    <Grid container item xs={12} sm={12} md={12} lg={12} className={styles['market-overview-container-order']}>
                        <Grid item xs={12} sm={6} md={6} lg={6} className={styles['content-align-left']}>
                            <Typography variant='body1'>{i18next.t('ABOUT_US_PAGE.MARKETPLACE_OVERVIEW.BODY_1_TEXT_1')} <Typography variant='boldText' component='span'> {i18next.t('ABOUT_US_PAGE.MARKETPLACE_OVERVIEW.BODY_1_BOLD_1')}</Typography>{i18next.t('ABOUT_US_PAGE.MARKETPLACE_OVERVIEW.BODY_1_TEXT_2')} <Typography variant='boldText' component='span'> {i18next.t('ABOUT_US_PAGE.MARKETPLACE_OVERVIEW.BODY_1_BOLD_2')}</Typography>{i18next.t('ABOUT_US_PAGE.MARKETPLACE_OVERVIEW.BODY_1_TEXT_3')}</Typography><br />
                            <Typography variant='body1'>{i18next.t('ABOUT_US_PAGE.MARKETPLACE_OVERVIEW.BODY_2')}</Typography><br />
                            <Typography variant='body1'>{i18next.t('ABOUT_US_PAGE.MARKETPLACE_OVERVIEW.BODY_3_TEXT_1')}-  <Typography variant='boldText' component='span'>{i18next.t('ABOUT_US_PAGE.MARKETPLACE_OVERVIEW.BODY_3_BOLD')}</Typography>{i18next.t('ABOUT_US_PAGE.MARKETPLACE_OVERVIEW.BODY_3_TEXT_2')}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6} className={styles['animated-img-grid']}>
                            <AnimatedSpriteImage path={i18next.t('SHARED.ABOUT_US')} />
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <OurEcoSystem />
            {/* <CaseStudies /> */}
            <Testimonials />
            <Footer />
        </>
    );
};
export default AboutUs;