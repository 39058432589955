import React, { useEffect, useState } from 'react';
import { Grid, Card, CardContent, CardMedia, Box, Typography, Tooltip, Modal, IconButton, alpha, Link } from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import i18next from 'i18next';
import styles from './carousel.module.scss';
import images from '../../../assets/img';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import { Ecosystem } from '../../../data/ourEcoSystemData';
import DeviceBreakPoints from '../../../deviceBreakPoints';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import ArrowBackIosNewSharpIcon from '@mui/icons-material/ArrowBackIosNewSharp';
import CloseIcon from '@mui/icons-material/Close';


const CarouselContainer = (props) => {
  const [play, setPlay] = useState(false);
  const [open, setOpen] = useState(false);

  const { isMediumDevice, smScreen, isSmallDevice, theme } = DeviceBreakPoints();
  let carouselData = props?.heading === (i18next.t('PRODUCT.FEATURES') || i18next.t('PRODUCT.PRODUCT_HIGHLIGHTS')) ?
    props?.carouselData?.filter(item => item.header) :
    props?.carouselData;

  const [chunkedData, setChunkedData] = useState([]);
  const [currentMedia, setCurrentMedia] = useState(null);
  const [showHeaderTooltip, setShowHeaderTooltip] = useState(false);
  const [showDescTooltip, setShowDescTooltip] = useState(false);
  const [innerIndexTooltip, setInnerIndexTooltip] = useState(0);
  const [outerIndexTooltip, setOuterIndexTooltip] = useState(0);

  const setIndex = (innerIndex, outerIndex) => {
    setInnerIndexTooltip(innerIndex);
    setOuterIndexTooltip(outerIndex);
  };

  const getDataSize = () => {
    if (isSmallDevice) {
      return 1;
    }
    else if (smScreen) {
      if (isMediumDevice) {
        return 2;
      }
      else {
        return 3;
      }
    }
  };

  useEffect(() => {
    if (props?.heading === i18next.t('PRODUCT.FEATURES') || props?.heading === i18next.t('PRODUCT.PRODUCT_HIGHLIGHTS')) {
      carouselData.sort((highlight1, highlight2) => highlight1.order - highlight2.order);
    }
    let tempData = [];
    for (let index = 0; index < carouselData?.length; index += getDataSize()) {
      tempData.push(carouselData?.slice(index, index + getDataSize()));
    }
    setChunkedData(tempData);
  }, [props.carouselData, isSmallDevice, isMediumDevice, smScreen]);

  const handleClose = () => {
    setOpen(false);
    setPlay(false);
  };

  const handleOpen = (index, innerIndex) => {
    setCurrentMedia(chunkedData[index][innerIndex]);
    setOpen(true);
    chunkedData[index][innerIndex]?.type !== 'image' && setPlay(true);
  };
  const Header = () => {
    return (
      <Typography variant='mainTitle' className={styles['sub-heading']}>
        {props?.heading}
      </Typography>
    );
  };

  const loadDefaultImage = (index) => {
    if (document.getElementById(`highlight-image-${index}`))
      document.getElementById(`highlight-image-${index}`).src = images.solutions.defaultHighlightImage;
  };

  const displayVideoComponent = (item, index, innerIndex) => {
    return (['mp4'].includes(
      item?.documentName?.substring(
        item?.documentName?.lastIndexOf('.') + 1
      )
    ) ? (
      <>
        <video
          className={styles['video-box']}
          onClick={() => handleOpen(index, innerIndex)}
        >
          <source src={item?.src} />
          {i18next.t('SHARED.VIDEOS_ERROR')}.
        </video>
        <Box component='img'
          src={images.videoPlayIcon}
          className={styles['video-play-icon']}
          title={i18next.t('SHARED.PLAY_VIDEO')}
          onClick={() => handleOpen(index, innerIndex)}
        />
      </>
    ) : (
      <Box className={styles['no-supported-format-style']}>
        <Link
          href={item?.src}
          target='_blank'
          rel='noopener noreferrer'
          className={styles['file-link-style']}
          sx={{ color: alpha(theme.palette.common.dark, 0.87) }}
          title={i18next.t('SOLUTION_DETAIL.DOWNLOAD_VIDEO_TITLE')}
        >
          <IconButton size='large'>
            <DownloadForOfflineIcon sx={{ fontSize: 40 }} />
          </IconButton>
        </Link>
      </Box>
    ));
  };

  return (
    <Grid container>
      {props?.heading !== i18next.t('ABOUT_US_PAGE.OUR_ECOSYSTEM.TITLE') && <Header />}
      <Grid container className={styles['product-guide-container']}>
        <Carousel
          className={styles['product-guide-carousel']}
          autoPlay={false}
          animation='slide'
          timeout={300}
          navButtonsAlwaysVisible={true}
          indicators={props?.heading === i18next.t('ABOUT_US_PAGE.OUR_ECOSYSTEM.TITLE') ? true : false}
          indicatorIconButtonProps={{
            className: styles['carousel-ecosystem-indicator'],
            sx: {
              color: theme.palette.secondary.dark,
              '&:hover': { color: theme.palette.primary.main }
            }
          }}
          activeIndicatorIconButtonProps={{
            sx: { color: 'primary.main', '&:hover': { color: 'primary.main' } }
          }}
          NavButton={({ onClick, next, prev, className }) => {
            return (
              (carouselData?.length > getDataSize() || props?.heading === i18next.t('ABOUT_US_PAGE.OUR_ECOSYSTEM.TITLE')) && (
                <Box mt={13}
                  onClick={onClick}
                  className={styles['carousel-buttons']}
                >
                  {next && <ArrowForwardIosOutlinedIcon sx={{ fontSize: '1.8rem', stroke: theme.palette.common.light, strokeWidth: 1, '&:hover': { backgroundColor: !isMediumDevice ? alpha(theme.palette.common.dark, 0.04) : '' } }} className={`${isMediumDevice
                    ? styles['icon-style-svg-mobile']
                    : styles['icon-style-svg']
                    }`} />
                  }
                  {prev && <ArrowBackIosNewSharpIcon sx={{ fontSize: '1.8rem', stroke: theme.palette.common.light, strokeWidth: 1, '&:hover': { backgroundColor: !isMediumDevice ? alpha(theme.palette.common.dark, 0.04) : '' } }}
                    className={`${isMediumDevice
                      ? styles['icon-style-svg-mobile']
                      : styles['icon-style-svg']
                      } `} />
                  }
                </Box>
              )
            );
          }}
        >
          {(() => {
            if (props?.heading === i18next.t('SHARED.GALLERY')) {
              let totalGallerySize = 0;
              chunkedData.forEach(chunk => { totalGallerySize += chunk.length; });
              return chunkedData?.map((chunk, index) => (
                <Grid container key={index} className={totalGallerySize > getDataSize() ? styles['image-grid-container'] : styles['image-grid-no-carousal']} >
                  {chunk?.map((item, innerIndex) => (
                    <Box className={styles['image-grid']} key={innerIndex} data-testid='multi-media-item'>
                      {item?.type === 'image' ? (<Box component='img'
                        onClick={() => handleOpen(index, innerIndex)}
                        className={styles['image-item']}
                        src={item?.content}
                      />) : displayVideoComponent(item, index, innerIndex)}
                    </Box>
                  ))
                  }
                </Grid>
              ));
            }
            else if (props?.heading === i18next.t('PRODUCT.FEATURES') || props?.heading === i18next.t('PRODUCT.PRODUCT_HIGHLIGHTS')) {
              let totalGallerySize = 0;
              chunkedData.forEach(chunk => { totalGallerySize += chunk.length; });
              return chunkedData?.map((chunk, outerIndex) => (
                <Grid container className={totalGallerySize > getDataSize() ? styles['image-grid-container'] : styles['image-grid-no-carousal']}>
                  {
                    chunk?.map((item, innerIndex) => (
                      item?.header && <Grid item className={styles['image-grid']} key={item?.header}>
                        <Card className={styles['feature-image-item']} classes={{ root: styles['no-shadow'] }}>
                          <CardContent classes={{ root: styles['center-align-items'] }}>
                            <Box>
                              <CardMedia>
                                <Box component='img'
                                  id={`highlight-image-${innerIndex}`}
                                  className={styles['highlight-logo']}
                                  src={item?.multimediaLink ? item.multimediaLink : images.solutions.defaultHighlightImage}
                                  alt={i18next.t('SOLUTION_DETAIL.HIGHLIGHT_IMAGE_ALT_TEXT')}
                                  onError={() => loadDefaultImage(innerIndex)} />
                              </CardMedia>
                            </Box>
                            <Grid sx={{ color: 'primary.main' }} item xs={12} className={`${styles['padding-space']} ${styles['header-color']}`}>
                              {
                                item?.header?.length <= 25 ? item?.header :
                                  <Tooltip
                                    title={item?.header}
                                    open={outerIndex === outerIndexTooltip && innerIndex === innerIndexTooltip && showHeaderTooltip}
                                    onOpen={() => setShowHeaderTooltip(true)}
                                    onClose={() => setShowHeaderTooltip(false)}>
                                    <Box component='span' onMouseOver={() => {
                                      setIndex(innerIndex, outerIndex);
                                      setShowHeaderTooltip(true);
                                    }}
                                      //onclick for responsive mode
                                      onClick={() => {
                                        setIndex(innerIndex, outerIndex);
                                        setShowHeaderTooltip(true);
                                      }}>{item?.header?.substring(0, Math.min(item?.header?.length, 25)) + '...'}</Box>
                                  </Tooltip>
                              }
                            </Grid>
                            <Grid item xs={12} className={styles['padding-space']}>
                              {
                                item?.description?.length <= 100 ? item?.description :
                                  <Tooltip
                                    title={item?.description}
                                    open={outerIndex === outerIndexTooltip && innerIndex === innerIndexTooltip && showDescTooltip}
                                    onOpen={() => setShowDescTooltip(true)}
                                    onClose={() => setShowDescTooltip(false)}>
                                    <Box component='span' onMouseOver={() => {
                                      setIndex(innerIndex, outerIndex);
                                      setShowDescTooltip(true);
                                    }}
                                      //onclick for responsive mode
                                      onClick={() => {
                                        setIndex(innerIndex, outerIndex);
                                        setShowDescTooltip(true);
                                      }}>{item?.description?.substring(0, Math.min(item?.description?.length, 100)) + '...'}</Box>
                                  </Tooltip>
                              }
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                    ))}
                </Grid>
              ));
            }
            else if (props?.heading === i18next.t('ABOUT_US_PAGE.OUR_ECOSYSTEM.TITLE')) {
              return Ecosystem.map((item, index) => {
                return (
                  <Grid container key={index} className={styles['carousel-grid']}>
                    <Grid item>
                      <Typography className={styles['ecosystem-company']} variant={'h6'} sx={{ ':before, :after': { backgroundColor: 'secondary.light' } }}>{i18next.t(item.category)}</Typography>
                    </Grid>
                    <Grid item className={styles['ecosystem-image-grid']}>
                      {item.logos.map((logo, logoIndex) => {
                        return (
                          <Grid item key={logoIndex} className={styles['logo-card']} sx={{ backgroundColor: 'secondaryBackground.bgBody', borderColor: `1px solid ${theme.palette.secondaryBackground.bgBody}` }}>
                            <Box component='img' className={styles['logo-image']} src={logo.img} />
                          </Grid>
                        );
                      })
                      }
                    </Grid>
                  </Grid>
                );
              });
            }

          })()}
        </Carousel>
        <Modal
          open={open}
          onClose={handleClose}
          backdropTransitionOutTiming={0}
        >
          <Box className={styles['modal-product-guide']} bgcolor='common.light'>
            <Box onClick={handleClose} className={styles['modal-close-button']}>
              <CloseIcon title={i18next.t('FORM.CLOSE')} sx={{ fontSize: '2rem', stroke: theme.palette.common.light, strokeWidth: 1 }} />
            </Box>
            {currentMedia?.type === 'image' ? (
              <Box component='img'
                className={styles['modal-image']}
                src={currentMedia?.content}
              />
            ) : (
              play && (
                <video className={styles['modal-video']} controls autoPlay>
                  <source src={currentMedia?.src} type='video/mp4' />
                </video>
              )
            )}
          </Box>
        </Modal>
      </Grid>
    </Grid >
  );
};

export default CarouselContainer;
