import React, { useState } from 'react';
import { withStyles } from '@mui/styles';
import { Typography, Box, Modal, Grid, IconButton, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import WarningIcon from '@mui/icons-material/Warning';
import Loader from '../../Loader';
import i18next from 'i18next';
import { appTheme } from '../../../../../themes/appTheme';

const useStyles = () => ({
    typoStyle: {
        float: 'right'
    },
    divStyle: {
        position: 'absolute',
        backgroundColor: appTheme.palette.common.light,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        padding: '2rem'
    },
    okButtonStyle: {
        whiteSpace: 'nowrap !important',
        marginRight: '1em !important'
    },
    textSize: {
        fontSize: '0.9em',
        whiteSpace: 'nowrap !important',
        width: '100% !important'
    },
    iconStyle: {
        marginRight: '1rem',
        fontSize: '2rem',
        marginBottom: '-4px'
    },
    noAutoCapitalization: {
        '&.makeStyles-label-125': {
            whiteSpace: 'nowrap !important'
        }
    },
    alignRight: {
        justifyContent: 'flex-end'
    }
});

const ConfirmModal = (props) => {
    const [open, setOpen] = useState(true);
    const { classes, dialogPosition } = props;
    let dropdownWrapper, currentFormat, dropdownComponent;
    dropdownWrapper = props?.hasOwnProperty('dropdownWrapper') ? props?.dropdownWrapper : {};
    currentFormat = props?.hasOwnProperty('currentFormat') ? props?.currentFormat : '';
    dropdownComponent = props?.hasOwnProperty('dropdownComponent') ? props?.dropdownComponent : false;

    const handleClose = () => {
        if (props?.heading.includes(i18next.t('SNACKBAR.CANNOT_PLACE_ORDER'))) {
            if (!props?.location?.pathname.includes('/overview/') && !props?.location?.pathname.includes('/cart'))
                props?.history.goBack();
        }
        setOpen(false);
        if (props?.plan || props?.modelRef === 'payPerCallUnSubscribe') {
            props.handleCancelConfirmation();
        } else {
            props.callback();
        }
    };

    const handleSuccess = () => {
        setOpen(false);
        if (props?.heading.includes(i18next.t('SNACKBAR.CANNOT_PLACE_ORDER'))) {
            if (!props?.location?.pathname.includes('/overview/') && !props?.location?.pathname.includes('/cart'))
                props.history.goBack();
        }
        if (props?.modelRef === 'payPerCallUnSubscribe') {
            props?.handleUnSubscribe();
        } else if (props?.plan) {
            props?.handleSubscribe(props?.plan, true);
        } else {
            if (props?.cancelBtn !== 'disable') {
                props?.okOperation(props?.index, props?.type);
            }
            props?.callback();
        }
    };

    return (
        <React.Fragment>
            <Modal
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                        handleClose();
                    }
                }}
                aria-labelledby='simple-modal-title'
                aria-describedby='simple-modal-description'
                open={open}
                disableEscapeKeyDown={props?.disableEscapeKeyDown}>
                <Box className={`${classes.divStyle} ${props?.responsive && 'modal-responsive'}`}>
                    <Grid container>
                        <Grid item xs={11}>
                            <Box mb={2}>
                                {props?.category === 'warning' && <WarningIcon sx={{ color: 'warning.main' }} className={classes.iconStyle} />}
                                <Typography component='span' variant='mainTitleLight'>{props?.heading}</Typography>
                            </Box>
                        </Grid>
                        {
                            !props?.noCloseIcon &&
                            <Grid item xs={1}>
                                <IconButton onClick={handleClose} size='large'><CloseIcon size='small' /></IconButton>
                            </Grid>
                        }
                        <Grid item xs={12}>
                            <Box className={dropdownWrapper}>{dropdownComponent} </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='modalSubTitleLight'>{props?.modalContent} </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Box mt={1}>
                                <Typography variant='modalSubTitleLight'>{props?.modalContent2} </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box>
                                <Typography variant='modalSubTitleLight'>{props?.modalContent3} </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.alignRight}>
                        <Grid item sm>
                            <Box
                                mt={1} display='flex'
                                justifyContent={dialogPosition ? dialogPosition : 'flex-end'}
                                className={classes.alignRight}
                            >
                                {
                                    props?.deletionOfAccounts ?
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <Button fullWidth variant='filledBtn' className={`${classes.okButtonStyle} ${classes.textSize} ${props?.noAutoCapitalization && classes.noAutoCapitalization}`} onClick={props?.handleDeleteMSaccount}>{props?.deleteMSaccountButton}</Button>
                                            </Grid>
                                            {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <Button fullWidth variant='borderedBtn' className={`${classes.textSize} ${props.noAutoCapitalization && classes.noAutoCapitalization}`} onClick={props.handleDeleteCIAMaccount}>{props.deleteCIAMaccount}</Button>
                                            </Grid> */}
                                        </Grid>
                                        :
                                        <>
                                            <Button variant='filledBtn' sx={{ minWidth: '114px' }} className={`${classes.okButtonStyle} ${props.noAutoCapitalization && classes.noAutoCapitalization}`} onClick={() => handleSuccess()} disabled={dropdownComponent && currentFormat.length === 0 ? (true) : (false)}>{props?.submitLabel ? props?.submitLabel : i18next.t('SHARED.OK')}</Button>
                                            {props?.cancelBtn !== 'disable' && <Button variant='borderedBtn' className={props?.noAutoCapitalization && classes.noAutoCapitalization} onClick={() => handleClose()}>{props?.cancelLabel ? props?.cancelLabel : i18next.t('FORM.CANCEL')}</Button>}
                                        </>
                                }
                            </Box>
                        </Grid>
                    </Grid>

                </Box>
            </Modal>
            {props?.confirmationModalLoader && <Loader />}
        </React.Fragment>
    );

};



export default withStyles(useStyles)(ConfirmModal);