import React, { Fragment, useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Container, Link, Box, Typography, Grid, Divider, Tooltip } from '@mui/material';
import { CookieConsent } from '@mobilitystore/cookie-consent-interface';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import * as Constants from '../../../actions/configuration';
import { getUserDetails } from '../../../actions/api/ApiStatisticsAction';
import { portalViewData } from '../../../data/appConstants';
import i18next from 'i18next';
import styles from './footer.module.scss';
import { setCookiePolicy, unSetCookiePolicy } from '../../../utils/index';
import images from './../../../assets/img';

const Footer = (props) => {
  const [buildVersion, setBuildVersion] = useState([]);
  const [openCookieModal, setOpenCookieModal] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [showGoTop, setshowGoTop] = useState(false);
  const dispatch = useDispatch();
  const currentYear = new Date().getFullYear();
  const linkSellerAccount = () => {
    window.open(Constants.sellerAccountRedirect.sellerAccountLink, '_blank');
  };
  const history = useHistory();

  useEffect(() => {
    try {
      const json = require('../../../version.json');
      setBuildVersion(json);
    } catch {
      return false;
    }
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleVisibleButton);
  }, [scrollPosition]);

  useEffect(() => {
    if (props.user?.userId) {
      props.getUserDetails(props.user?.userId);
    }
  }, [props?.user]);

  useEffect(() => {
    if (props?.userDetails?.countryCode) {
      dispatch({ type: 'COUNTRY_CODE_REGION', payload: props?.userDetails?.countryCode });
    }
  }, [props?.userDetails]);

  const handleVisibleButton = () => {
    const scrollHeightPoint =
      props.portalView === portalViewData.MOBILE ? 500 : 300;
    const position = window.scrollY;
    setScrollPosition(position);

    if (position > scrollHeightPoint && !showGoTop) {
      return setshowGoTop(true);
    } else if (position < scrollHeightPoint && showGoTop) {
      return setshowGoTop(false);
    }
  };

  const showCookieModal = () => {
    setOpenCookieModal(true);
  };

  const cookieConsentResponseHandler = (response) => {
    if (response.cookies.marketing) {
      setCookiePolicy(response.expiryTime);
    } else {
      unSetCookiePolicy(response.expiryTime);
    }
  };

  const cookieConsentErrorHandler = (response) => {
    alert(response);
  };

  function showAboutUsPageFooter() {
    if (window.location.pathname === '/about-us') {
      scrollToTop();
    } else {
      history.push('/about-us');
    }
  }

  const handleSupportFooter = () => {
    if (window.location.pathname === '/support') {
      scrollToTop();
    } else {
      history.push('/support');
    }
  };

  const handleDeveloperGuideFooter = () => {
    if (window.location.pathname === '/developer-guide') {
      scrollToTop();
    } else {
      history.push('/developer-guide');
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  const openSellerPortal = () => {
    if (props.accessToken) {   
      linkSellerAccount();
    } else {
      window.open(process.env.REACT_APP_SELLER_BASE_URL, '_blank');
    }
  };

  const getCookie = (cookieName) => {
    const getCookies = decodeURIComponent(document.cookie).split(';');
    for (const [index, value] of getCookies.entries()) {
      if (getCookies[index].split('=')[0].indexOf(cookieName) !== -1) {
        return value.split('=')[1];
      }
    }
  };

  return (
    <Fragment>
      <Box className={styles['footer']} sx={{ backgroundColor: 'primaryBackground.bgBody' }}>
        <Container>
          <Box
            display={{
              xs: 'none',
              sm: 'none',
              md: 'block',
              lg: 'block',
              xl: 'block'
            }}
            mx={3}
          >
            <Box mt={4} mb={3} >
              <Grid container spacing={5}>
                <Grid className={`${styles['other-links']}`} item>
                  <Link
                    underline='none'
                    className={`${styles['footer']} ${styles['cursor-pointer-link']}`}
                    onClick={openSellerPortal}
                  >
                    <Typography sx={{ color: 'common.dark' }} variant='mediumText'>
                      {i18next.t('HOME_PAGE.HEADER_FOOTER.BECOME_SELLER')}
                    </Typography>
                  </Link>
                </Grid>
                <Grid className={styles['other-links']} item>
                  <Link
                    component={RouterLink}
                    className={styles['footer']}
                    to={{
                      pathname: '/about-us'
                    }}
                    underline='none'
                    onClick={showAboutUsPageFooter}
                  >
                    <Typography sx={{ color: 'common.dark' }} variant='mediumText'>
                      {i18next.t('SHARED.ABOUT_US')}
                    </Typography>
                  </Link>
                </Grid>
                <Grid className={styles['other-links']} item>
                  <Link
                    component={RouterLink}
                    className={styles['footer']}
                    to={{
                      pathname: '/support'
                    }}
                    underline='none'
                    onClick={handleSupportFooter}
                  >
                    <Typography sx={{ color: 'common.dark' }} variant='mediumText'>
                      {i18next.t('HOME_PAGE.HEADER_FOOTER.SUPPORT')}
                    </Typography>
                  </Link>
                </Grid>
                <Grid className={styles['other-links']} item>
                  <Link
                    component={RouterLink}
                    className={styles['footer']}
                    to={{
                      pathname: '/get-in-touch'
                    }}
                    underline='none'
                  >
                    <Typography sx={{ color: 'common.dark' }} variant='mediumText'>
                      {i18next.t('SHARED.CONTACT_US')}
                    </Typography>
                  </Link>
                </Grid>
                {process.env.REACT_APP_ENVIRONMENT ===
                  Constants.REACT_APP_ENVIRONMENT.DEV && (
                    <Grid className={styles['other-links']} item>
                      <Link
                        component={RouterLink}
                        className={styles['footer']}
                        to={{
                          pathname: '/developer-guide'
                        }}
                        underline='none'
                        onClick={handleDeveloperGuideFooter}
                      >
                        <Typography sx={{ color: 'common.dark' }} variant='mediumText'>
                          {i18next.t('HOME_PAGE.HEADER_FOOTER.API_GUIDE')}
                        </Typography>
                      </Link>
                    </Grid>
                  )}
              </Grid>
            </Box>
            <Divider />
            <Box mt={3}>
              <Grid container xs sm md lg xl>
                <Grid item xs sm md lg xl justifyContent={'space-between'}>
                  <Box display='inline-block' pr={2} width='50%'>
                    <Typography variant='smallText'>
                      {i18next.t('HOME_PAGE.HEADER_FOOTER.COPYRIGHT_BOSCH')}
                      {currentYear},
                      {i18next.t('HOME_PAGE.HEADER_FOOTER.ALL_RIGHTS_RESERVED')}
                    </Typography>
                  </Box>
                  <Box display='inline-block' pr={2} className={styles['content-alignment']}>
                    <Link
                      component={RouterLink}
                      className={styles['footer-link']}
                      to={{
                        pathname: '/corporate-information'
                      }}
                      underline='none'
                      target='_blank'
                    >
                      <Typography variant='p' sx={{ color: 'common.dark' }}>
                        {i18next.t('SHARED.CORPORATE_INFORMATION')}
                      </Typography>
                    </Link>
                  </Box>
                  <Box display='inline-block' pr={2} className={styles['content-alignment']}>
                    <Link
                      component={RouterLink}
                      className={styles['footer-link']}
                      to={{
                        pathname: '/legal-notice'
                      }}
                      underline='none'
                      target='_blank'
                    >
                      <Typography variant='p' sx={{ color: 'common.dark' }}>
                        {i18next.t('HOME_PAGE.HEADER_FOOTER.LEGAL_NOTICE')}
                      </Typography>
                    </Link>
                  </Box>
                  <Box display='inline-block' pr={2} className={styles['content-alignment']}>
                    <Link
                      component={RouterLink}
                      className={styles['footer-link']}
                      to={{
                        pathname: '/data-protection-policy'
                      }}
                      underline='none'
                      target='_blank'
                    >
                      <Typography variant='p' sx={{ color: 'common.dark' }}>
                        {i18next.t('SHARED.DATA_PROTECTION_NOTICE')}
                      </Typography>
                    </Link>
                  </Box>
                  <Box display='inline-block' pr={2} className={styles['content-alignment']}>
                    <Link
                      component={RouterLink}
                      className={styles['footer-link']}
                      to={{
                        pathname: '/terms-of-use'
                      }}
                      underline='none'
                      target='_blank'
                    >
                      <Typography variant='p' sx={{ color: 'common.dark' }}>
                        {i18next.t('HOME_PAGE.HEADER_FOOTER.TERMS_OF_USE')}
                      </Typography>
                    </Link>
                  </Box>
                  <Box display='inline-block' pr={2} className={styles['content-alignment']}>
                    <Link
                      component={RouterLink}
                      className={styles['footer-link']}
                      onClick={() => showCookieModal()}
                      underline='none'
                    >
                      <Typography variant='p' sx={{ color: 'common.dark' }}>
                        {i18next.t('HOME_PAGE.HEADER_FOOTER.PRIVACY_SETTINGS')}
                      </Typography>
                    </Link>
                  </Box>
                </Grid>
                
              </Grid>
              <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                  {showGoTop && (
                    <Box display='flex' justifyContent={'flex-end'}>
                      <Tooltip
                        title={i18next.t('HOME_PAGE.HEADER_FOOTER.SCROLL_TOP')}
                        placement='top-end'
                      >
                          <Box component='img'
                          className= {styles['arrow-top']}
                          src= {images.dropDownArrowIcon}
                          onClick={scrollToTop} />
                        </Tooltip>
                    </Box>
                  )}
                </Grid>
            </Box>
          </Box>
          <Box
            display={{
              xs: 'block',
              sm: 'block',
              md: 'none',
              lg: 'none',
              xl: 'none'
            }}
          >
            <Box className={styles['footer']} my={2} onClick={openSellerPortal}>
              {i18next.t('HOME_PAGE.HEADER_FOOTER.BECOME_SELLER')}
            </Box>
            <Box my={2}>
              <Link
                component={RouterLink}
                className={styles['footer']}
                to={{
                  pathname: '/about-us'
                }}
                underline='none'
              >
                <Typography sx={{ color: 'common.dark' }} variant='p'>
                  {i18next.t('SHARED.ABOUT_US')}
                </Typography>
              </Link>
            </Box>
            <Box my={2}>
              <Link
                component={RouterLink}
                className={styles['footer']}
                to={{
                  pathname: '/support'
                }}
                underline='none'
              >
                <Typography sx={{ color: 'common.dark' }} variant='p'>
                  {i18next.t('HOME_PAGE.HEADER_FOOTER.SUPPORT')}
                </Typography>
              </Link>
            </Box>
            <Box my={2}>
              <Link
                component={RouterLink}
                className={styles['footer']}
                to={{
                  pathname: '/get-in-touch'
                }}
                underline='none'
              >
                <Typography sx={{ color: 'common.dark' }} variant='p'>
                  {i18next.t('SHARED.CONTACT_US')}
                </Typography>
              </Link>
            </Box>
            {process.env.REACT_APP_ENVIRONMENT ===
              Constants.REACT_APP_ENVIRONMENT.DEV && (
                <Box my={2}>
                  <Link
                    component={RouterLink}
                    className={styles['footer']}
                    to={{
                      pathname: '/developer-guide'
                    }}
                    underline='none'
                  >
                    <Typography sx={{ color: 'common.dark' }} variant='p'>
                      {i18next.t('HOME_PAGE.HEADER_FOOTER.API_GUIDE')}
                    </Typography>
                  </Link>
                </Box>
              )}
            <Divider />
            <Box my={2} mt={3}>
              <Link
                component={RouterLink}
                to={{
                  pathname: '/corporate-information'
                }}
                underline='none'
                className={styles['footer']}
              >
                <Typography sx={{ color: 'common.dark' }} variant='p'>
                  {i18next.t('SHARED.CORPORATE_INFORMATION')}
                </Typography>
              </Link>
            </Box>
            <Box my={2}>
              <Link
                component={RouterLink}
                to={{
                  pathname: '/legal-notice'
                }}
                underline='none'
                className={styles['footer']}
              >
                <Typography sx={{ color: 'common.dark' }} variant='p'>
                  {i18next.t('HOME_PAGE.HEADER_FOOTER.LEGAL_NOTICE')}
                </Typography>
              </Link>
            </Box>
            <Box my={2}>
              <Link
                component={RouterLink}
                to={{
                  pathname: '/data-protection-policy'
                }}
                underline='none'
                className={styles['footer']}
              >
                <Typography sx={{ color: 'common.dark' }} variant='p'>
                  {i18next.t('SHARED.DATA_PROTECTION_NOTICE')}
                </Typography>
              </Link>
            </Box>
            <Box my={2}>
              <Link
                component={RouterLink}
                to={{
                  pathname: '/terms-of-use'
                }}
                underline='none'
                className={styles['footer']}
              >
                <Typography sx={{ color: 'common.dark' }} variant='p'>
                  {i18next.t('HOME_PAGE.HEADER_FOOTER.TERMS_OF_USE')}
                </Typography>
              </Link>
            </Box>
            <Box my={2}>
              <Link
                component={RouterLink}
                className={styles['footer']}
                onClick={() => showCookieModal()}
                underline='none'
              >
                <Typography sx={{ color: 'common.dark' }} variant='p'>
                  {i18next.t('HOME_PAGE.HEADER_FOOTER.PRIVACY_SETTINGS')}
                </Typography>
              </Link>
            </Box>
            <Grid container className={styles['footer-margin']}>
              <Grid item xs={11} sm={10} md={10} lg={10} xl={10}>
                <Typography variant='p'>
                  {i18next.t('HOME_PAGE.HEADER_FOOTER.COPYRIGHT_BOSCH')}
                  {currentYear},
                  {i18next.t('HOME_PAGE.HEADER_FOOTER.ALL_RIGHTS_RESERVED')}
                </Typography>
              </Grid>
              <Grid item xs={1} sm={2} md={2} lg={2} xl={2}>
                {showGoTop && (
                  <Box display='flex' justifyContent='flex-end'>
                    <Box component='img'className= {styles['arrow-top']} src= {images.dropDownArrowIcon} onClick={scrollToTop} />
                  </Box>
                )}
              </Grid>
            </Grid>
          </Box>
          {Object.keys(buildVersion).map(
            (key, index) =>
              key.indexOf('app') !== -1 && (
                <input
                  id={index}
                  key={`${key}`}
                  type='hidden'
                  value={`${key}:${buildVersion[key]}`}
                ></input>
              )
          )}
        </Container>
      </Box>
      {openCookieModal &&
        <CookieConsent
          open={false}
          dataProtectionPolicy='/data-protection-policy'
          corporateInformation='/corporate-information'
          config={{
            dialogOptions: {
              showPrivacySettingsDialog: true,
              cookies: {
                marketing: getCookie('cookieConsentByUser') === '1' ? true : false
              }
            }
          }}
          exitHandler={() => setOpenCookieModal(false)}
          responseHandler={cookieConsentResponseHandler}
          errorHandler={cookieConsentErrorHandler}
        />}
    </Fragment>
  );
};

const mapStateToProps = (redux_state) => ({
  accessToken: redux_state.loginReducer.accessToken,
  portalView: redux_state.LandingReducer.portalView,
  user: redux_state.loginReducer.user,
  userDetails: redux_state.loginReducer.userDetails
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getUserDetails
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
