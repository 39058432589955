import { Container, Stack } from '@mui/material';
import React from 'react';
import AnimatedSpriteImage from '../AnimatedSpriteImage/animatedSpriteImage';
import AnimatedText from '../AnimatedText/animatedText';
import styles from './sprite-image-animated-text-slide.module.scss';
import DeviceBreakPoints from '../../../../deviceBreakPoints';
import { IMAGE_CDN } from '../../../../actions/configuration';

const SpriteImageAnimatedTextSlide = (props) => {
  const { isSmallDevice, isXlDevice } = DeviceBreakPoints();
  const getClassName = () => {
    if (isSmallDevice) return 'center';
    else {
      if (isXlDevice) return 'space-around';
      else return 'space-between';
    }
  };
  return (
    <Container className={styles['banner-container']} sx={{ backgroundImage: `url(${IMAGE_CDN}revamped-homepage-banner-background.png)`, backgroundColor: 'primaryBackground.banner' }}>
      <Stack
        alignItems='center'
        justifyContent={
          getClassName()
        }
        direction={!isSmallDevice ? 'row' : 'column'}
        className={
          !isXlDevice
            ? styles['padding-to-items-xl']
            : styles['padding-to-items']
        }
      >
        <AnimatedText {...props} />
        <AnimatedSpriteImage />
      </Stack>
    </Container>
  );
};

export default SpriteImageAnimatedTextSlide;
