import React, { useEffect, useState, useRef } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import Loader from '../../Shared/Utils/Loader';
import { Link as RouterLink } from 'react-router-dom';
import {
  logout,
  handleAAASignupLogin,
  clearLoginStorage
} from '../../../actions/LoginAction';
import styles from './header.module.scss';
import * as Constants from '../../../actions/configuration';
import {
  getPromisedAllMasterData,
  getSubcategories
} from '../../../actions/MasterDataAction';
import { retrieveUserCompanyStatus } from '../../../actions/CompanyAction';
import {
  getProductCategories,
  getAllProductsCount,
  // Commented the SWS code for Future Release
  // getSearchSuggestions
} from '../../../actions/LandingAction';
import { getCartProduct } from '../../../actions/CartAction';
import {
  AppBar,
  Toolbar,
  Grid,
  TextField,
  ButtonGroup,
  Link,
  Box,
  Typography,
  IconButton,
  Container,
  Popover,
  Paper,
  Divider,
  List,
  ListItem,
  ListItemText,
  Accordion,
  AccordionSummary,
  Slide,
  Button,
  Avatar,
  ListItemIcon,
  Badge,
  Tooltip,
  Alert,
  Snackbar,
  Menu,
  // Autocomplete {/* Commented this code for Site-Wide-Search Future Release */}
  InputAdornment,
  Collapse,
} from '@mui/material';
// import parse from 'autosuggest-highlight/parse';
// import match from 'autosuggest-highlight/match';
import { withRouter } from 'react-router';
import { headersData } from '../../../data/appConstants';
import { megaMenuRightContent } from '../../../data/HomePage/categoriesList';
import idConstants from '../../../data/idConstants';
import Breadcrumb from '../../Shared/Breadcrumb';
import reactGA from 'react-ga';
import i18next from 'i18next';
import CustomAccordion from '../../Shared/CustomAccordion';
import DeviceBreakPoints from '../../../deviceBreakPoints';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SearchSharpIcon from '@mui/icons-material/SearchSharp';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import images from '../../../assets/img';
import { Close } from '@mui/icons-material';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { RegisterCompany } from '@mobilitystore/user-onboarding-interface';
import { getEnvironment, replaceSpaceWithHyphenInLowerCaps } from '../../../utils';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import DialogModal from '../../Shared/Utils/Modal/DialogModal';

function Header(props) {
  const [loader] = React.useState(false);
  const [openRegisterModal, setOpenRegisterModal] = useState(false);
  const reduxStore = useSelector((state) => state);
  const userCompanyStatus = reduxStore.CompanyDetailsReducer.userCompanyStatus;
  const profileStatus = reduxStore.CompanyDetailsReducer.profileStatus;
  const dispatchRedux = useDispatch();
  const [confirmationModalLoader, setConfirmationModalLoader] = useState(false);
  {/* Commented this code for Site-Wide-Search Future Release */ }
  // const [searchText, setSearchText] = useState(
  //   props.location?.state?.text ?? ''
  // );
  const [searchText, setSearchText] = useState(props.text ?? '');
  const [headerItem, setHeaderItem] = useState(headersData);
  const [currentSelection, setCurrentSelection] = useState('');
  const { isMediumDevice, isSmallDevice, isDesktopDevice, theme } =
    DeviceBreakPoints();
  const [showSearch, setSearch] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [showMenuDropdown, setMenuDropdown] = useState(false);
  const [openAccordion, setOpenAccordion] = React.useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const ref = useRef(null);
  const mobileRef = useRef(null);
  const [snackAlertFlag, setSnackAlertFlag] = useState(false);
  const [snackAlertMsg, setSnackAlertMsg] = useState('');
  const [snackAlertSeverity, setSnackAlertSeverity] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const [isSearch, setIsSearch] = useState(false);
  const [autoCompleteOptions, setAutoCompleteOptions] = useState([]);

  const checkIfHasAccess = (message) => {
    if (
      !(
        props?.accessToken &&
        profileStatus?.companyId &&
        userCompanyStatus?.companyRegisteredStatus === 'approved' &&
        userCompanyStatus?.areCompanyDetailsCaptured
      )
    ) {
      setOpen(false);
      setDialogMessage(message);
      setOpenDialog(true);
      return true;
    }
    return false;
  };

  const handleHideDropdown = (event) => {
    if (event.key === 'Escape') {
      closeAccordion();
    }
  };

  // To handle close event on clicking or MouseOver outside the overlay
  const handleClickOrMouseOverOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      closeAccordion();
    }
  };

  // To handle close event on clicking outside the overlay in mobile view
  const handleMobileClickOutside = (event) => {
    if (mobileRef.current && !mobileRef.current.contains(event.target)) {
      setMenuDropdown(false);
    }
  };

  const closeAccordion = () => {
    setOpenAccordion(false);
    setCurrentSelection('');
  };

  const goBack = () => {
    closeAccordion();
  };

  const apiProdCategory = props.productCategories?.find(
    (category) => category.categoryId === idConstants.api.categoryId
  );

  const dataProductCategory = props.productCategories?.find(
    (category) => category.categoryId === idConstants.data.categoryId
  );

  const solutionProductCategory = props.productCategories?.find(
    (category) => category.categoryId === idConstants.solutions.categoryId
  );

  const hardwareProductCategory = props.productCategories?.find(
    (category) => category.categoryId === idConstants.hardware.categoryId
  );

  const automotiveProductCategory = props.productCategories?.find(
    (category) => category.categoryId === idConstants.automotiveSpares.categoryId
  );

  useEffect(() => {
    setConfirmationModalLoader(false);
    props.getProductCategories().then(() => {
      props.getAllProductsCount('');
    });
    if (props.accessToken) {
      props.retrieveUserCompanyStatus();
    }
    // Commented the SWS code for Future Release
    // const queryParams = `?key=${searchText}`;
    // props.getSearchSuggestions(queryParams);

    const storageListener = (event) => {
      if (event.storageArea !== localStorage) return;
      if (event.key === 'addToCart') {
        if (window.location.pathname !== '/cart') {
          props.getCartProduct();
        }
        window.localStorage.removeItem('addToCart');
      }
    };

    window.addEventListener('storage', storageListener);

    return () => {
      window.removeEventListener('storage', storageListener);
    };
  }, [props.accessToken]);

  useEffect(() => {
    if (
      props.accessToken &&
      userCompanyStatus?.companyRegisteredStatus === 'approved' &&
      userCompanyStatus?.areCompanyDetailsCaptured === true
    ) {
      props.getCartProduct();
    }
  }, [userCompanyStatus]);

  useEffect(() => {
    if (
      props.productCategories?.length &&
      props?.publishedProductsCounts?.counts &&
      Object.keys(props?.publishedProductsCounts?.counts).length > 0 &&
      !props?.isDataFetched
    ) {
      dispatchRedux({
        type: 'IS_DATA_FETCHED',
        payload: true
      });
      props?.getSubcategories();
      props?.getPromisedAllMasterData(idConstants?.api?.categoryId);
    }
  }, [props?.productCategories, props?.publishedProductsCounts]);

  useEffect(() => {
    setSearchText(props?.location?.state?.text ?? '');
  }, [props?.location?.state?.text]);

  useEffect(() => {
    if (open) {
      window.addEventListener('scroll', handleClose);
    } else {
      window.removeEventListener('scroll', handleClose);
    }
  }, [open]);

  const handleClose = () => {
    setOpen(false);
  };
  const linkSellerAccount = async () => {
    await dispatchRedux(clearLoginStorage());
    localStorage.setItem('resetLoader', true);
    window.location.href = Constants.sellerAccountRedirect.sellerAccountLink;
  };

  const signOut = () => {
    props.logout();
  };

  const openPopUp = () => {
    handleClose();
    if (window.location.pathname === '/') {
      setOpenRegisterModal(true);
    } else {
      props.history.push({
        pathname: '/',
        state: 'openPopup' // just for setting state while routing from other than home page
      });
    }
  };

  useEffect(() => {
    if (props.history.location.state === 'openPopup') {
      setOpenRegisterModal(true);
      props.history.replace();
    }
  }, [profileStatus]);

  const handleSignInRegister = () => {
    setConfirmationModalLoader(true);
    localStorage.setItem('resetLoader', true);
    handleAAASignupLogin();
    reactGA.event({
      category: 'Login',
      action: 'User Clicked on Login',
      label: 'Login Button'
    });
  };

  const handleSellOnMarketPlace = () => {
    setConfirmationModalLoader(true);
    linkSellerAccount();
    reactGA.event({
      category: 'Seller Corner',
      action: 'User Clicked on Seller Corner',
      label: 'Seller Corner'
    });
  };

  const RegisterComponent = localStorage.getItem(
    idConstants.tokens.aaaIdToken
  ) &&
    (openRegisterModal || !profileStatus?.statusCheck) &&
    !localStorage.getItem('newLogin') && (
      <RegisterCompany
        language='en'
        clientToken={localStorage.getItem(idConstants.tokens.aaaIdToken)}
        environment={getEnvironment(process.env.REACT_APP_ENVIRONMENT)}
        successHandler={(response) => {
          dispatchRedux({
            type: 'UPDATE_PROFILE_STATUS',
            payload: response
          });
          localStorage.removeItem('newLogin');
        }}
        cancelHandler={() => {
          setOpenRegisterModal(false);
        }}
        errorHandler={(error) => {
          if (error.message) {
            setSnackAlertFlag(true);
            setSnackAlertMsg(error.message);
            setSnackAlertSeverity('error');
          }
        }}
        role={Constants.AAA_CONFIG.BUYER_ROLE}
      />
    );

  const signInOutBlock = props.accessToken && (
    <React.Fragment>
      {RegisterComponent}
      <Popover
        PaperProps={{
          square: true
        }}
        marginThreshold={24}
        open={open}
        anchorEl={anchorEl}
        container={anchorEl?.parentNode}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Paper square>
          <List
            sx={{ backgroundColor: 'primaryBackground.bgBody' }}
            className={styles['header-signout-list-container']}
          >
            <ListItem
              alignItems='flex-start'
              className={styles['header-sign-out-dropdown-option-account-info']}
            >
              <ListItemText
                className={styles['header-sign-out-username']}
                sx={{ color: 'common.dark' }}
                title={
                  userCompanyStatus?.user?.firstName &&
                    userCompanyStatus?.user?.lastName
                    ? userCompanyStatus?.user?.firstName +
                    ' ' +
                    userCompanyStatus?.user?.lastName
                    : ''
                }
                primary={
                  userCompanyStatus?.user?.firstName &&
                    userCompanyStatus?.user?.lastName
                    ? userCompanyStatus?.user?.firstName +
                    ' ' +
                    userCompanyStatus?.user?.lastName
                    : ''
                }
                secondary={
                  <React.Fragment>
                    <Box
                      className={styles['header-sign-out-email']}
                      title={userCompanyStatus?.user?.emailId}
                      sx={{ color: 'common.dark' }}
                    >
                      {userCompanyStatus?.user?.emailId}
                    </Box>
                    <Box mt={2}>
                      <Button
                        fullWidth
                        variant='filledBtn'
                        sx={{ fontSize: '14px', padding: '3px 16px' }}
                        onClick={() => {
                          signOut();
                        }}
                        size='small'
                        endIcon={
                          <Box
                            component={'img'}
                            src={images.logoutIcon}
                            className={styles['icon-style-small']}
                          />
                        }
                      >
                        {i18next.t('HOME_PAGE.HEADER_FOOTER.SIGN_OUT')}
                      </Button>
                    </Box>
                  </React.Fragment>
                }
              ></ListItemText>
            </ListItem>
            <Divider light />
            {profileStatus?.status !== 'approved' && (
              <React.Fragment>
                <ListItem
                  alignItems='flex-start'
                  sx={{ backgroundColor: 'profileStatus.main' }}
                  className={styles['header-company-status-styling']}
                >
                  {props.accessToken && !profileStatus?.companyId ? (
                    <React.Fragment>
                      <ListItemText
                        className={styles['header-company-pending']}
                        sx={{ color: 'common.dark' }}
                        primary={i18next.t(
                          'HOME_PAGE.HEADER_FOOTER.PENDING_REGISTRATION'
                        )}
                        secondary={
                          <React.Fragment>
                            <Box
                              component='span'
                              sx={{ color: 'common.dark' }}
                              className={
                                styles['header-company-pending-description']
                              }
                            >
                              {i18next.t(
                                'HOME_PAGE.HEADER_FOOTER.UPDATE_COMPANY_DETAILS'
                              )}
                            </Box>
                            <Box mt={2}>
                              <Button
                                fullWidth
                                variant='filledBtn'
                                sx={{ fontSize: '14px' }}
                                onClick={() => openPopUp()}
                              >
                                {i18next.t(
                                  'HOME_PAGE.HEADER_FOOTER.REGISTER_COMPANY'
                                )}
                              </Button>
                            </Box>
                          </React.Fragment>
                        }
                      ></ListItemText>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <ListItemText
                        classes={{
                          primary: styles['header-company-pending-title']
                        }}
                        primary={
                          profileStatus?.status
                            ? profileStatus?.status?.replace('_', ' ')
                            : i18next.t(
                              'HOME_PAGE.HEADER_FOOTER.PENDING_REGISTRATION'
                            )
                        }
                        secondary={
                          <React.Fragment>
                            <Box
                              sx={{ color: 'common.dark' }}
                              className={
                                styles['header-company-pending-description']
                              }
                              title={profileStatus?.companyRegStatusDescription}
                            >
                              {profileStatus?.companyRegStatusDescription}
                            </Box>
                          </React.Fragment>
                        }
                        className={styles['header-company-pending']}
                      ></ListItemText>
                    </React.Fragment>
                  )}
                </ListItem>
                <Divider light />
              </React.Fragment>
            )}
            <ListItem
              className={styles['cursor-pointer']}
              onClick={() => props.history.push('/profile')}
            >
              <ListItemText
                disableTypography
                className={styles['header-sign-out-dropdown-option']}
                sx={{ color: 'common.dark' }}
              >
                {i18next.t('HOME_PAGE.HEADER_FOOTER.VIEW_PROFILE')}
              </ListItemText>
            </ListItem>
            <Divider light />
            <ListItem
              className={styles['cursor-pointer']}
              onClick={() => props.history.push('/myaccount')}
            >
              <ListItemText
                disableTypography
                className={styles['header-sign-out-dropdown-option']}
                sx={{ color: 'common.dark' }}
              >
                {i18next.t('SHARED.MY_ACCOUNT_TITLE')}
              </ListItemText>
            </ListItem>
            <Divider light />
            <ListItem
              className={styles['cursor-pointer']}
              onClick={() =>
                !checkIfHasAccess(
                  i18next.t('MY_ACCOUNT.UNAUTHORIZED_MY_PROPOSALS')
                ) && props.history.push('/myaccount/myproposals')
              }
            >
              <ListItemText
                disableTypography
                className={styles['header-sign-out-dropdown-option']}
                sx={{ color: 'common.dark' }}
              >
                {i18next.t('HOME_PAGE.HEADER_FOOTER.MY_PROPOSALS')}
              </ListItemText>
            </ListItem>
            <Divider light />
            <ListItem
              className={styles['cursor-pointer']}
              onClick={() =>
                !checkIfHasAccess(
                  i18next.t('MY_ACCOUNT.UNAUTHORIZED_MY_QUOTES')
                ) && props.history.push('/myaccount/myquotes')
              }
            >
              <ListItemText
                disableTypography
                className={styles['header-sign-out-dropdown-option']}
                sx={{ color: 'common.dark' }}
              >
                {i18next.t('MY_QUOTES.MY_QUOTES')}
              </ListItemText>
            </ListItem>
            <Divider light />
            <ListItem
              className={styles['cursor-pointer']}
              onClick={() =>
                !checkIfHasAccess(
                  i18next.t('MY_ACCOUNT.UNAUTHORIZED_MY_APPLICATIONS')
                ) && props.history.push('/myaccount/myapplications')
              }
            >
              <ListItemText
                disableTypography
                className={styles['header-sign-out-dropdown-option']}
                sx={{ color: 'common.dark' }}
              >
                {i18next.t('SHARED.MY_APPLICATIONS')}
              </ListItemText>
            </ListItem>
            <Divider light />
            <ListItem
              className={styles['cursor-pointer']}
              onClick={() =>
                !checkIfHasAccess(
                  i18next.t('MY_ACCOUNT.UNAUTHORIZED_ANALYTICS')
                ) && props.history.push('/myaccount/apistatistics')
              }
            >
              <ListItemText
                disableTypography
                className={styles['header-sign-out-dropdown-option']}
                sx={{ color: 'common.dark' }}
              >
                {i18next.t('SHARED.ANALYTICS_TRACKING')}
              </ListItemText>
            </ListItem>
            <Divider light />
            <ListItem
              className={styles['cursor-pointer']}
              sx={{
                '&:hover': {
                  backgroundColor: 'secondary.main'
                }
              }}
              onClick={() =>
                !checkIfHasAccess(
                  i18next.t('MY_ACCOUNT.UNAUTHORIZED_MY_ORDERS')
                ) &&
                props.history.push({
                  pathname: '/myaccount/myorders',
                  state: { activeTab: 'orderHistory' }
                })
              }
            >
              <ListItemText
                disableTypography
                className={styles['header-sign-out-dropdown-option']}
                sx={{ color: 'common.dark' }}
              >
                {i18next.t('HOME_PAGE.HEADER_FOOTER.ORDER_HISTORY')}
              </ListItemText>
            </ListItem>
            <Divider light />
            <ListItem
              className={styles['cursor-pointer']}
              sx={{
                '&:hover': {
                  backgroundColor: 'secondary.main'
                }
              }}
              onClick={() =>
                !checkIfHasAccess(
                  i18next.t('MY_ACCOUNT.UNAUTHORIZED_TRACK_ISSUES')
                ) && props.history.push('/myaccount/track-issues')
              }
            >
              <ListItemText
                disableTypography
                className={styles['header-sign-out-dropdown-option']}
                sx={{ color: 'common.dark' }}
              >
                {i18next.t('TICKET_CARD.TRACK_ISSUES')}
              </ListItemText>
            </ListItem>
            <Divider light />
          </List>
        </Paper>
      </Popover>
      {loader && <Loader />}
    </React.Fragment>
  );

  useEffect(() => {
    setOpen(false);
    setMenuDropdown(false);
    if (isMediumDevice) {
      setSearch(showSearch && searchText);
    } else {
      setSearch(true);
    }
  }, [isMediumDevice]);

  useEffect(() => {
    /** Check if the URL path is /search and read the key param value to process. */
    const searchRegex =
      /^\/search/; /** Regex to check if string starts with /search */
    const params =
      searchRegex.test(props.location.pathname) &&
      new URLSearchParams(props.location.search);
    const keyText = params && params.get('key');
    if (keyText) {
      setSearchText(keyText, () => {
        searchProductsByText();
      });
    }
  }, [props.location.search]);

  function searchProductsByText() {
    if (!searchText) {
      props.history.push(allProdUrl);
      return;
    }
    props.history.push({
      pathname: '/search?key=' + encodeURIComponent(searchText),
      state: {
        text: searchText ?? '',
        categoryId: 'All',
        productCategoryName: i18next.t(
          'HOME_PAGE.HEADER_FOOTER.ALL_CATEGORIES'
        ),
        productCategories: props.productCategories,
        solutionSearch: true
      }
    });
  }

  function toggleSearch(event) {
    if (isMediumDevice && showSearch) {
      setMenuDropdown(false);
      setSearch(false);
    }
    if (isMediumDevice && !showSearch) {
      setMenuDropdown(false);
      setSearch(true);
    }
    if (isMediumDevice && event.key === 'Enter') {
      searchProductsByText();
    }
  }

  {/* Commented this code for Site-Wide-Search Future Release */ }
  // function handleChange(event) {
  //   const searchText = event?.target?.value || '';
  //   if (event?.key === 'Enter' && searchText?.length > 0) {
  //     setIsSearch(true);
  //     searchByText(searchText);
  //   } else {
  //     if (searchText === '') {
  //       props.getSearchSuggestions('');
  //     } else if
  //       (searchText.length > 2) {
  //       const queryParams = `?key=${searchText}`;
  //       props.getSearchSuggestions(queryParams);
  //     }
  //     setSearchText(searchText);
  //     setAutoCompleteOptions(props?.searchSuggestions?.content?.result || []);
  //   }
  // }

  function handleChange(event) {
    setSearchText(event.target.value);
    if (event.key === 'Enter') {
      searchProductsByText();
    }
  }

  function showSignoutMenu(event) {
    setOpen(true);
    setAnchorEl(event.currentTarget);
  }

  function showMenuOverlay(clearSearchText) {
    setSearch(false);
    setMenuDropdown(!showMenuDropdown);
    setOpenAccordion(false);
    if (clearSearchText) {
      setSearchText('');
    }
  }

  function showCart() {
    if (window.location.pathname === '/cart') {
      window.location.reload();
    } else {
      props.history.push('/cart');
    }
  }

  const allProdUrl = {
    pathname: '/search',
    state: {
      text: '',
      categoryId: 'All',
      productCategoryName: i18next.t('HOME_PAGE.HEADER_FOOTER.ALL_CATEGORIES'),
      productCategories: props.productCategories,
      solutionSearch: true
    }
  };
  function navigateToHome() {
    if (window.location.pathname === '/') {
      window.location.reload();
    } else {
      props.history.push('/');
    }
  }
 
  //disabling the category items in the header which dont have any products
  useEffect(() => {
    if (props?.publishedProductsCounts?.counts) {
      let activeMenuItem =
        Constants.SHOW_AUTOMOTIVE_SPARES === 'true'
          ? headersData
          : headersData.filter(
              (item) =>
                item.categoryId !== idConstants.automotiveSpares.categoryId
            );
      activeMenuItem.forEach((item) => {
        if (item.categoryId) {
          if (
            Object.keys(props?.publishedProductsCounts?.counts).includes(
              item.categoryId
            )
          ) {
            item.isDisabled = false;
          } else {
            item.isDisabled = true;
          }
        } else {
          if (item.label === 'HOME_PAGE.HEADER_FOOTER.IOT_SOFTWARE_SERVICES')
            item.isDisabled = true;
          else item.isDisabled = false;
        }
      });
      setHeaderItem(activeMenuItem);
    }
  }, [props?.publishedProductsCounts?.counts]);
  useEffect(() => {
    const handleScroll = () => {
      if (menuAnchorEl) {
        handleMenuClose();
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [menuAnchorEl]);

  function isBubbleParentCategory(parentCatergoryId) {
    // this function returns boolean true
    // if the parentcategory is of parent category to two to more subcategory products
    // to display in bubble like service category
    let count = 0;
    props.productCategories?.map((category) => {
      if (category.parentProductCategoryId === parentCatergoryId) {
        count = count + 1;
      }
      return true;
    });
    return count;
  }

  const apiProdUrl = {
    pathname: `/${idConstants.api.productCategoryName?.toLowerCase()}`,
    state: {
      text: '',
      categoryId: idConstants.api.categoryId,
      productCategoryName: apiProdCategory
        ? apiProdCategory.productCategoryName
        : i18next.t('ANALYTICS_AND_TRACKING.API_STATISTICS.API'),
      productCategories: props.productCategories,
      solutionSearch: true,
      ...(isBubbleParentCategory(idConstants.api.categoryId) > 1 && {
        showSubCategories: true
      })
    }
  };

  const dataProdUrl = {
    pathname: `/${idConstants.data.productCategoryName?.toLowerCase()}`,
    state: {
      text: '',
      categoryId: idConstants.data.categoryId,
      productCategoryName: dataProductCategory
        ? dataProductCategory.productCategoryName
        : i18next.t('DATA_DETAIL.DATA_PRODUCT'),
      productCategories: props.productCategories,
      solutionSearch: true,
      ...(isBubbleParentCategory(idConstants.data.categoryId) > 1 && {
        showSubCategories: true
      })
    }
  };

  const solutionProdUrl = {
    pathname: `/${idConstants.solutions.productCategoryName?.toLowerCase()}`,
    state: {
      prePopulateFrom: {
        url: `/${idConstants.solutions.productCategoryName?.toLowerCase()}`
      },
      text: '',
      categoryId: idConstants.solutions.categoryId,
      productCategoryName: solutionProductCategory
        ? solutionProductCategory.productCategoryName
        : i18next.t('SHARED.SOLUTIONS'),
      productCategories: props.productCategories,
      solutionSearch: true,
      ...(isBubbleParentCategory(idConstants.solutions.categoryId) > 1 && {
        showSubCategories: true
      })
    }
  };

  const hardwareProdUrl = {
    pathname: `/${idConstants.hardware.productCategoryName?.toLowerCase()}`,
    state: {
      text: '',
      categoryId: idConstants.hardware.categoryId,
      productCategoryName: hardwareProductCategory
        ? hardwareProductCategory.productCategoryName
        : i18next.t('SHARED.HARDWARE'),
      productCategories: props.productCategories,
      solutionSearch: true,
      ...(isBubbleParentCategory(idConstants.hardware.categoryId) > 1 && {
        showSubCategories: true
      })
    }
  };

  const automotiveProdUrl = {
    pathname: `/${replaceSpaceWithHyphenInLowerCaps(
      idConstants.automotiveSpares.productCategoryName
    )}`,
    state: {
      text: '',
      categoryId: idConstants.automotiveSpares.categoryId,
      productCategoryName: automotiveProductCategory
        ? automotiveProductCategory.productCategoryName
        : i18next.t('SHARED.AUTOMOTIVE_SPARES'),
      productCategories: props.productCategories,
      solutionSearch: true,
      ...(isBubbleParentCategory(idConstants.automotiveSpares.categoryId) >
        1 && {
        showSubCategories: true,
      }),
    },
  };

  function hideProducts(clearSearchText) {
    setMenuDropdown(false);
    if (clearSearchText) {
      setSearchText('');
    }
  }

  // To listen to the event clicked outside the overlay
  useEffect(() => {
    document.addEventListener('keydown', handleHideDropdown, true);
    document.addEventListener('click', handleClickOrMouseOverOutside, true);
    document.addEventListener('click', handleMobileClickOutside, true);
    document.addEventListener('mouseover', handleClickOrMouseOverOutside, true);
    return () => {
      document.removeEventListener('keydown', handleHideDropdown, true);
      document.removeEventListener(
        'click',
        handleClickOrMouseOverOutside,
        true
      );
      document.removeEventListener('click', handleMobileClickOutside, true);
      document.removeEventListener(
        'mouseover',
        handleClickOrMouseOverOutside,
        true
      );
    };
  });

  useEffect(() => {
    let activeMenuItem =
      Constants.SHOW_AUTOMOTIVE_SPARES === 'true'
        ? headersData
        : headersData.filter(
            (item) =>
              item.categoryId !== idConstants.automotiveSpares.categoryId
          );
    activeMenuItem.forEach((item) => {
      if (
        item.link !== '/' &&
        window.location.pathname.includes(item.link) &&
        item.link !== '/listing'
      ) {
        if (
          window.location.pathname.includes('/data-protection-policy') ||
          window.location.pathname.includes('/myaccount')
        )
          item.isActive = false;
        else item.isActive = true;
      } else if (
        window.location.pathname === '/listing' &&
        item.link === '/listing'
      ) {
        item.isActive = true;
      } else if (
        item.link === '/blogs-resources' &&
        (window.location.pathname.includes('/product-videos') ||
          window.location.pathname.includes('/product-guides'))
      ) {
        item.isActive = true;
      } else {
        item.isActive = false;
      }
    });
    setHeaderItem(activeMenuItem);
  }, [window.location.pathname]);

  const displayOverlay = (data) => {
    if (openAccordion && data === currentSelection) {
      closeAccordion();
    } else if (openAccordion && data !== currentSelection) {
      closeAccordion();
      const timer = setTimeout(() => {
        setOpenAccordion(true);
        setCurrentSelection(data);
      }, 250);
      return () => clearTimeout(timer);
    } else if (!openAccordion) {
      setOpenAccordion(true);
      setCurrentSelection(data);
    }
  };

  const closeActiveOverlay = () => {
    setOpenAccordion(false);
  };
  const onHover = (data) => {
    if (openAccordion && data !== currentSelection) {
      closeAccordion();
      const timer = setTimeout(() => {
        setOpenAccordion(true);
        setCurrentSelection(data);
      }, 250);
      return () => clearTimeout(timer);
    } else if (!openAccordion) {
      setOpenAccordion(true);
      setCurrentSelection(data);
    }
  };

  const handleRoute = (category) => {
    setOpenAccordion(false);
    setMenuDropdown(false);
    props?.history?.push({
      pathname: category.link,
      state: { prePopulateFrom: { url: category.link } }
    });
    localStorage.removeItem('queryParams');
  };

  const routeChange = (url) => {
    if (url === '/search') {
      props.history.push({
        pathname: '/search',
        state: {
          text: '',
          categoryId: 'All',
          productCategoryName: i18next.t(
            'HOME_PAGE.HEADER_FOOTER.ALL_CATEGORIES'
          ),
          productCategories: props.productCategories,
          solutionSearch: true
        }
      });
    } else props.history.push(url);
  };

  const megaMenuBlock = (subCategory) => {
    return (
      <ListItem alignItems='flex-start'>
        <ListItemIcon>
          <Avatar sx={{ width: 60, height: 60 }} src={subCategory.img}></Avatar>
        </ListItemIcon>
        <ListItemText>
          <Typography className={styles['sub-category-text']} color='primary'>
            {i18next.t(subCategory.name)}
          </Typography>
          <Typography
            sx={{
              '&:hover': {
                color: `${theme.palette.common.dark} !important`
              }
            }}
            color='common.dark'
            className={styles['sub-category-text']}
          >
            {i18next.t(subCategory.description)}.
          </Typography>
        </ListItemText>
      </ListItem>
    );
  };
  let headerButtonClassName;
  if (!props.accessToken) {
    if (isDesktopDevice) {
      headerButtonClassName = styles['header-space'];
    } else {
      headerButtonClassName = styles['header-button-group'];
    }
  } else {
    headerButtonClassName = styles['header-button-login'];
  }

  const searchByText = (searchedTerm) => {
    props.history.push({
      pathname:
        '/search' +
        (searchedTerm.length > 2 ? '?key=' + encodeURIComponent(searchedTerm) : ''),
      state: {
        text: searchedTerm ?? '',
        isSearch: true
      }
    });
  };

  const highlightSearchText = (text, searchText) => {
    const regex = new RegExp(`(${searchText})`, 'gi');
    const parts = text?.split(regex);
    return parts?.map((part, index) =>
      regex.test(part) ? <strong key={index}>{part}</strong> : part
    );
  };

  const onClickSearchSuggestions = (text) => {
    props.history?.push({
      pathname: '/search?key=' + encodeURIComponent(text),
      state: {
        text: text
      }
    });
  };

  const handleSignInClick = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };
  const CustomMenuItem = ({ onClose }) => {
    return (
      <>
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 0,
            color: 'common.light',
            fontSize: '0.5rem'
          }}
        >
          <Close />
        </IconButton>
        <Grid
          container
          justifyContent={'space-between'}
          alignContent={'center'}
          sx={{ backgroundColor: 'primary.dark' }}
        >
          <Grid
            item
            p={2}
            sm={5}
            xs={5}
            md={5}
            sx={{
              backgroundColor: 'secondaryBackground.bgBody',
              clipPath: 'polygon(0% 0%, 100% 0%, 95% 100%, 0% 100%)',
              justifyContent: 'center'
            }}
          >
            <Typography variant='body1' align='center'>
              {i18next.t('HOME_PAGE.HEADER_FOOTER.MOBILITY')}
              <br />
              {i18next.t('HOME_PAGE.HEADER_FOOTER.MARKETPLACE')}
              <br />
              {i18next.t('HOME_PAGE.HEADER_FOOTER.IS_NOW_USING')}
              <br />
              <b>{i18next.t('HOME_PAGE.HEADER_FOOTER.SINGLE_KEY_ID')}</b>
            </Typography>
          </Grid>

          <Grid
            item
            p={2}
            sm={7}
            xs={7}
            md={7}
            sx={{ backgroundColor: 'primary.dark' }}
            justifyContent={'center'}
          >
            <Grid
              container
              justifyContent={'center'}
              alignItems={'center'}
              alignSelf={'center'}
              pt={2}
            >
              <Grid item>
                <Box
                  component='img'
                  src={images.singleKeyIdImage}
                  sx={{ width: '100%' }}
                />
              </Grid>
              <Grid item>
                <Button
                  variant='outlined'
                  size='small'
                  onClick={handleSignInRegister}
                  pt={1}
                  sx={{
                    'borderColor': '#fff',
                    'color': 'white',
                    'textTransform': 'unset',
                    'borderRadius': 0,
                    'marginTop': 1,
                    '&:hover': { borderColor: '#fff' }
                  }}
                >
                  {i18next.t('SHARED.CONTINUE')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <React.Fragment>
      <AppBar
        sx={{ backgroundColor: 'primaryBackground.bgBody' }}
        color='primary'
        position='relative'
        className={
          !isMediumDevice
            ? styles['header-container']
            : styles['header-container-small']
        }
      >
        <Box
          sx={{ backgroundImage: `url(${Constants.IMAGE_CDN}topbar.png)` }}
          className={styles['header-bosch-strip']}
        ></Box>
        <Container>
          <Toolbar
            className={
              !isMediumDevice
                ? styles['header-toolbar']
                : styles['header-toolbar-small']
            }
          >
            <Grid
              container
              alignItems='center'
              columnSpacing={2}
              rowSpacing={!isMediumDevice ? 2 : 1}
            >
              <Grid item xs={3} sm={2} md={2} lg={2} xl={2}>
                <Link
                  onClick={navigateToHome}
                  className={
                    isMediumDevice
                      ? styles['header-brand-small']
                      : styles['header-brand-large']
                  }
                >
                  <Box
                    sx={{
                      backgroundImage: `url(${Constants.IMAGE_CDN}bosch-logo-only.svg)`,
                    }}
                    className={styles['bosch-background-image']}
                  ></Box>
                </Link>
              </Grid>
              <Grid
                item
                xs={9}
                sm={10}
                sx={{
                  display: {
                    xl: 'none',
                    lg: 'none',
                    md: 'none',
                    sm: 'flex',
                    xs: 'flex',
                  },
                }}
              >
                <Box
                  className={styles['header-icon-button-container-small']}
                  color='common.dark'
                >
                  <React.Fragment>
                    <IconButton
                      size='large'
                      onClick={toggleSearch}
                      sx={{ ':disabled': { color: 'disabledColor.main' } }}
                      className={styles['header-action-button']}
                    >
                      <SearchSharpIcon
                        sx={{
                          stroke: theme.palette.common.light,
                          strokeWidth: 1,
                        }}
                      />
                    </IconButton>
                    {props.accessToken && (
                      <IconButton
                        size='large'
                        onClick={showCart}
                        sx={{ ':disabled': { color: 'disabledColor.main' } }}
                        className={styles['header-action-button']}
                      >
                        {props.cartProductCount > 0 && (
                          <Badge
                            badgeContent={props.cartProductCount}
                            color='info'
                            className={styles['header-cart-icon-badge']}
                          />
                        )}
                        <ShoppingCartOutlinedIcon
                          className={styles['header-action-cart-logged-in']}
                          sx={{
                            marginLeft: '10px',
                            stroke: theme.palette.common.light,
                            strokeWidth: 1,
                            '&:hover': {
                              color: `${theme.palette.primaryBackground.main} !important`,
                            },
                          }}
                        />
                      </IconButton>
                    )}
                    {!props.accessToken && (
                      <IconButton
                        size='large'
                        className={styles['header-action-button']}
                        onClick={handleSignInClick}
                        sx={{ ':disabled': { color: 'disabledColor.main' } }}
                      >
                        <Box
                          component='img'
                          src={images.loginIcon}
                          alt='log-in'
                          className={styles['icon-style-small']}
                        />
                      </IconButton>
                    )}
                    {props.accessToken && (
                      <IconButton
                        size='large'
                        className={styles['header-action-button']}
                        onClick={showSignoutMenu}
                        sx={{ ':disabled': { color: 'disabledColor.main' } }}
                      >
                        <AccountCircleOutlinedIcon
                          sx={{ stroke: theme.palette.common.light }}
                        />
                      </IconButton>
                    )}
                    {!showMenuDropdown && (
                      <IconButton
                        className={styles['header-action-button']}
                        sx={{ ':disabled': { color: 'disabledColor.main' } }}
                        onClick={showMenuOverlay}
                        size='large'
                      >
                        <MenuOutlinedIcon
                          sx={{
                            stroke: theme.palette.common.light,
                            strokeWidth: 1,
                          }}
                        />
                      </IconButton>
                    )}
                    {showMenuDropdown && (
                      <IconButton
                        className={styles['header-action-button']}
                        sx={{ ':disabled': { color: 'disabledColor.main' } }}
                        onClick={showMenuOverlay}
                        size='large'
                      >
                        <CloseOutlinedIcon
                          sx={{
                            stroke: theme.palette.common.light,
                            strokeWidth: 1,
                          }}
                        />
                      </IconButton>
                    )}
                  </React.Fragment>
                </Box>
              </Grid>
              {showSearch && (
                <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                  <Grid container>
                    <Grid item xs={12}>
                      <TextField
                        className='search-field'
                        variant='filled'
                        color='primary'
                        InputProps={{
                          disableUnderline: false,
                          placeholder: i18next.t(
                            'HOME_PAGE.SEARCH_PLACEHOLDER'
                          ),
                          endAdornment: (
                            <InputAdornment
                              position='start'
                              className={styles['header-search-input-icon']}
                            >
                              <Collapse in={searchText.length > 0}>
                                <IconButton
                                  aria-label='close'
                                  color='default'
                                  size='small'
                                  onClick={() => {
                                    setSearchText('');
                                  }}
                                >
                                  <Tooltip
                                    title={i18next.t(
                                      'HOME_PAGE.HEADER_FOOTER.CLEAR'
                                    )}
                                  >
                                    <CloseOutlinedIcon
                                      sx={{
                                        stroke: theme.palette.common.light,
                                        strokeWidth: 1,
                                      }}
                                    />
                                  </Tooltip>
                                </IconButton>
                              </Collapse>
                              <IconButton
                                aria-label='search'
                                onClick={searchProductsByText}
                                style={{ background: 'transparent' }}
                                size='large'
                              >
                                <SearchSharpIcon
                                  sx={{ stroke: theme.palette.secondary.light }}
                                />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        onChange={handleChange}
                        onKeyPress={handleChange}
                        value={searchText}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {/* Commented this code for Site-Wide-Search Future Release
              <Autocomplete
                        freeSolo
                        autoComplete
                        includeInputInList
                        inputValue={searchText?.length ? searchText : ''}
                        onInputChange={handleChange}
                        onFocus={handleChange}
                        options={autoCompleteOptions}
                        getOptionLabel={(option) =>
                          option ?? ''
                        }
                        placeholder={i18next.t('HOME_PAGE.SEARCH_PLACEHOLDER')}
                        noOptionsText={i18next.t(
                          'FILTER_SECTION.NO_RESULTS_FOUND'
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            color='primary'
                            variant='filled'
                            label={i18next.t('HOME_PAGE.SEARCH_PLACEHOLDER')}
                            onKeyPress={handleChange}
                            value={searchText}
                          />
                        )}
                        renderOption={(props, option, { inputValue }) => {
                          const matches = match(option, inputValue, { insideWords: true });
                          const parts = parse(option, matches);
                          return (
                            <li {...props}
                              onClick={() =>
                                onClickSearchSuggestions(option)
                              }>
                              <div>
                                {parts.map((part, index) => (
                                  <span
                                    key={index}
                                    style={{
                                      fontWeight: part.highlight ? 700 : 400,
                                    }}
                                  >
                                    {part.text}
                                  </span>
                                ))}
                              </div>
                            </li>
                          );
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              ) */}
              {!showSearch && (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  sx={{
                    display: {
                      xs: 'flex',
                      sm: 'flex',
                      md: 'none',
                      xl: 'none',
                      lg: 'none',
                    },
                  }}
                >
                  <Typography
                    pt={1}
                    noWrap
                    variant='inherit'
                    className={styles['header-menu-title']}
                  >
                    {i18next.t('HOME_PAGE.HEADER_FOOTER.MOBILITY_MARKETPLACE')}
                  </Typography>
                </Grid>
              )}
              <Grid
                item
                lg={5}
                xl={5}
                md={5}
                sx={{
                  display: {
                    xs: 'none',
                    sm: 'none',
                    md: 'flex',
                    lg: 'flex',
                    xl: 'flex',
                  },
                }}
                className={
                  isDesktopDevice ? '' : styles['button-group-spacing']
                }
              >
                <ButtonGroup
                  disableElevation
                  className={headerButtonClassName}
                  color='primary'
                >
                  {
                    <Button
                      variant='filledBtn'
                      sx={{
                        background: 'primaryBackground.main !important',
                        height: '30px',
                        marginTop: '4px',
                        fontSize: '0.8rem !important',
                      }}
                      startIcon={
                        <Box
                          component={'img'}
                          src={images.mailSupportIcon}
                          sx={{ height: '18px', width: '18px' }}
                        />
                      }
                      href={'/get-in-touch'}
                    >
                      {i18next.t('HOME_PAGE.HEADER_FOOTER.GET_IN_TOUCH')}
                    </Button>
                  }
                  {!props.accessToken && (
                    <>
                      <Button
                        variant='blackTextBtn'
                        sx={{
                          paddingLeft: '12px !important',
                          '&:hover': {
                            color: `${theme.palette.primaryBackground.main} !important`,
                          },
                        }}
                        startIcon={
                          <AccountCircleOutlinedIcon
                            sx={{ stroke: theme.palette.common.light }}
                          />
                        }
                        onClick={handleSignInClick}
                      >
                        {i18next.t('HOME_PAGE.HEADER_FOOTER.SIGN_IN')}
                      </Button>
                      <Menu
                        anchorEl={menuAnchorEl}
                        open={Boolean(menuAnchorEl)}
                        onClose={handleMenuClose}
                        sx={{
                          '& .MuiMenu-list': {
                            padding: 0,
                          },
                        }}
                      >
                        <CustomMenuItem onClose={handleMenuClose} />
                      </Menu>
                    </>
                  )}
                  {!props.accessToken && (
                    <>
                      <Box
                        component='span'
                        className={styles['button-seprator']}
                        sx={{ color: 'common.dark' }}
                      >
                        {i18next.t('SHARED.OR')}
                      </Box>
                      <Button
                        sx={{
                          '&:hover': {
                            color: `${theme.palette.primaryBackground.main} !important`,
                          },
                        }}
                        variant='blackTextBtn'
                        onClick={handleSignInClick}
                      >
                        {i18next.t('HOME_PAGE.HEADER_FOOTER.CREATE_AN_ACCOUNT')}
                      </Button>
                    </>
                  )}
                </ButtonGroup>
                {props.accessToken && (
                  <>
                    <Button
                      variant='blackTextBtn'
                      startIcon={
                        <Box className={styles['header-cart-icon-block']}>
                          {props.cartProductCount > 0 && (
                            <Badge
                              badgeContent={props.cartProductCount}
                              color='info'
                              className={styles['header-cart-icon-badge']}
                            />
                          )}
                          <Tooltip
                            placement='bottom'
                            title={i18next.t('TOOLTIP.CART')}
                          >
                            <ShoppingCartOutlinedIcon
                              className={styles['header-action-cart-logged-in']}
                              sx={{
                                marginLeft: '10px',
                                stroke: theme.palette.common.light,
                                strokeWidth: 1,
                                '&:hover': {
                                  color: `${theme.palette.primaryBackground.main} !important`,
                                },
                              }}
                            />
                          </Tooltip>
                        </Box>
                      }
                      onClick={showCart}
                    ></Button>
                    <Box
                      component='span'
                      className={styles['button-seprator']}
                      sx={{ color: 'common.dark' }}
                    >
                      |
                    </Box>
                  </>
                )}
                {props.accessToken && (
                  <IconButton
                    size='large'
                    disableRipple
                    disableFocusRipple
                    disableTouchRipple
                    className={`${styles['header-signin-success']}`}
                    onClick={showSignoutMenu}
                    sx={{
                      color: 'common.dark',
                      '&:hover': {
                        color: `${theme.palette.primaryBackground.main} !important`,
                      },
                    }}
                  >
                    <AccountCircleOutlinedIcon
                      sx={{ stroke: theme.palette.common.light }}
                    />
                  </IconButton>
                )}
              </Grid>
              <Grid
                item
                lg={12}
                xl={12}
                md={12}
                justifyContent='flex-end'
                sx={{
                  display: {
                    xs: 'none',
                    sm: 'none',
                    md: 'flex',
                    lg: 'flex',
                  },
                }}
                className={styles['vertical-padding']}
              >
                <Box className={styles['header-menu-title-container']}>
                  <Typography noWrap variant='headerMenuTitle'>
                    {i18next.t('HOME_PAGE.HEADER_FOOTER.MOBILITY_MARKETPLACE')}
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                className={styles['header-menu-buttons']}
                lg={12}
                xl={12}
                md={12}
                sx={{
                  display: {
                    lg: 'flex',
                    md: 'flex',
                    sm: 'none',
                    xs: 'none',
                  },
                }}
              >
                <Box className={styles['flex-items']}>
                  {headerItem.map((data) => {
                    if (data.link === '/seller') {
                      return (
                        <Box key={data?.id}>
                          <Button
                            sx={{
                              color: 'common.dark',
                              '&:hover': {
                                backgroundColor: 'primaryBackground.bgBody',
                                borderBottom: `1.5px solid ${theme.palette.primary.main}`,
                              },
                            }}
                            size='small'
                            onClick={handleSellOnMarketPlace}
                            className={styles['seller-menu-item']}
                            onMouseOver={() => closeActiveOverlay()}
                          >
                            {i18next.t(data.label)}
                          </Button>
                        </Box>
                      );
                    } else if (data.hasSubMenu) {
                      return (
                        <Box key={data.link}>
                          <Button
                            onMouseOver={() => onHover(data)}
                            onClick={() => displayOverlay(data)}
                            disableRipple
                            variant={data.isActive ? 'textBtn' : 'blackTextBtn'}
                            sx={{
                              borderBottom:
                                '1.5px solid transparent !important',
                              '&:hover': {
                                borderBottom: data.isActive
                                  ? ''
                                  : `1.5px solid ${theme.palette.primary.main} !important`,
                              },
                            }}
                            className={
                              data.isActive
                                ? styles['header-menu-item-active']
                                : ''
                            }
                          >
                            <Box display={'flex'}>
                              {i18next.t(data.label)}
                              {
                                <KeyboardArrowDownIcon
                                  className={styles['arrow-up']}
                                  style={{
                                    transform:
                                      openAccordion && data == currentSelection
                                        ? 'rotate(180deg)'
                                        : 'none',
                                  }}
                                  sx={{
                                    stroke: theme.palette.common.light,
                                    strokeWidth: 1,
                                  }}
                                />
                              }
                            </Box>
                          </Button>
                        </Box>
                      );
                    } else {
                      let tabLink;
                      switch (data.link) {
                        case '/about-us':
                          tabLink = '/about-us';
                          break;
                        case '/api':
                          tabLink = apiProdUrl;
                          break;
                        case '/data':
                          tabLink = dataProdUrl;
                          break;
                        case '/solutions':
                          tabLink = solutionProdUrl;
                          break;
                        case '/hardware':
                          tabLink = hardwareProdUrl;
                          break;
                        case '/automotive-spares':
                          tabLink = automotiveProdUrl;
                          break;
                        default:
                          tabLink = data.link;
                          break;
                      }
                      return (
                        <Link
                          to={tabLink}
                          component={RouterLink}
                          underline='none'
                          key={data.link}
                          style={{
                            pointerEvents: data.isDisabled ? 'none' : '',
                          }}
                        >
                          <Button
                            disableRipple
                            variant={data.isActive ? 'textBtn' : 'blackTextBtn'}
                            sx={{
                              borderBottom:
                                '1.5px solid transparent !important',
                              '&:hover': {
                                borderBottom: data.isActive
                                  ? ''
                                  : `1.5px solid ${theme.palette.primary.main} !important`,
                              },
                            }}
                            className={`${
                              data.isActive
                                ? styles['header-menu-item-active']
                                : ''
                            }`}
                            onMouseOver={() => closeActiveOverlay()}
                            onClick={() => {
                              if (data.link === '/listing') {
                                props.history.push('/listing');
                              }
                            }}
                          >
                            {i18next.t(data.label)}
                          </Button>
                        </Link>
                      );
                    }
                  })}
                </Box>
              </Grid>
            </Grid>
          </Toolbar>
        </Container>
        {!isMediumDevice && (
          <Box ref={ref}>
            <CustomAccordion isOpen={openAccordion} type='overlay'>
              <Grid
                container
                sx={{ backgroundColor: 'primaryBackground.bgBody' }}
              >
                <Grid
                  item
                  xs
                  sm
                  md={7}
                  lg={7}
                  xl={7}
                  className={styles['left-content']}
                >
                  {currentSelection.label ===
                    'HOME_PAGE.HEADER_FOOTER.BLOG_RESOURCES' && (
                    <>
                      <Box
                        color='common.dark'
                        className={styles['left-heading-style']}
                      >
                        {i18next.t('HOME_PAGE.HEADER_FOOTER.RESOURCE_CENTER')}
                      </Box>
                      <Grid container>
                        {currentSelection?.subMenuList?.map((productGuides) => {
                          return (
                            <Grid
                              item
                              xs
                              sm
                              md
                              lg
                              xl
                              className={styles['sub-row-content']}
                              key={productGuides.link}
                            >
                              <Link
                                underline='none'
                                href={productGuides.link}
                                to={() => handleRoute(productGuides)}
                              >
                                {megaMenuBlock(productGuides)}
                              </Link>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </>
                  )}
                  {(currentSelection.label === 'SHARED.CLOUD_SERVICES' ||
                    currentSelection.label ===
                      'HOME_PAGE.HEADER_FOOTER.SERVICES') && (
                    <>
                      <Box
                        color='common.dark'
                        className={styles['left-heading-style']}
                      >
                        {i18next.t(currentSelection.label)}
                      </Box>
                      <Grid container>
                        {currentSelection?.subMenuList?.map((subCategory) => {
                          return (
                            <Grid
                              item
                              xs
                              sm
                              md
                              lg
                              xl
                              className={styles['sub-row-content']}
                              key={subCategory.link}
                            >
                              <Link
                                underline='none'
                                href={subCategory.link}
                                to={() => handleRoute(subCategory)}
                              >
                                {megaMenuBlock(subCategory)}
                              </Link>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </>
                  )}
                </Grid>
                <Grid
                  item
                  xs
                  sm
                  md={5}
                  lg={5}
                  xl={5}
                  sx={{ backgroundColor: 'secondaryBackground.bgBody' }}
                >
                  {megaMenuRightContent.map((item, index) => (
                    <Grid
                      sx={{ color: 'common.dark' }}
                      container
                      className={styles['first-comp']}
                      key={item?.url}
                    >
                      <Grid item xs sm md={4} lg={4} xl={4}>
                        <Box
                          component='img'
                          className={styles['animated-comp-icon']}
                          src={item.img}
                          alt={i18next.t(item.content)}
                        />
                      </Grid>
                      <Grid
                        item
                        xs
                        sm
                        md
                        lg
                        xl
                        className={styles['first-content']}
                      >
                        <Typography
                          variant='h6'
                          className={styles['content-style']}
                        >
                          {i18next.t(item.content)}
                        </Typography>
                        <Button
                          variant='borderedBtn'
                          sx={{ fontSize: '14px' }}
                          onClick={() => routeChange(item.url)}
                        >
                          {i18next.t(item.buttonContent)}
                        </Button>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </CustomAccordion>
          </Box>
        )}
      </AppBar>
      {isMediumDevice && showMenuDropdown && (
        <Slide direction='bottom' in={showMenuDropdown}>
          <Paper
            ref={mobileRef}
            square
            elevation={1}
            className={styles['header-submenu-dropdown']}
          >
            {!openAccordion && (
              <Slide direction='right' in={!openAccordion}>
                <Box>
                  <Container fullWidth>
                    <Link
                      to={'/about-us'}
                      underline='none'
                      component={RouterLink}
                    >
                      <Typography
                        color='common.dark'
                        className={styles['header-sub-menu-dropdown-option']}
                        onClick={() => props.history.push('/about-us')}
                      >
                        {i18next.t('SHARED.ABOUT_US')}
                      </Typography>
                    </Link>
                    <Link underline='none'>
                      <Typography
                        className={styles['header-sub-menu-dropdown-option']}
                        onClick={() => displayOverlay(headersData[1])}
                        color={'common.dark'}
                      >
                        <Box display={'flex'}>
                          {i18next.t('SHARED.CLOUD_SERVICES')}
                          <ChevronRightOutlinedIcon
                            sx={{
                              stroke: theme.palette.common.light,
                              strokeWidth: 1,
                            }}
                          />
                        </Box>
                      </Typography>
                    </Link>
                    <Link underline='none'>
                      <Typography
                        className={styles['header-sub-menu-dropdown-option']}
                        onClick={() => displayOverlay(headersData[2])}
                        color={'common.dark'}
                      >
                        <Box display={'flex'}>
                          {i18next.t('HOME_PAGE.HEADER_FOOTER.SERVICES')}
                          <ChevronRightOutlinedIcon
                            sx={{
                              stroke: theme.palette.common.light,
                              strokeWidth: 1,
                            }}
                          />
                        </Box>
                      </Typography>
                    </Link>

                    <Link
                      to={apiProdUrl}
                      underline='none'
                      component={RouterLink}
                      style={{
                        pointerEvents:
                          props?.publishedProductsCounts?.counts &&
                          Object.keys(
                            props?.publishedProductsCounts?.counts
                          ).includes(idConstants.api.categoryId)
                            ? ''
                            : 'none',
                      }}
                    >
                      <Typography
                        color='common.dark'
                        className={styles['header-sub-menu-dropdown-option']}
                        onClick={() => hideProducts(true)}
                      >
                        {i18next.t('SHARED.APIS')}
                      </Typography>
                    </Link>

                    <Link
                      to={solutionProdUrl}
                      underline='none'
                      component={RouterLink}
                      style={{
                        pointerEvents:
                          props?.publishedProductsCounts?.counts &&
                          Object.keys(
                            props?.publishedProductsCounts?.counts
                          ).includes(idConstants.solutions.categoryId)
                            ? ''
                            : 'none',
                      }}
                    >
                      <Typography
                        color='common.dark'
                        className={styles['header-sub-menu-dropdown-option']}
                        onClick={() => hideProducts(true)}
                      >
                        {i18next.t('SHARED.SOLUTIONS')}
                      </Typography>
                    </Link>
                    <Link
                      to={dataProdUrl}
                      underline='none'
                      component={RouterLink}
                      style={{
                        pointerEvents:
                          props?.publishedProductsCounts?.counts &&
                          Object.keys(
                            props?.publishedProductsCounts?.counts
                          ).includes(idConstants.data.categoryId)
                            ? ''
                            : 'none',
                      }}
                    >
                      <Typography
                        color={'common.dark'}
                        className={styles['header-sub-menu-dropdown-option']}
                        onClick={() => hideProducts(true)}
                      >
                        {i18next.t('SHARED.DATA')}
                      </Typography>
                    </Link>
                    <Link
                      to={hardwareProdUrl}
                      underline='none'
                      component={RouterLink}
                      style={{
                        pointerEvents:
                          props?.publishedProductsCounts?.counts &&
                          Object.keys(
                            props?.publishedProductsCounts?.counts
                          ).includes(idConstants.hardware.categoryId)
                            ? ''
                            : 'none',
                      }}
                    >
                      <Typography
                        color={'common.dark'}
                        className={styles['header-sub-menu-dropdown-option']}
                        onClick={() => hideProducts(true)}
                      >
                        {i18next.t('SHARED.HARDWARE')}
                      </Typography>
                    </Link>
                    {Constants.SHOW_AUTOMOTIVE_SPARES === 'true' && (
                      <Link
                        to={automotiveProdUrl}
                        underline='none'
                        component={RouterLink}
                        style={{
                          pointerEvents:
                            props?.publishedProductsCounts?.counts &&
                            Object.keys(
                              props?.publishedProductsCounts?.counts
                            ).includes(idConstants.automotiveSpares.categoryId)
                              ? ''
                              : 'none',
                        }}
                      >
                        <Typography
                          color={'common.dark'}
                          className={styles['header-sub-menu-dropdown-option']}
                          onClick={() => hideProducts(true)}
                        >
                          {i18next.t('SHARED.AUTOMOTIVE_SPARES')}
                        </Typography>
                      </Link>
                    )}
                    <Link underline='none'>
                      <Typography
                        className={styles['header-sub-menu-dropdown-option']}
                        onClick={() => displayOverlay(headersData[7])}
                        color={'common.dark'}
                      >
                        <Box display='flex'>
                          {i18next.t('HOME_PAGE.HEADER_FOOTER.BLOG_RESOURCES')}
                          <ChevronRightOutlinedIcon
                            sx={{
                              stroke: theme.palette.common.light,
                              strokeWidth: 1,
                            }}
                          />
                        </Box>
                      </Typography>
                    </Link>
                    {/* Commenting as of now, it will be removed once 'become a seller' is added */}
                    {/* <Link underline='none'>
                    </Link> */}
                    <Link underline='none' to={'/listing'}>
                      <Typography
                        onClick={() => {
                          props.history.push('/listing');
                        }}
                        color={'common.dark'}
                        className={styles['header-sub-menu-dropdown-option']}
                      >
                        {i18next.t('HOME_PAGE.HEADER_FOOTER.EXPLORE_ALL')}
                      </Typography>
                    </Link>
                    <Link underline='none' href={'/get-in-touch'}>
                      <Typography
                        onClick={() => {
                          props.history.push('/get-in-touch');
                        }}
                        color={'common.dark'}
                        className={styles['header-sub-menu-dropdown-option']}
                      >
                        {i18next.t('HOME_PAGE.HEADER_FOOTER.GET_IN_TOUCH')}
                      </Typography>
                    </Link>
                    {!props.accessToken && (
                      <Accordion
                        elevation={0}
                        disableGutters
                        expanded={false}
                        className={styles['header-sub-menu-option-container']}
                      >
                        <AccordionSummary
                          className={styles['header-sub-menu-dropdown-icon']}
                          expandIcon={null}
                        >
                          <Button
                            variant='filledBtn'
                            fullWidth={isSmallDevice ? true : false}
                            onClick={handleSignInClick}
                          >
                            {i18next.t('HOME_PAGE.HEADER_FOOTER.REGISTER')}
                          </Button>
                        </AccordionSummary>
                      </Accordion>
                    )}
                  </Container>
                </Box>
              </Slide>
            )}
            {openAccordion && (
              <Box>
                <Slide direction='left' in={openAccordion}>
                  <Box>
                    <IconButton onClick={goBack}>
                      <KeyboardBackspaceOutlinedIcon
                        sx={{
                          stroke: theme.palette.common.light,
                          strokeWidth: 1,
                          fontSize: '2.5rem',
                        }}
                      />
                    </IconButton>
                    <Box className={styles['megamenu-content-mobile']}>
                      {currentSelection.label ===
                        'HOME_PAGE.HEADER_FOOTER.BLOG_RESOURCES' && (
                        <>
                          <Typography variant='largeText'>
                            {i18next.t(
                              'HOME_PAGE.HEADER_FOOTER.RESOURCE_CENTER'
                            )}
                          </Typography>
                          {currentSelection.subMenuList.map((productGuides) => {
                            return (
                              <Link
                                className={styles['cloud-submenu-mobile']}
                                underline='none'
                                href={productGuides.link}
                                key={productGuides.link}
                                to={() => handleRoute(productGuides)}
                              >
                                {megaMenuBlock(productGuides)}
                              </Link>
                            );
                          })}
                        </>
                      )}
                      {(currentSelection.label === 'SHARED.CLOUD_SERVICES' ||
                        currentSelection.label ===
                          'HOME_PAGE.HEADER_FOOTER.SERVICES') && (
                        <>
                          <Typography
                            className={styles['megamenu-heading-mobile']}
                          >
                            {i18next.t(currentSelection.label)}
                          </Typography>
                          {currentSelection.subMenuList.map((subCategory) => {
                            return (
                              <Link
                                className={styles['cloud-submenu-mobile']}
                                underline='none'
                                href={subCategory.link}
                                key={subCategory.link}
                                to={() => handleRoute(subCategory)}
                              >
                                {megaMenuBlock(subCategory)}
                              </Link>
                            );
                          })}
                        </>
                      )}
                    </Box>
                  </Box>
                </Slide>
              </Box>
            )}
          </Paper>
        </Slide>
      )}
      <Container>
        <Box my={1} px={!isMediumDevice ? 3 : 0}>
          <Grid container columnSpacing={1}>
            <Grid item xs={12} className={styles['breadcrumb-Grid']}>
              {props.history.location.pathname !== '/' ? (
                <Breadcrumb></Breadcrumb>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
        </Box>
      </Container>
      {confirmationModalLoader && <Loader />}
      {signInOutBlock}
      <Snackbar
        open={snackAlertFlag}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={() => {
          setSnackAlertFlag(false);
        }}
      >
        <Alert severity={snackAlertSeverity}>{snackAlertMsg}</Alert>
      </Snackbar>
      {openDialog && (
        <DialogModal
          title={dialogMessage}
          okOperation={() => setOpenDialog(false)}
          onClose={() => setOpenDialog(false)}
          submitLabel={'SHARED.OKAY'}
          fromUnAuthorizedAccessModal={true}
          hideCloseIcon
        />
      )}
    </React.Fragment>
  );
}

const mapStateToProps = (redux_state) => ({
  user: redux_state.loginReducer.user,
  accessToken: redux_state.loginReducer.accessToken,
  productCategories: redux_state.LandingReducer.productCategories,
  publishedProductsCounts: redux_state.LandingReducer.publishedProductsCounts,
  isDataFetched: redux_state.MasterDataReducer.isDataFetched,
  cartProductCount: redux_state.CartReducer.cartProductCount,
  searchSuggestions: redux_state.LandingReducer.searchSuggestions
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
  retrieveUserCompanyStatus: () => dispatch(retrieveUserCompanyStatus()),
  getSearchSuggestions: (queryParams) =>
    dispatch(getSearchSuggestions(queryParams)),
  getPromisedAllMasterData: (categoryId) =>
    dispatch(getPromisedAllMasterData(categoryId)),
  getSubcategories: () => dispatch(getSubcategories()),
  getProductCategories: () => dispatch(getProductCategories()),
  getAllProductsCount: () => dispatch(getAllProductsCount()),
  getCartProduct: () => dispatch(getCartProduct())
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
