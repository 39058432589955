import React from 'react';
import { Grid, Typography, Container,Box } from '@mui/material';
import styles from './our-ecosystem.module.scss';
import i18next from 'i18next';
import CarouselContainer from '../../Carousel';


const OurEcoSystem = () => {
    return (
        <Box sx={{backgroundColor:'primaryBackground.bgBody'}}>
            <Container>
                <Grid container xs={12} sm={12} md={12} lg={12} className={styles['ecosystem-container']} >
                    <Grid item xs={12} sm={12} md={12} lg={12} >
                        <Typography variant='h5'>{i18next.t('ABOUT_US_PAGE.OUR_ECOSYSTEM.TITLE')}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} mb={4}>
                        <Typography align='center' variant='body1'>{i18next.t('ABOUT_US_PAGE.OUR_ECOSYSTEM.BODY')}.</Typography>
                    </Grid>
                    <CarouselContainer heading={i18next.t('ABOUT_US_PAGE.OUR_ECOSYSTEM.TITLE')}/>
                </Grid>
            </Container>
        </Box>
    );
};

export default OurEcoSystem;