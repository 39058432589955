import React, { useState } from 'react';
import {
  Typography,
  Box,
  Modal,
  Grid,
  IconButton,
  Button
} from '@mui/material';
import { appTheme } from '../../../../../themes/appTheme';
import CloseIcon from '@mui/icons-material/Close';
import i18next from 'i18next';
import Images from '../../../../../assets/img';
import styles from './index.module.scss';

const DialogModal = (props) => {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
    props.onClose();
  };

  const handleOk = () => {
    setOpen(false);
    props.okOperation();
    props.onClose();
  };

  const handleCancel = () => {
    setOpen(false);
    props.cancelOperation && props.cancelOperation();
    props.onClose();
  };

  const {
    heading,
    title,
    modalContent,
    isSuccess,
    submitLabel,
    rejectLabel,
    fromUnAuthorizedAccessModal,
    hideCloseIcon,
    image,
    isSecondaryButton,
    isContentHTML
  } = props;

  return (
    <React.Fragment>
      <Modal
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleClose();
          }
        }}
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
        open={open}
      >
        <Box
          className={styles['div-style']}
          sx={{ backgroundColor: 'common.light' }}
        >
          <Grid container>
            {heading && (
              <>
                <Grid
                  item
                  xs={11}
                >
                  <Box>
                    <Typography
                      component='span'
                      variant='lightText'
                      fontSize='1.6rem'
                    >
                      {heading}
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={1}
                >
                  <IconButton
                    onClick={handleClose}
                    size='large'
                  >
                    <CloseIcon size='small' />
                  </IconButton>
                </Grid>
              </>
            )}
            {
              !fromUnAuthorizedAccessModal && (<Grid item xs={12}>
                <Box
                  sx={{
                    backgroundColor: isSuccess
                      ? appTheme.palette.primaryBackground.main
                      : appTheme.palette.common.light
                  }}
                  className={styles['dialog-box']}
                >
                  <Box
                    component='img'
                    src={isSuccess ? Images.successIcon : Images.rejectIcon}
                    className={styles['dialog-box__image-container']}
                  />
                </Box>
              </Grid>
              )}
          </Grid>
          {
            fromUnAuthorizedAccessModal && <>

              <Box
                sx={{ height: '13rem', width: '100%', backgroundColor: 'secondary.light' }}
                component='img'
                src={image ? image : Images.analyticsInsightsGeneric}
                alt={i18next.t('IMAGE_ALT_TEXT.ANALYTICS_INSIGHTS_GENERIC')}
              />
              {!hideCloseIcon && (
                <IconButton
                  onClick={handleOk}
                  sx={{
                    position: 'absolute',
                    right: '2%',
                    top: '3%'
                  }}
                >
                  <CloseIcon />
                </IconButton>
              )}
            </>
          }
          <Box className={styles['description-container']}>
            <Box className={styles['dialog-box__description']}>
              <Typography sx={fromUnAuthorizedAccessModal && { fontSize: '13px !important' }} variant='boldOnlyText'>
                {' '}
                {i18next.t(title)}{' '}
              </Typography>
              <Typography variant='modalSubTitle'>
                {' '}
                {isContentHTML ? modalContent : ` ${i18next.t(modalContent)} `}
              </Typography>
            </Box>
            <Grid
              container
              display='flex'
              justifyContent={'flex-end'}
            >
              <Grid
                item
                sm
              >
                <Box
                  mt={2}
                  display='flex'
                  justifyContent={'flex-end'}
                  gap={2}
                >
                  {rejectLabel && (
                    <Button
                      variant='borderedBtn'
                      onClick={handleCancel}
                      sx={{ textTransform: 'capitalize' }}
                    >
                      {i18next.t(rejectLabel)}
                    </Button>
                  )}
                  {isSecondaryButton && (
                    <Button
                      variant='borderedBtn'
                      onClick={handleCancel}
                      sx={{ textTransform: 'capitalize' }}
                    >
                      {i18next.t('FORM.CANCEL')}
                    </Button>
                  )}
                  {submitLabel && (
                    <Button
                      variant='filledBtn'
                      onClick={handleOk}
                    >
                      {i18next.t(submitLabel)}
                    </Button>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default DialogModal;
