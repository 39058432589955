const breadcrumbData = {
    landing: {
        name: 'Home',
        path: '/'
    },
    otherItems: [
        {
            name: 'Contact Us',
            path: '/contact-us'
        },
        {
            name: 'Get In Touch',
            path: '/get-in-touch'
        },
        {
            name: 'Search',
            path: '/search'
        },
        {
            name: 'Data',
            path: '/data'
        },
        {
            name: 'Enterprise Licensing',
            path: '/enterprise-licensing'
        },
        {
            name: 'Managed Services',
            path: '/managed-services'
        },
        {
            name: 'Cloud Infrastructure',
            path: '/cloud-infrastructure'
        },
        {
            name: 'Solutions',
            path: '/solutions'
        },
        {
            name: 'Cloud Services',
            path: '/cloud-services'
        },
        {
            name: 'Services',
            path: '/services'
        },
        {
            name: 'Product',
            path: '/product'
        },
        {
            name: 'My Account',
            path: '/myaccount'
        },
        {
            name: 'My Applications',
            path: '/myaccount/myapplications'
        },
        {
            name: 'My Proposals',
            path: '/myaccount/myproposals'
        },
        {
            name: 'Overview',
            path: '/myaccount/myproposals/proposaloverview'
        },
        {
            name: 'Application',
            path: '/myaccount/myapplications/application'
        },
        {
            name: 'API',
            path: '/api'
        },
        {
            name: 'Analytics & Tracking',
            path: '/myaccount/apistatistics'
        },
        {
            name: 'My Orders',
            path: '/myaccount/myorders'
        },
        {
            name: 'Order Details',
            path: '/myaccount/myorders/orderdetails'
        },
        {
            name: 'Product',
            path: '/myaccount/myapplications/application/product'
        },
        {
            name: 'Product',
            path: '/order-details'
        },
        {
            name: 'Product',
            path: '/order-summary'
        },
        {
            name: 'My Profile',
            path: '/profile'
        },
        {
            name: 'Support',
            path: '/support'
        },
        {
            name: 'About Us',
            path: '/about-us'
        },
        {
            name: 'Case Study',
            path: '/case-studies/zeliot'
        },
        {
            name: 'Product Guides',
            path: '/product-guides'
        },
        {
            name: 'Product Videos',
            path: '/product-videos'
        },
        {
            name: 'Corporate information',
            path: '/corporate-information'
        },
        {
            name: 'Legal notice',
            path: '/legal-notice'
        },
        {
            name: 'Data protection notice',
            path: '/data-protection-policy'
        },
        {
            name: 'Terms of use',
            path: '/terms-of-use'
        },
        {
            name: 'Developer Guide',
            path: '/developer-guide'
        },
        {
            name: 'Hardware',
            path: '/hardware'
        },
        {
            name: 'Automotive Spares',
            path: '/automotive-spares'
        },
        {
            name: 'Order Fulfillment',
            path: '/myaccount/myorders/order-fulfillment'
        },
        {
            name: 'Cart',
            path: '/cart'
        },
        {
            name: 'Listing',
            path: '/listing'
        },
        {
            name: 'My Quotes',
            path: '/myaccount/myquotes'
        },
        {
            name: 'Professional Services',
            path: '/professional-services'
        },
        {
            name: 'Add Quote',
            path: '/listing/add-quote'
        },
        {
            name: 'Track Issues',
            path: '/myaccount/track-issues'
        },
        {
            name: 'Details',
            path: '/myaccount/track-issues/details'
        }
    ]
};

export default breadcrumbData;