import React, { useState } from 'react';
import { Card, CardContent, CardMedia, Box, Grid, Typography, Tooltip, Button, Link } from '@mui/material';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import i18next from 'i18next';
import idConstants from '../../../data/idConstants';
import styles from './product-card.module.scss';
import DeviceBreakPoints from '../../../deviceBreakPoints';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import { appTheme } from '../../../themes/appTheme';

function MsCard(props) {

    const { isSmallDevice, isSmallDeviceBetween, isMediumDeviceViewBetween, isDesktopDevice, mdScreen, theme } = DeviceBreakPoints();
    const [isEllipsis, setIsEllipsis] = useState(false);
    const [isEllipsisCategory, setIsEllipsisCategory] = useState(false);

    let subCategories;
    if (props?.card?.productDetailedInfo?.subcategory?.length > 0) {
        subCategories = props?.card?.productDetailedInfo?.subcategory?.slice(0, 3);
    } else {
        /* Mapping to an array if subcategory is an object, subcategory is an object for Professional service category */
        const subCategory = props?.card?.productDetailedInfo?.subcategory;
        if (subCategory) {
            subCategories = [
                {
                    ...subCategory
                }
            ];
        }
    }

    const scrollToGetinTouch = () => {
        if (props.page === i18next.t('CAMPAIGN_PAGE.CAMPAIGN')) {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }
        else {
            window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
        }
    };

    const getLinkPath = (url, id, name) => {
        let linkPath = '';
        const encodedProductName = encodeURIComponent(name);
        switch (props?.categoryId) {
            case idConstants.data.categoryId:
                linkPath = `/data/${encodedProductName}/overview/${id}`;
                break;
            case idConstants.cloudInfrastructure.categoryId:
                linkPath = `/cloud-services/cloud-infrastructure/${encodedProductName}/overview/${id}`;
                break;
            case idConstants.managedServices.categoryId:
                linkPath = `/cloud-services/managed-services/${encodedProductName}/overview/${id}`;
                break;
            case idConstants.enterpriseLicensing.categoryId:
                linkPath = `/cloud-services/enterprise-licensing/${encodedProductName}/overview/${id}`;
                break;
            case idConstants.iotSoftwareServices.categoryId:
                linkPath = `/services/iot-software-services/${encodedProductName}/overview/${id}`;
                break;
            case idConstants.professionalServices.categoryId:
                linkPath = `/services/professional-services/${encodedProductName}/overview/${id}`;
                break;
            case idConstants.communicationServices.categoryId:
                linkPath = `/services/communication-services/${encodedProductName}/overview/${id}`;
                break;
            case idConstants.solutions.categoryId:
                linkPath = `/solutions/${encodedProductName}/overview/${id}`;
                break;
            case idConstants.hardware.categoryId:
                linkPath = `/hardware/${encodedProductName}/overview/${id}`;
                break;
            case idConstants.automotiveSpares.categoryId:
                linkPath = `/automotive-spares/${encodedProductName}/overview/${id}`;
                break;
            case idConstants.sdk.categoryId:
                linkPath = `/services/sdk/${encodedProductName}/overview/${id}`;
                break;
            default:
                linkPath = url;
                break;
        }
        return linkPath;
    };
   
    const productId = props?.card?.productId;
    let productName = props?.card?.productName?.trim().toLowerCase();
    if (productName.includes('-')) {
        productName = productName.split('-').join('').replace(/  /g, ' ').replace(/ /g, '-');
    } else {
        productName = productName.replace(/ /g, '-');
    }
    const location = window.location.pathname;
    let urlCategoryName = location.substring(1);
    const propsCategoryName = props?.card?.productCategory?.productCategoryName.toLowerCase();

    if (urlCategoryName === 'search') {
        urlCategoryName = propsCategoryName === 'fuel and energy' ? 'api' : `${propsCategoryName}`;
    } else {
        urlCategoryName = propsCategoryName;
    }
    const navigationUrl = `/${urlCategoryName}/${encodeURIComponent(productName)}/overview/${productId}`;
    const linkPathname = getLinkPath(navigationUrl, productId, encodeURIComponent(productName));

    const getFreemiumTagStyle = () => {
      return isDesktopDevice || mdScreen
        ? styles['freemium-tag-view']
        : isSmallDevice || !isSmallDeviceBetween
        ? styles['freemium-tag-view-responsive']
        : '';
    };

    return (
      <React.Fragment>
        <Card
          className={`${props.className} ${styles['card']} ${
            props?.flagCheckStyle === 'true' ? '' : styles['search-card']
          }`}
          sx={{ backgroundColor: 'primaryBackground.bgBody' }}
        >
          <CardContent className={styles['card-content-style']}>
            <Box>
              <Box>
                <Box className={styles['tag-display']}>
                  {props?.purchaseBenefit === i18next.t('PRODUCT.FREEMIUM') && (
                    <Grid container className={styles['freemium-tag-grid']}>
                      <Grid
                        item
                        sx={{ display: 'inline-flex' }}
                        className={getFreemiumTagStyle()}
                      >
                        <Box
                          border={1}
                          className={
                            isSmallDevice ||
                            isMediumDeviceViewBetween ||
                            isSmallDeviceBetween
                              ? `${styles['category-name-responsive']} ${styles['category-name']}`
                              : styles['category-name']
                          }
                          sx={{ color: 'common.light' }}
                        >
                          <Tooltip
                            title={i18next.t('PRODUCT.FREEMIUM')}
                            open={isEllipsisCategory}
                            onClose={() => setIsEllipsisCategory(false)}
                            enterTouchDelay={0}
                          >
                            <Box
                              className={
                                isSmallDevice
                                  ? styles['category-name-Box-responsive']
                                  : styles['category-name-Box']
                              }
                              onClick={(event) =>
                                setIsEllipsisCategory(
                                  event.target.offsetWidth <
                                    event.target.scrollWidth
                                    ? true
                                    : false
                                )
                              }
                              onMouseOver={(event) =>
                                setIsEllipsisCategory(
                                  event.target.offsetWidth <
                                    event.target.scrollWidth
                                    ? true
                                    : false
                                )
                              }
                            >
                              {i18next.t('PRODUCT.FREEMIUM')}
                            </Box>
                          </Tooltip>
                        </Box>
                      </Grid>
                      <Grid item>
                        <Grid
                          container
                          className={styles['freemium-tag-gradient']}
                        >
                          <Box
                            border={1}
                            className={styles['category-name-gradient-1']}
                          >
                            <Box className={styles['display-grid']}></Box>
                          </Box>
                          <Box
                            border={1}
                            className={styles['category-name-gradient-2']}
                          >
                            <Box className={styles['display-grid']}></Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Box>
                <CardMedia
                  className={styles['card-media-style']}
                  component='Box'
                  sx={{
                    backgroundColor: 'primaryBackground.bgBody',
                    borderColor: `${theme.palette.secondary.main} !important`,
                  }}
                />
              </Box>
              <Box
                className={styles['product-logo-wrap']}
                backgroundColor='primaryBackground.bgBody'
              >
                <CardMedia
                  className={styles['product-logo']}
                  sx={{
                    borderColor: `${theme.palette.secondary.main} !important`,
                  }}
                  image={props.thumbnailURL}
                  title={props.cardTitle}
                  component='img'
                />
              </Box>
            </Box>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Tooltip
                title={props.cardTitle}
                enterTouchDelay={0}
                open={isEllipsis}
                onClose={() => setIsEllipsis(false)}
              >
                <Typography
                  onClick={(event) =>
                    setIsEllipsis(
                      event.target.offsetWidth < event.target.scrollWidth
                        ? true
                        : false
                    )
                  }
                  onMouseOver={(event) =>
                    setIsEllipsis(
                      event.target.offsetWidth < event.target.scrollWidth
                        ? true
                        : false
                    )
                  }
                  gutterBottom
                  variant='h6'
                  className={styles['product-name']}
                  sx={{ color: `${theme.palette.common.dark} !important` }}
                  component='h6'
                  align='left'
                >
                  {props.cardTitle}
                </Typography>
              </Tooltip>
            </Grid>
            {props?.card?.productCategory?.categoryId ===
            idConstants.automotiveSpares.categoryId ? (
              <>
                <Box sx={{ display: 'flex' }}>
                  <Typography
                    component='p'
                    variant='mediumText'
                    className={`${styles['product-card-desc']}`}
                    color='initial'
                    align='left'
                    sx={{width:'55%'}}
                  >
                    {`${i18next.t('SHARED.QUANTITY')} :`}
                  </Typography>
                  <Typography
                    component='p'
                    variant='mediumText'
                    className={`${styles['product-card-desc']}`}
                    color='initial'
                    align='left'
                    sx={{width:'45%'}}
                  >
                    {props?.card?.productDetailedInfo?.quantity}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex' }}>
                  <Typography
                    component='p'
                    variant='mediumText'
                    className={`${styles['product-card-desc']}`}
                    color='initial'
                    align='left'
                    sx={{width:'55%'}}
                  >
                    {`${i18next.t('SHARED.UNIT_COST')} :`}
                  </Typography>
                  <Typography
                    component='p'
                    variant='mediumText'
                    className={`${styles['product-card-desc']}`}
                    color='initial'
                    align='left'
                    sx={{width:'45%'}}
                  >
                    {props?.card?.productDetailedInfo?.unitCost}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex' }}>
                  <Typography
                    component='p'
                    variant='mediumText'
                    className={`${styles['product-card-desc']}`}
                    color='initial'
                    align='left'
                    sx={{width:'55%'}}
                  >
                    {`${i18next.t('SHARED.UNIT_PRICE')} :`}
                  </Typography>
                  <Typography
                    component='p'
                    variant='mediumText'
                    className={`${styles['product-card-desc']}`}
                    color='initial'
                    align='left'
                    sx={{width:'45%'}}
                  >
                    {props?.card?.productDetailedInfo?.unitPrice}
                  </Typography>
                </Box>
              </>
            ) : (
              <>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography
                    component='p'
                    variant='smallText'
                    className={`${styles['product-card-desc']}`}
                    color='initial'
                    align='left'
                  >
                    {props.desc}
                  </Typography>
                  {props.showCategoryName && props.categoryName && <Typography
                    component='p'
                    variant='smallText'
                    className={`${styles['product-card-category-wrapper']}`}
                    color='initial'
                    align='left'
                  >
                    <Typography
                    component='span'
                    variant='smallText'
                    className={`${styles['product-card-category-text']}`}
                    color='initial'
                    align='left'
                    style={{backgroundColor: appTheme.palette.primary.light, borderColor: appTheme.palette.secondary.main}}
                    >
                      {props.categoryName}
                    </Typography>
                  </Typography>}
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className={styles['subcategories']}
                >
                  {subCategories?.map(
                    (data, index) =>
                      props?.subcategories?.filter(
                        (item) => item.id === data.id
                      )[0]?.name && (
                        <Grid item key={index}>
                          <Box
                            className={styles['subcategory-card']}
                            sx={{
                              borderColor: `${theme.palette.secondary.main} !important`,
                            }}
                          >
                            <Box className={styles['subcategory-card-name']}>
                              {
                                props?.subcategories?.filter(
                                  (item) => item.id === data.id
                                )[0]?.name
                              }
                            </Box>
                          </Box>
                        </Grid>
                      )
                  )}
                </Grid>
              </>
            )}
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              {props.page !== i18next.t('CAMPAIGN_PAGE.CAMPAIGN') &&
              props.page !== i18next.t('CAMPAIGN_PAGE.CAMPAIGN2') ? (
                <Tooltip title={i18next.t('SHARED.VIEW_DETAILS')}>
                  <Link
                    component={RouterLink}
                    to={{
                      pathname: linkPathname,
                      state: {
                        data: props.card,
                        productCategories: props.productCategories,
                        categoryId: props?.categoryId,
                        categoryName: props.categoryName,
                        filteredOptions: props.filteredOptions,
                        searchText: props.searchText,
                        currentSort: {
                          sortBy: props.sortBy,
                          sortOrder: props.sortOrder,
                          sortVal: props.sortVal,
                        },
                      },
                      hash: 'overview',
                    }}
                    sx={{
                      backgroundColor: 'primaryBackground.bgBody',
                      '&:hover': {
                        backgroundColor: 'primaryBackground.bgBody',
                      },
                    }}
                    className={styles['view-btn']}
                    disableFocusRipple
                    disableTouchRipple
                    disableRipple
                  >
                    <ArrowCircleRightOutlinedIcon
                      sx={{
                        fontSize: '2rem',
                        stroke: theme.palette.common.light,
                        strokeWidth: 1,
                      }}
                    />
                  </Link>
                </Tooltip>
              ) : (
                <Tooltip title={i18next.t('SHARED.VIEW_DETAILS')}>
                  <Button
                    sx={{
                      backgroundColor: 'primaryBackground.bgBody',
                      '&:hover': {
                        backgroundColor: 'primaryBackground.bgBody',
                      },
                    }}
                    onClick={scrollToGetinTouch}
                    className={styles['view-btn']}
                    disableFocusRipple
                    disableTouchRipple
                    disableRipple
                  >
                    <ArrowCircleRightOutlinedIcon
                      sx={{
                        fontSize: '2rem',
                        stroke: theme.palette.common.light,
                        strokeWidth: 1,
                      }}
                    />
                  </Button>
                </Tooltip>
              )}
            </Grid>
          </CardContent>
        </Card>
      </React.Fragment>
    );
}

const mapStateToProps = (redux_state) => ({
    productCategories: redux_state.LandingReducer.productCategories,
    subcategories: redux_state.MasterDataReducer.subcategories,
});

export default connect(mapStateToProps, null)(MsCard);