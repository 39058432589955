import { applyMiddleware, legacy_createStore as createStore } from 'redux';
import thunk from 'redux-thunk';
import reducers from './reducer';

/**
 * Create Middleware
 */
const middleware = applyMiddleware(thunk);
/**
     * Create Store
     */
const store = createStore(reducers, middleware);

export default store;
