const CDN_LINK = `${process.env.REACT_APP_CDN_LINK}/assets/img`;
const CDN_LINK_THEME = `${process.env.REACT_APP_CDN_LINK}/assets/themes/elegant`; //Link to change the images according to the theme.
import idConstants from '../../data/idConstants';

const images = {
  singleKeyIdImage: `${CDN_LINK}/logo-single-key-id.svg`,
  filterIcon: `${CDN_LINK}/filter-icon.svg`,
  checkboxChecked: `${CDN_LINK}/cb-checked.svg`,
  checkboxUnchecked: `${CDN_LINK}/cb-unchecked.svg`,
  listingPageBanner: `${CDN_LINK}/listing-page-banner-animation.svg`,
  boschLogo: `${CDN_LINK}/bosch-logo-only.svg`,
  startPlayFrame: `${CDN_LINK}/start-play-frame.svg`,
  closeIconNav: `${CDN_LINK}/close.svg`,
  routeMaticThumbNail: `${CDN_LINK}/routematic-thumbnail.png`,
  successStoryCardImage: `${CDN_LINK}/success-story-card-image.jpg`,
  boschStrip: `${CDN_LINK}/topbar.png`,
  boschStripSmall: `${CDN_LINK}/supergraphic-topbar.jpg`,
  alertErrorSvg: `${CDN_LINK}/alert-error.svg`,
  addLightGreyIcon: `${CDN_LINK}/add-light-grey.svg`,
  imagery: `${CDN_LINK}/imagery.svg`,
  boschIcons: {
    peopleIcon: `${CDN_LINK}/people.svg`,
    appIcon: `${CDN_LINK}/directory-cloud.svg`,
  },
  alertSuccess: `${CDN_LINK}/alert-success.svg`,
  successIcon: `${CDN_LINK}/success-icon.svg`,
  rejectIcon: `${CDN_LINK}/reject-icon.svg`,
  companyLogos: {
    awsLogo: `${CDN_LINK}/ourEcoSystemImages/aws.png`,
    azureLogo: `${CDN_LINK}/ourEcoSystemImages/azure.png`,
    chargeZoneLogo: `${CDN_LINK}/ourEcoSystemImages/chargezone.svg`,
    kazamLogo: `${CDN_LINK}/ourEcoSystemImages/kazam.jpg`,
    magentaLogo: `${CDN_LINK}/ourEcoSystemImages/magenta.svg`,
    mongoDbLogo: `${CDN_LINK}/ourEcoSystemImages/mongodb.png`,
    routematicLogo: `${CDN_LINK}/ourEcoSystemImages/routematic.png`,
    zeliotLogo: `${CDN_LINK}/ourEcoSystemImages/zeliot.png`,
    ampereLogo: `${CDN_LINK}/ourEcoSystemImages/ampere.png`,
    carxLogo: `${CDN_LINK}/ourEcoSystemImages/carx.png`,
    expedentLogo: `${CDN_LINK}/ourEcoSystemImages/expedent.png`,
    setuLogo: `${CDN_LINK}/ourEcoSystemImages/setu.png`,
  },
  sortByIcon: `${CDN_LINK}/down.svg`,
  recentlyAddedIcon: `${CDN_LINK}/recently-added.svg`,
  ascIcon: `${CDN_LINK}/ascending.svg`,
  descIcon: `${CDN_LINK}/decending.svg`,
  generalFaq: `${CDN_LINK}/general-faq.svg`,
  resetIcon: `${CDN_LINK}/reset-icon.svg`,
  sortIcon: `${CDN_LINK}/sort-icon.svg`,
  checkIcon: `${CDN_LINK}/check.svg`,
  faqBanner: `${CDN_LINK}/faq-banner.png`,
  sellerIcon: `${CDN_LINK}/seller-faq.svg`,
  buyerIcon: `${CDN_LINK}/buyer-faq.svg`,
  thumbnail: `${CDN_LINK}/thumbnail.svg`,
  backgroundimg: `${CDN_LINK}/business.jpg`,
  chargeZoneLogo: `${CDN_LINK}/mcp/charge-zone-logo.svg`,
  carezeusLogo: `${CDN_LINK}/mcp/carezeus-logo.svg`,
  forwardIcon: `${CDN_LINK}/mcp/forward-right.svg`,
  backIcon: `${CDN_LINK}/mcp/back-left.svg`,
  fewClicksAwayImage: `${CDN_LINK}/few-clicks-away.svg`,
  playButton: `${CDN_LINK}/playback.png`,
  videoPlayIcon: `${CDN_LINK}/video-play-icon.svg`,
  mcpRequestInfobg: `${CDN_LINK}/mcp/request-info-bg.jpg`,
  mcpRequestInfoLady: `${CDN_LINK}/mcp/mcp-mascot-lady.svg`,
  productDocumentCSVBlue: `${CDN_LINK}/product-document-csv-blue.png`,
  takeMeHomeIcon: `${CDN_LINK}/take-me-home-icon.svg`,
  errorPageAnimation: `${CDN_LINK}/404-error-page-animation.svg`,
  routematicLogo: `${CDN_LINK}/mcp/routematic-logo.svg`,
  zeliotLogo: `${CDN_LINK}/mcp/zeliot-logo.svg`,
  carxLogo: `${CDN_LINK}/mcp/carx.svg`,
  categories: {
    software: `${CDN_LINK}/mobility-solutions.jpg`,
    hardware: `${CDN_LINK}/industry-and-trade.jpg`,
    parentCategory: `${CDN_LINK}/icon-bg-home.png`,
    api: `${CDN_LINK}/nav-api.svg`,
    sdk: `${CDN_LINK}/nav-sdk.svg`,
    filter: `${CDN_LINK}/filter.svg`,
    childCategory: `${CDN_LINK}/nav-api.svg`,
    library: `${CDN_LINK}/nav-library.svg`,
    mobileApp: `${CDN_LINK}/nav-mobileapp.svg`,
    ddl: `${CDN_LINK}/nav-ddl.svg`,
    framework: `${CDN_LINK}/nav-framework.svg`,
    sensors: `${CDN_LINK}/nav-sensors.svg`,
    gateway: `${CDN_LINK}/nav-gateway.svg`,
    devices: `${CDN_LINK}/nav-devices.svg`,
  },
  api: {
    logo: `${CDN_LINK}/api-logo.jpg`,
    apibackgroundimg: `${CDN_LINK}/coverimage.jpg`,
    backgroundimg: `${CDN_LINK}/business.jpg`,
    thumbnail: `${CDN_LINK}/api-thumbnail.png`,
    avatarIcon: `${CDN_LINK}/api-thumbnail-square.jpg`,
  },
  cloudCircleIcon: `${CDN_LINK}/mcp-cloud-circle.svg`,
  rupeeIcon: `${CDN_LINK}/rupee-icon.svg`,
  genericPricingIcon: `${CDN_LINK}/generic-pricing.svg`,
  data: {
    backgroundImage: `${CDN_LINK}/data-background-image.jpg`,
    thumbnail: `${CDN_LINK}/data-thumbnail.png`,
  },
  analyticsTrackingImg: `${CDN_LINK}/analytics-tracking.png`,
  applicationsImg: `${CDN_LINK}/applications.png`,
  myAccountImg: `${CDN_LINK}/my-account.png`,
  purchasedProductsImg: `${CDN_LINK}/purchased-products.png`,
  sellerAccountImg: `${CDN_LINK}/seller-account.png`,
  loginBackground: `${CDN_LINK}/login-background.jpg`,
  bgGreyImg: `${CDN_LINK}/bg-grey.svg`,
  analyticsImg: `${CDN_LINK}/analytics.png`,
  applicationsBgImg: `${CDN_LINK}/applications-bg.png`,
  sellerImg: `${CDN_LINK}/seller.png`,
  purchasedProductsBgImg: `${CDN_LINK}/products.png`,
  securityImage: `${CDN_LINK}/security-img.png`,
  genericAppImg: `${CDN_LINK}/generic-app-thumbnail.png`,
  applicationBackgroundImg: `${CDN_LINK}/bg-app-img.jpg`,
  desktop: `${CDN_LINK}/desktop.png`,
  sellerStepper: `${CDN_LINK}/seller-stepper.png`,
  buyerStepper: `${CDN_LINK}/buyer-stepper.png`,
  contactSeller: `${CDN_LINK}/contact-seller.jpg`,
  customPlanImg: `${CDN_LINK}/custom-plan.png`,
  mobilityTitleLogo: `${CDN_LINK}/mobility-title-logo.jpg`,
  registerBackgroung: `${CDN_LINK}/register-background.jpg`,
  contactSellerModalLogo: `${CDN_LINK}/contact-seller-modal.jpg`,
  pdfBoschLogo: `${CDN_LINK}/emailTemplate/logo.png`,
  rocketImage: `${CDN_LINK}/rocket-img.png`,
  deleteUser: `${CDN_LINK}/request-for-deletion.svg`,
  contactUs: `${CDN_LINK}/contactus-mail.png`,
  userManual: `${CDN_LINK}/user-manual.svg`,
  video: `${CDN_LINK}/video-record.png`,
  tryOutBgImg: `${CDN_LINK}/revamped-tryout-bg.svg`,
  orderConfirmed: `${CDN_LINK}/greentick.png`,
  orderPending: `${CDN_LINK}/pending-yellow.png`,
  orderError: `${CDN_LINK}/redcross.png`,
  orderErrorIllustration: `${CDN_LINK}/failed.png`,
  orderConfirmedIllustration: `${CDN_LINK}/success.png`,
  viewProfile: `${CDN_LINK}/viewprofile.png`,
  browseIcon: `${CDN_LINK}/browse-icon.png`,
  dcsApiError: `${CDN_LINK}/dcs-error.png`,
  deletedApiError: `${CDN_LINK}/deleted-api-error-img.svg`,
  noRecordsFound: `${CDN_LINK}/no-records-found.png`,
  userRemoveIcon: `${CDN_LINK}/user-remove-icon.svg`,
  userRemoveWhiteIcon: `${CDN_LINK}/user-remove-white.svg`,
  deleteIcon: `${CDN_LINK}/delete-icon.svg`,
  editIcon: `${CDN_LINK}/edit-icon.svg`,
  deleteIconLightGrey: `${CDN_LINK}/delete-icon-light-grey.svg`,
  editIconLightGrey: `${CDN_LINK}/edit-icon-light-grey.svg`,
  services: {
    enterpriseLicensing: {
      thumbnail: `${CDN_LINK}/saas-logo.png`,
      backgroundImage: `${CDN_LINK}/saas-background-image.jpg`,
    },
    managedServices: {
      thumbnail: `${CDN_LINK}/peripherals-logo.png`,
      backgroundImage: `${CDN_LINK}/peripherals-background-image.jpg`,
    },
    cloudInfrastructure: {
      thumbnail: `${CDN_LINK}/iaas-logo.png`,
      backgroundImage: `${CDN_LINK}/iaas-background-image.jpg`,
    },
    iotSoftwareServices: {
      thumbnail: `${CDN_LINK}/software-services-default.svg`,
    },
    professionalServices: {
      thumbnail: `${CDN_LINK}/professional-services-default.svg`,
    },
    communicationServices: {
      thumbnail: `${CDN_LINK}/communication-services-default.svg`,
    },
    sdk: {
      thumbnail: `${CDN_LINK}/sdk-services-default.svg`,
    },
  },
  solutions: {
    thumbnail: `${CDN_LINK}/solutions-logo.png`,
    backgroundImage: `${CDN_LINK}/solutions-background-image.jpg`,
    defaultHighlightImage: `${CDN_LINK}/default-highlight-image.svg`,
  },
  pending: `${CDN_LINK}/pending.png`,
  rejected: `${CDN_LINK}/rejected.png`,
  consumers: `${CDN_LINK}/consumer.svg`,
  enterprises: `${CDN_LINK}/enterprises.svg`,
  providers: `${CDN_LINK}/providers.svg`,
  productCategory: {
    ddl: `${CDN_LINK}/ddl.svg`,
    devices: `${CDN_LINK}/devices.svg`,
    framework: `${CDN_LINK}/framework.svg`,
    gateway: `${CDN_LINK}/gateway.svg`,
    library: `${CDN_LINK}/library.svg`,
    mobileapp: `${CDN_LINK}/mobileapp.svg`,
    productCategoryApi: `${CDN_LINK}/product-category-api.svg`,
    productCategorySdk: `${CDN_LINK}/product-category-sdk.svg`,
    sensors: `${CDN_LINK}/sensors.svg`,
    apiCategory: `${CDN_LINK}/api-thumbnail.png`,
    hardwareCategory: `${CDN_LINK}/hardware.svg`,
    softwareCategory: `${CDN_LINK}/saas.svg`,
    cloudInfraCategory: `${CDN_LINK}/cloud-infra.svg`,
    dataLakeCategory: `${CDN_LINK}/data-lake.svg`,
    dataCategory: `${CDN_LINK}/data-thumbnail.png`,
    solutionCategory: `${CDN_LINK}/solutions-logo.png`,
    mobilitySoftwareCategory: `${CDN_LINK}/saas.png`,
    peripheralsCategory: `${CDN_LINK}/peripherals.png`,
    cloudInfrastructureCategory: `${CDN_LINK}/cloud.png`,
  },
  onestop: `${CDN_LINK}/onestop.svg`,
  participate: `${CDN_LINK}/participate.svg`,
  boschVerified: `${CDN_LINK}/bosch-verified.svg`,
  infoIcon: `${CDN_LINK}/info.svg`,
  sellersectionHomepage: `${CDN_LINK}/sellers-section-homepage.png`,
  sellersectionHomepageMobile: `${CDN_LINK}/sellers-section-homepage-mobile.png`,
  easyonboardingIcon: `${CDN_LINK}/easy-onboarding.svg`,
  rupeecircleIcon: `${CDN_LINK}/rupee-symbol-circle.svg`,
  graphGrowingIcon: `${CDN_LINK}/graph.svg`,
  closeIcon: `${CDN_LINK}/close-small.svg`,
  supportBannerImage: `${CDN_LINK}/support-banner-image.svg`,
  getInTouchBannerImage: `${CDN_LINK}/get-in-touch-banner.svg`,
  apiCard: `${CDN_LINK}/api-card.jpg`,
  dataCard: `${CDN_LINK}/data-card.jpg`,
  hardwareCard: `${CDN_LINK}/hardware-card.jpg`,
  solutionsCard: `${CDN_LINK}/solutions-card.jpg`,
  servicesCard: `${CDN_LINK}/services-card.jpg`,
  supportsectionHomepage: `${CDN_LINK}/support-image.svg`,
  mailSupportIcon: `${CDN_LINK}/mail-support-new.svg`,
  userOnboardingManual: `${CDN_LINK}/user-onboarding-manual.svg`,
  userManualIcon: `${CDN_LINK}/document.svg`,
  faq: `${CDN_LINK}/faq-support.svg`,
  documentSupportIcon: `${CDN_LINK}/document-support.svg`,
  videoSupportIcon: `${CDN_LINK}/video-support.svg`,
  secureIcon: `${CDN_LINK}/secure-icon.svg`,
  curatedIcon: `${CDN_LINK}/curated-icon.svg`,
  keypadIcon: `${CDN_LINK}/keypad-icon.svg`,
  reducedIcon: `${CDN_LINK}/reduced-icon.svg`,
  intentsMobiThumbnail: `${CDN_LINK}/intents-mobi.png`,
  zeliotThumbnail: `${CDN_LINK}/zeliot.png`,
  decentroThubmail: `${CDN_LINK}/decentro.png`,
  guyWithIpad: `${CDN_LINK}/guy-with-ipad.svg`,
  guyWithIpadMobileView: `${CDN_LINK}/guy-with-ipad-mobile-view.svg`,
  girlWithIpad: `${CDN_LINK}/girl-with-ipad.svg`,
  searchBanner: `${CDN_LINK}/search-banner.svg`,
  aboutUsImage: `${CDN_LINK}/aboutus-header.jpg`,
  aboutUsColImageStory: `${CDN_LINK}/aboutus-col-img-story.png`,
  aboutUsColImageMission: `${CDN_LINK}/aboutus-col-img-mission.png`,
  desktopIconImg: `${CDN_LINK}/desktop-connectivity.png`,
  unsubscribeModalImg: `${CDN_LINK}/unsubscribe-modal.svg`,
  campaignThankyouImg: `${CDN_LINK}/thank-you-handshake.gif`,
  contactSalesCardImg: `${CDN_LINK}/male-working-on-computer.svg`,
  loaderImage: `${CDN_LINK}/loader.gif`,
  checkmarkImg: `${CDN_LINK}/checkmark.svg`,
  useCasesImage1: `${CDN_LINK}/use-cases-image-1.svg`,
  useCasesImage2: `${CDN_LINK}/use-cases-image-2.svg`,
  useCasesImage3: `${CDN_LINK}/use-cases-image-3.svg`,
  useCasesImage4: `${CDN_LINK}/use-cases-image-4.svg`,
  useCasesImage5: `${CDN_LINK}/use-cases-image-5.svg`,
  magnifyBrand: `${CDN_LINK}/magnify-your-brand.gif`,
  unlockBusiness: `${CDN_LINK}/benefits-unlock-business.gif`,
  buildToScale: `${CDN_LINK}/benefits-build-to-scale.gif`,
  storeImage: `${CDN_LINK}/store-use-cases-buyer-benefits.gif`,
  userThumbsUp: `${CDN_LINK}/user-thumbs-up.gif`,
  windowSearch: `${CDN_LINK}/window-search-buyer-benefits.gif`,
  whatWeOffer: {
    sdkIcon: `${CDN_LINK}/sdk-icon.svg`,
    hardwareIcon: `${CDN_LINK}/hardware-icon.svg`,
    cloudIcon: `${CDN_LINK}/cloud-icon.svg`,
    dataIcon: `${CDN_LINK}/data-icon.svg`,
    solutionsIcon: `${CDN_LINK}/solutions-icon.svg`,
    apiIcon: `${CDN_LINK}/api-icon.svg`,
    servicesIcon: `${CDN_LINK}/services-icon.svg`,
    cloudInfra: `${CDN_LINK}/cloud-services-infrastructure-revamped.svg`,
    enterpriseLicensing: `${CDN_LINK}/cloud-services-enterprise-licensing-latest.svg`,
    cloudManagedServices: `${CDN_LINK}/cloud-services-managed-revamped.svg`,
    iotSoftwareServices: `${CDN_LINK}/software-services-revamped.svg`,
    professionalServices: `${CDN_LINK}/professional.svg`,
    communicationServices: `${CDN_LINK}/communication-services-revamped.svg`,
  },
  youMayAlsoLike: {
    apiIcon: `${CDN_LINK}/api-circle-icon.svg`,
    dataIcon: `${CDN_LINK}/data-circle-icon.svg`,
    solutionsIcon: `${CDN_LINK}/solutions-circle-icon.svg`,
  },
  caseStudyForeGroundImg1: `${CDN_LINK}/case-study-1.png`,
  caseStudyForeGroundImg2: `${CDN_LINK}/case-study-2.png`,
  caseStudyForeGroundImg3: `${CDN_LINK}/case-study-3.png`,
  caseStudyLogoImg1: `${CDN_LINK}/logo-1.jpg`,
  caseStudyLogoImg2: `${CDN_LINK}/logo-2.png`,
  caseStudyLogoImg3: `${CDN_LINK}/logo-3.png`,
  animatedIconMegaMenu: `${CDN_LINK}/animated-icon-mega-menu-latest.svg`,
  cartIconMegaMenu: `${CDN_LINK}/cart-icon-mega-menu.svg`,
  blogs: `${CDN_LINK}/blog.svg`,
  vidoesResources: `${CDN_LINK}/vidoes-resources-latest.svg`,
  productResources: `${CDN_LINK}/product-resources-latest.svg`,
  productGuide: `${CDN_LINK}/product-guide.svg`,
  productVideo: `${CDN_LINK}/product-video.svg`,
  noResultsFound: `${CDN_LINK}/no-results-found.svg`,
  categoryAssets: {
    api: `${CDN_LINK}/apibanner.svg`,
    [idConstants.api.categoryId]: {
      featureImg1: `${CDN_LINK}/wide-range-of-api.svg`,
      featureImg2: `${CDN_LINK}/exclusive-list.svg`,
      featureImg3: `${CDN_LINK}/free-tryout.svg`,
      featureImg4: `${CDN_LINK}/shortend-development.svg`,
      featureImg5: `${CDN_LINK}/secure-payment.svg`,
    },
    managedservices: `${CDN_LINK}/cloud-managed-services-banner-new.svg`,
    [idConstants.managedServices.categoryId]: {
      featureImg1: `${CDN_LINK}/maximize-savings-latest.svg`,
      featureImg2: `${CDN_LINK}/choice-of-basic-latest.svg`,
      featureImg3: `${CDN_LINK}/continous-monitoring-latest.svg`,
      featureImg4: `${CDN_LINK}/go-global-support-latest.svg`,
      featureImg5: `${CDN_LINK}/leverage-cloud-latest.svg`,
      featureImg6: `${CDN_LINK}/cloud-consulting-latest.svg`,
      featureImg7: `${CDN_LINK}/bosch-brand-latest.svg`,
      featureImg8: `${CDN_LINK}/disaster-recovery-latest.svg`,
    },
    cloudinfrastructure: `${CDN_LINK}/cloud-infra-banner-new.svg`,
    [idConstants.cloudInfrastructure.categoryId]: {
      featureImg1: `${CDN_LINK}/savings.svg`,
      featureImg2: `${CDN_LINK}/comprehensive-latest.svg`,
      featureImg3: `${CDN_LINK}/aws-account-latest.svg`,
      featureImg4: `${CDN_LINK}/one-time-advisory-latest.svg`,
      featureImg5: `${CDN_LINK}/recommendation-latest.svg`,
      featureImg6: `${CDN_LINK}/monthly-latest.svg`,
      featureImg7: `${CDN_LINK}/extensive-latest.svg`,
      featureImg8: `${CDN_LINK}/migration-support-latest.svg`,
    },
    enterpriselicensing: `${CDN_LINK}/enterprise-licensing-banner-revamped.svg`,
    [idConstants.enterpriseLicensing.categoryId]: {
      featureImg1: `${CDN_LINK}/no-upfront-latest.svg`,
      featureImg2: `${CDN_LINK}/pay-as-you-go-latest.svg`,
      featureImg3: `${CDN_LINK}/optimize-latest.svg`,
      featureImg4: `${CDN_LINK}/hassle-latest.svg`,
    },
    iotsoftwareservices: `${CDN_LINK}/software-services-banner-new.svg`,
    [idConstants.iotSoftwareServices.categoryId]: {
      featureImg1: `${CDN_LINK}/intitutive-latest.svg`,
      featureImg2: `${CDN_LINK}/choice-of-latest.svg`,
      featureImg3: `${CDN_LINK}/promote-business-latest.svg`,
      featureImg4: `${CDN_LINK}/easily-manage-latest.svg`,
      featureImg5: `${CDN_LINK}/custom-built-latest.svg`,
      featureImg6: `${CDN_LINK}/low-setup-latest.svg`,
      featureImg7: `${CDN_LINK}/improve-accessability-latest.svg`,
      featureImg8: `${CDN_LINK}/detailed-reporting-latest.svg`,
    },
    communicationservices: `${CDN_LINK}/communication-services-banner-revamped.svg`,
    [idConstants.communicationServices.categoryId]: {
      featureImg1: `${CDN_LINK}/multi-partner-latest.svg`,
      featureImg2: `${CDN_LINK}/automated-latest.svg`,
      featureImg3: `${CDN_LINK}/dynamic-load-latest.svg`,
      featureImg4: `${CDN_LINK}/end-to-end-latest.svg`,
      featureImg5: `${CDN_LINK}/feature-latest.svg`,
      featureImg6: `${CDN_LINK}/complete-data-latest.svg`,
    },
    professionalservices: `${CDN_LINK}/professional-services-banner-new.svg`,
    [idConstants.professionalServices.categoryId]: {
      featureImg1: `${CDN_LINK}/expert.svg`,
      featureImg2: `${CDN_LINK}/ongoing.svg`,
      featureImg3: `${CDN_LINK}/seamless-integration.svg`,
      featureImg4: `${CDN_LINK}/empower-your.svg`,
      featureImg5: `${CDN_LINK}/profitability.svg`,
      featureImg6: `${CDN_LINK}/reliable.svg`,
    },
    solutions: `${CDN_LINK}/solutions-banner.svg`,
    [idConstants.solutions.categoryId]: {
      featureImg1: `${CDN_LINK}/wide-range-solutions.svg`,
      featureImg2: `${CDN_LINK}/curated-solutions.svg`,
      featureImg3: `${CDN_LINK}/ready-to-integrate.svg`,
      featureImg4: `${CDN_LINK}/partner-ecosystem.svg`,
    },
    data: `${CDN_LINK}/data-banner.svg`,
    [idConstants.data.categoryId]: {
      featureImg1: `${CDN_LINK}/wide-range.svg`,
      featureImg2: `${CDN_LINK}/free.svg`,
      featureImg3: `${CDN_LINK}/testing.svg`,
      featureImg4: `${CDN_LINK}/security-check.svg`,
    },
    hardware: `${CDN_LINK}/hardware-banner.svg`,
    [idConstants.hardware.categoryId]: {
      featureImg1: `${CDN_LINK}/wide-range-hardware.svg`,
      featureImg2: `${CDN_LINK}/pan-india.svg`,
      featureImg3: `${CDN_LINK}/security-check-hardware.svg`,
      featureImg4: `${CDN_LINK}/hardware-partner.svg`,
    },
    automotivespares: `${CDN_LINK}/hardware-banner.svg`,
    [idConstants.automotiveSpares.categoryId]: {
      featureImg1: `${CDN_LINK}/wide-range-hardware.svg`,
      featureImg2: `${CDN_LINK}/pan-india.svg`,
      featureImg3: `${CDN_LINK}/security-check-hardware.svg`,
      featureImg4: `${CDN_LINK}/hardware-partner.svg`
    },
    sdk: `${CDN_LINK}/sdk-banner-new.svg`,
    [idConstants.sdk.categoryId]: {
      featureImg1: `${CDN_LINK}/easy-integration.svg`,
      featureImg2: `${CDN_LINK}/access-the-rich-data.svg`,
      featureImg3: `${CDN_LINK}/seamless-support.svg`,
      featureImg4: `${CDN_LINK}/security.svg`,
      featureImg5: `${CDN_LINK}/performance.svg`,
    },
    fintechLogo: `${CDN_LINK}/fintech.svg`,
    locationAndMappingLogo: `${CDN_LINK}/location-and-mapping.svg`,
    logisticsAndFleetLogo: `${CDN_LINK}/logisticsandfleet.svg`,
    digitalIndiaLogo: `${CDN_LINK}/government-api.svg`,
    smartCityLogo: `${CDN_LINK}/smart-city.svg`,
    environmentLogo: `${CDN_LINK}/environment.svg`,
    journeyRouteLogo: `${CDN_LINK}/journey-travel-route-planning.svg`,
    verificationLogo: `${CDN_LINK}/verification-and-validation.svg`,
    fuelChargingLogo: `${CDN_LINK}/fuel-and-charging-infra.svg`,
    iotLogo: `${CDN_LINK}/iot-ai-ml.svg`,
    parkingLogo: `${CDN_LINK}/parking.svg`,
    communicationDevicesLogo: `${CDN_LINK}/communication-devices.svg`,
    hardwareSensorsLogo: `${CDN_LINK}/hardware-sensors.svg`,
    fleetManagementLogo: `${CDN_LINK}/fleet-management.svg`,
    specialityHardwareLogo: `${CDN_LINK}/specialized-mobility-hardware.svg`,
    telematicsLogo: `${CDN_LINK}/telematics.svg`,
    vehicleAnalyticsLogo: `${CDN_LINK}/vehicle-data-analytics.svg`,
    communicationServicesLogo: `${CDN_LINK}/communication-services-hardware.svg`,
    professionalServicesLogo: `${CDN_LINK}/professional-managed-services.svg`,
    softwareServicesLogo: `${CDN_LINK}/software-service.svg`,
    awsLogo: `${CDN_LINK}/aws-subcategory.svg`,
    azureLogo: `${CDN_LINK}/azure-subcategory.svg`,
    cloudLogo: `${CDN_LINK}/cloud.svg`,
    cloudManagedServicesLogo: `${CDN_LINK}/cloud-managed-services-subcategory.svg`,
    gcpLogo: `${CDN_LINK}/gcp-subcategory.svg`,
    databaseLogo: `${CDN_LINK}/database.svg`,
    emailLogo: `${CDN_LINK}/mail.svg`,
    whatsAppLogo: `${CDN_LINK}/whatsapp.svg`,
    smsLogo: `${CDN_LINK}/sms.svg`,
    accessControlLogo: `${CDN_LINK}/access-control.svg`,
    parkingPaymentLogo: `${CDN_LINK}/parking-payments.svg`,
    anprLogo: `${CDN_LINK}/anpr.svg`,
    displaySystemsLogo: `${CDN_LINK}/display-systems-hardware.svg`,
    sensorsLogo: `${CDN_LINK}/sensors-hardware.svg`,
    adasHardwareLogo: `${CDN_LINK}/adas.svg`,
    mechanicalDeviceLogo: `${CDN_LINK}/mechanical-device.svg`,
  },
  caseStudyPage: {
    caseStudyPageBannerImg: `${CDN_LINK}/casestudy-banner-img.svg`,
    arrowImg: `${CDN_LINK}/case-study-arrow-img.svg`,
    websiteLinkImg: `${CDN_LINK}/website-link-img.svg`,
    segmentImg: `${CDN_LINK}/segment-img.svg`,
    countryImg: `${CDN_LINK}/country-flag-img.svg`,
    industryImg: `${CDN_LINK}/industry-img.svg`,
    customerIcon: `${CDN_LINK}/user-icon-img.svg`,
    userAdvanceImg: `${CDN_LINK}/user-advanced-img.svg`,
    planChartImg: `${CDN_LINK}/plan-chart-img.svg`,
    targetDartImg: `${CDN_LINK}/target-dart-img.svg`,
    signzyImg: `${CDN_LINK}/signzy-img.svg`,
    zeliotImg: `${CDN_LINK}/zeliot-company-logo.png`,
    zeliotCioImg: `${CDN_LINK}/zeliot-cio-profile-image.jpg`,
  },
  hardware: {
    thumbnail: `${CDN_LINK}/hardware-thumbnail.svg`,
  },
  rating: {
    userAccountIcon: `${CDN_LINK}/user-account-icon.svg`,
  },
  dropDownArrowIcon: `${CDN_LINK}/dropdown-downarrow.svg`,
  logoutIcon: `${CDN_LINK}/logout.svg`,
  loginIcon: `${CDN_LINK}/login.svg`,
  analyticsInsightsGeneric: `${CDN_LINK}/analytics-insights-generic.svg`,
  noProductFound: `${CDN_LINK}/no-product-found-revamped.svg`,
  productSubscribedIcon: `${CDN_LINK}/product-subscribed-icon.svg`,
  productAlreadyPurchasedIcon: `${CDN_LINK}/product-already-purchased-icon.svg`,
};

export default images;
