import i18next from 'i18next';
import getMetaDataDetails from './getMetaDataDetails';

export const productSchemaDetails = (productName, thumbnailImage) => {

	const productScripts = document.querySelectorAll('script[type="application/ld+json"]');
	const [metaDescription] = getMetaDataDetails(window.location.pathname);
	if (productScripts) {
		productScripts.forEach((script, index) => {
			const productSchema = JSON.parse(script.textContent);

			if (productSchema && productSchema['@type'] === 'Product') {
			
				if (productName && thumbnailImage) {
					productSchema.name = productName;
					productSchema.image = thumbnailImage;

				}

				if (metaDescription) {
					productSchema.description = i18next.t(metaDescription);
				}

				productScripts[index].textContent = JSON.stringify(productSchema, null, 2);
			}
		});
	}
};


export const breadCrumbSchemaDetails = (category, categoryPathName, productName, productPathName, home, categoryPage) => {

	const breadCrumbScripts = document.querySelectorAll('script[type="application/ld+json"]');
	if (breadCrumbScripts) {
		breadCrumbScripts.forEach((item, index) => {
			const breadCrumbSchema = JSON.parse(item.textContent);
			if (home || categoryPage) {
				if (breadCrumbSchema && breadCrumbSchema['@type'] === 'Product') {
					const [metaDescription] = getMetaDataDetails(window.location.pathname);

					breadCrumbSchema.name = '';
					breadCrumbSchema.image = '';
					breadCrumbSchema.description = i18next.t(metaDescription);

					breadCrumbScripts[index].textContent = JSON.stringify(breadCrumbSchema, null, 2);
				}
			}
			if (breadCrumbSchema && breadCrumbSchema['@type'] === 'BreadcrumbList') {

				breadCrumbSchema.itemListElement[0].item = process.env.REACT_APP_BUYER_BASE_URL;

				if (category && categoryPathName) {
					breadCrumbSchema.itemListElement[1].name = category;
					breadCrumbSchema.itemListElement[1].item = process.env.REACT_APP_BUYER_BASE_URL + categoryPathName;
				} else {
					breadCrumbSchema.itemListElement[1].name = '';
					breadCrumbSchema.itemListElement[1].item = '';
				}
				if (productName && productPathName) {
					breadCrumbSchema.itemListElement[2].name = productName;
					breadCrumbSchema.itemListElement[2].item = process.env.REACT_APP_BUYER_BASE_URL + productPathName + '#overview';
				} else {
					breadCrumbSchema.itemListElement[2].name = '';
					breadCrumbSchema.itemListElement[2].item = '';
				}

				breadCrumbScripts[index].textContent = JSON.stringify(breadCrumbSchema, null, 2);
			}
		});
	}
};

