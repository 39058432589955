import { Container, CssBaseline, Box, Grid, Typography, Button, Tooltip } from '@mui/material';
import { React, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './all-you-need.module.scss';
import Images from '../../../assets/img';
import reactGA from 'react-ga';
import i18next from 'i18next';

export default function AllWeNeedIsHere() {
    const history = useHistory();

    const [play, setPlay] = useState(false);
    return (
        <>
            <CssBaseline />
            <Box className={`${styles['allyouneed-section-wrapper']}`} pt={10} pb={10} px={6} sx={{ backgroundColor: 'primaryBackground.bgBody' }}>
                <Container>
                    <Grid container md={12} sm={12} xs={12}>
                        <Grid item md={12} sm={12} xs={12} className={`${styles['allyouneed-section-heading-wrapper']}`}>
                            <Typography className={`${styles['allyouneed-section-heading']}`} component='h1' variant='h5'>{i18next.t('HOME_PAGE.HEADER_FOOTER.MOBILITY_MARKETPLACE')}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container md={12} sm={12} xs={12} className={`${styles['allyouneed-section-grid-container']}`}>
                        <Grid container md={6} xs={12} className={`${styles['allyouneed-section-features-wrapper']}`}>
                            <Grid item md={12} xs={12} className={`${styles['allyouneed-section-content-container']}`}>
                                <Typography className={`${styles['allyouneed-section-content']}`}>{i18next.t('HOME_PAGE.ALL_YOU_NEED.BODY')}</Typography>
                            </Grid>
                            <Grid container md={12} xs={12} sm={12} className={`${styles['allyouneed-section-btn-container']}`} spacing={1}>
                                <Grid item md={12} mt={3} sm={12} xs={12} pt={20} className={`${styles['allyouneed-section-btn-wrapper']}`}>
                                    <Button variant='borderedBtn'
                                        onClick={() => {
                                            history.push('/about-us');
                                            reactGA.event({ category: 'Know More', action: 'User Clicked on Know More', label: 'Know More' });
                                        }} > {i18next.t('HOME_PAGE.ALL_YOU_NEED.KNOW_MORE')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container md={6} xs={12} className={`${styles['allyouneed-section-video-container']}`}>
                            <Grid item md={12} xs={12} className={`${styles['allyouneed-section-video-wrapper']}`}>
                                {play ? <video className={`${styles['allyouneed-section-video']}`} controls autoPlay playsInline>
                                    <source src={`${process.env.REACT_APP_CDN_LINK}/ms-public-images/MobilityMarketplaceDemo.mp4`} type='video/mp4' />
                                </video> : <>
                                    <Tooltip title={i18next.t('SHARED.PLAY_VIDEO')}>
                                        <Box component='img' src={Images.playButton}
                                            onClick={() => {
                                                setPlay(true);
                                                reactGA.event({ category: 'Home Screen Video Play', action: 'User Clicked on Play Button', label: 'Home Screen Video Play' });
                                            }}
                                            alt={i18next.t('IMAGE_ALT_TEXT.CAMPAIGN_SECTION.PLAYBACKIMG')}
                                            className={`${styles['allyouneed-section-image']}`} />
                                    </Tooltip>
                                </>}
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    );
}
