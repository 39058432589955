import React from 'react';
import { Snackbar, Alert, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import DeviceBreakPoints from '../../../../deviceBreakPoints';


export default function CustomizedSnackbars(props) {
  const {theme} = DeviceBreakPoints();
  const useStyles = makeStyles({
    root: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
  });
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const handleClose = () => {
    setOpen(false);
    props.changeState();
  };

  return (
    <Box className={classes.root}>
      <Snackbar open={open} autoHideDuration={props?.autoHideDuration ? props?.autoHideDuration : 2000} onClose={() => handleClose()} anchorOrigin={props.anchorOrigin? props.anchorOrigin : { vertical: 'bottom', horizontal: 'center' }}>
        <Alert severity={props.severity}>
          {props.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}